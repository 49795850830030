import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import {OrolService} from '../../services/orol.service';
import {SpinnerService} from '../../services/spinner.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

@Component({
  selector: 'app-maps-flood-watch',
  templateUrl: './maps-flood-watch.component.html',
  styleUrls: ['./maps-flood-watch.component.scss']
})
export class MapsFloodWatchComponent implements OnChanges {
  // @ViewChild('mapContainer', { static: false })
  // gmap!: ElementRef;
  // @ViewChild(GoogleMap, { static: false }) map: GoogleMap | undefined;
  // @ViewChild(MapInfoWindow, { static: false }) infoWindow: MapInfoWindow | undefined
  @Input()startDate!: string;
  @Input()endDate!: string;

  center: any = {lat: 27.891535, lng: 78.078743};
  public zoom: number = 4;

  mapOptions: google.maps.MapOptions = {
  // initial center position for the map
    center: this.center,
    zoom: this.zoom,
    mapId: 'DEMO_MAP_ID',
  };

  public iconUrl = '../../../assets/icons/marker.svg';
  public markers: any[] =[];
  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  selectedMarker: any;

  constructor(private orolService: OrolService, private spinnerService:SpinnerService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.page=1;
    if((this.startDate != "" || this.startDate != undefined)  && (this.endDate != "" || this.endDate != undefined)){
      this.searchByDate();
    }
    else{
      this.getFloodAlerts();
    }
  }

  ngOnInit(){
    this.getFloodAlerts();
  }

  async initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement  } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement, this.mapOptions
    );

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const infoContent = document.getElementById("iw-content") as HTMLElement
    // Add some markers to the map.
    const markers = this.markers.map((loc:any, i:number) => {
      var lat = loc.latitude;
      var lng = loc.longitude;
      if(lat && lng){
        map.panTo(new google.maps.LatLng(lat + 6, lng));
        const label = labels[i % labels.length];
        const pinGlyph = new google.maps.marker.PinElement({
          glyph: label,
          glyphColor: "white",
        })

        const marker = new AdvancedMarkerElement({
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          content: pinGlyph.element,
        });
        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener("click", () => {
          this.selectedMarker = loc;
            infoWindow.setContent(infoContent);
            infoWindow.open(map, marker);
        });
        return marker;
      }
      else{
        return new google.maps.marker.AdvancedMarkerElement();
      }
    });
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({markers, map})
  }

  searchByDate() {
    if(this.startDate && this.endDate){
      this.orolService.searchByDate(this.startDate, this.endDate).subscribe((data:any)=>{
        this.markers=[];
        this.addMarker(data.rows)
        if(data['count']){
          this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
          this.initMap();
        }
        this.spinnerService.setSpinner(false);
      });
    }
  }

  addMarker(data:any) {
    for(var i=0; i<data.length;i++){
      this.markers.push(data[i]);
    }
    if(this.totalNumberOfPages == this.page){
      this.initMap();
    }
  }

  getFloodAlerts() {
      if(this.paginate == true){
        this.page = this.page+1;
        this.currentPageCounter++;
     }
     else{
       this.page = this.page;
       this.currentPageCounter=1;
     }
    
     this.orolService.getFloodAlert(this.page, this.pageSize).subscribe((data:any)=>{
       if(data['count']){
         this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
         this.addMarker(data['rows'])
         if(this.currentPageCounter <= this.totalNumberOfPages - 1){
           this.paginate=true;
           this.getFloodAlerts();
         }
       }
       this.spinnerService.setSpinner(false);
     });
  }
}

