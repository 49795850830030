import { Component, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogModule, MatDialogRef} from '@angular/material/dialog';
import {NgIf} from '@angular/common';
import {MatButtonModule, } from '@angular/material/button';

export interface DialogData {
  title: "";
  content: "";
}

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss'],
  imports: [MatButtonModule, MatDialogModule],
})
export class DeleteModalComponent {
  constructor(private dialogRef: MatDialogRef<DeleteModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData){}

  delete(): void {
    let data = {action:"delete"}
    // this.onSubmitReason.emit(data);
    this.dialogRef.close(data);
 }
}
