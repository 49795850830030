import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.scss']
})
export class EmptyStateComponent implements OnInit{
  labelText!: string;
  constructor(private router: Router) {}


  ngOnInit() {
    const currentRoute = this.router.url;

    if (currentRoute === '/river-monitoring') {
      this.labelText = 'Water test';
    } else if (currentRoute === '/fish-sanctuaries') {
      this.labelText = 'Fish sanctuary';
    }else if (currentRoute === '/flood-watch') {
      this.labelText = 'Flood watch';
    }
    else if (currentRoute === '/flora-fauna') {
      this.labelText = 'Flora / Fauna';
    }
  }
}
