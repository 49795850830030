import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { RiverMonitoringService } from '../../../services/river-monitoring.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { OrolService } from 'src/app/services/orol.service';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { DeleteModalComponent } from '../../modals/delete-modal/delete-modal.component';
import { fadeInAnimation } from '../../../animations/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-river-monitoring-list',
  templateUrl: './river-monitoring-list.component.html',
  styleUrls: ['./river-monitoring-list.component.scss'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],
  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' },
  imports: [MatButtonModule, MatDialogModule],
})
export class RiverMonitoringListComponent implements OnInit {
  user: any;
  page: number = 1;
  limit: number = 15;
  riverMonitoring: any = [];
  count: number = 0;
  errorMessage: any;
  selectedRiver: any;
  isLoggedIn: boolean =false;

  constructor(public dialog: MatDialog, private riverMonitoringService: RiverMonitoringService,
    private orolService: OrolService,
    private spinnerService: SpinnerService,
    public router: Router) {
    // var user = localStorage.getItem('User');
    // alert(this.user)
    this.orolService.userDetailsSubject.subscribe((data:any) => {
      //Check for Empty Object
      if(data && Object.keys(data).length != 0){
        this.user=data;
        this.isLoggedIn = true;
      }
      else{
        this.user={};
        this.isLoggedIn = false;
      }
    });
  }

  openDialog(selectedRiver:string, event:any) {
    this.selectedRiver=selectedRiver;
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      // height: '400px',
      width: '400px',
      data: {
        title: 'DELETE',
        content: 'Are you sure you want to delete this water test detail for '+this.selectedRiver.contributorName+'?'
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.action == "delete"){
      this.deleteRiver();
      }
    });
    event.stopPropagation();
  }

  @HostListener("window:scroll", [])

  onScroll(): void {
    const scrollableHeight = document.documentElement.scrollHeight - window.innerHeight
    if (window.scrollY >= scrollableHeight) {
      console.log('User has scrolled to the bottom of the page!')
      if(this.count != 0){
        if(((this.limit * this.page) - this.count) > 0){
        this.page=0;
        }
        else{
          if(this.page!=0){
          this.page = this.page+1
          this.getRiverMonitoring()
          }
        }
    }
  }

  // if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
  //   console.log("bla 1")
  //     // you're at the bottom of the page
  //     if(this.count != 0){
  //     if(((this.limit * this.page) - this.count) > 0){
  //     this.page=0;
  //     }
  //     else{
  //       if(this.page!=0){
  //       this.page = this.page+1
  //       this.getRiverMonitoring()
  //       }
  //     }
  //     }
  //   }
  }
  

  ngOnInit(): void {
    this.getRiverMonitoring();
  }

  navigateToView(id:any){
    // Navigate to the view screen
    this.router.navigate(['../river-monitoring/', id])
  }

  //Pagination needs to be taken care of.
  /**
  * Method to call the list of River monitorings from the service file
  * @param { page } page - pageNumber
  * @param { limit } limit - limit of records to be fetched
  * @returns { Promise } - Object {"access_token": "", "token_type": "Bearer","expires_in": 1637589288}
  **/
  getRiverMonitoring() {
    this.riverMonitoringService.getRiverMonitoring(this.page, this.limit).subscribe({
      complete: () => {  

      }, // completeHandler
      error: (err) => { 
        this.spinnerService.setSpinner(false);
        this.errorMessage = err.error;
        setTimeout(() => {
          this.errorMessage = ""
        }, 5000);
       },    // errorHandler 
      next: (response:any) => { 
        var usersItems = response['rows']
        this.count = response['count'];
        // this.riverMonitoring = usersItems;
        usersItems.forEach((element:any) => {
          this.riverMonitoring.push(element)
        });
        this.spinnerService.setSpinner(false)
      },     // nextHandler
    });
    //   (response:any) => {
    //   // var userId = this.user.id;
    //   // var usersItems = response['rows'].filter((element) => element.userId == userId)

    // },
    // (err) => {

    // }
    // );
  }
  deleteRiver(){
    this.spinnerService.setSpinner(true);
    this.riverMonitoringService.deleteRiver(this.selectedRiver.id).subscribe(
      (response) => {
        // this.deleteModal.hide();
        this.spinnerService.setSpinner(false);
        console.log(response)
        this.riverMonitoring=[];
        this.page=1;
        this.getRiverMonitoring();
      },(err)=>{
        // this.deleteModal.hide();
        this.spinnerService.setSpinner(false);
        alert(err)
      }
    );
  }
  showDeleteModal(riverObj:any){
    this.selectedRiver = riverObj;
    console.log(this.selectedRiver)
    // this.deleteModal.show()
  }
}
