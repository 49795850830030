import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { AboutComponent } from './components/about/about.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { OngoingCampaignsComponent } from './components/ongoing-campaigns/ongoing-campaigns.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { FloraFaunaListComponent } from './components/flora-fauna/flora-fauna-list/flora-fauna-list.component';
import { FloraFaunaCreateEditComponent } from './components/flora-fauna/flora-fauna-create-edit/flora-fauna-create-edit.component';
import { ShopComponent } from './components/shop/shop.component';
// import { VoicesFromTheRiverComponent} from './components/voices-from-the-river/voices-from-the-river.component';
// import { AddBlogComponent} from './components/add-blog/add-blog.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { FloodWatchComponent } from './components/flood-watch/flood-watch.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';

import { FishSanctuariesCreateEditComponent } from './components/fish-sanctuaries/fish-sanctuaries-create-edit/fish-sanctuaries-create-edit.component';
// import { FishSanctuarieseditComponentComponent } from './components/fish-sanctuariesedit-component/fish-sanctuariesedit-component.component';

// import { ArticlesComponent } from "./components/articles/articles.component";
// import { ArticledetailsComponent } from "./components/articledetails/articledetails.component";


import { FishSanctuariesListComponent } from './components/fish-sanctuaries/fish-sanctuaries-list/fish-sanctuaries-list.component';
import { FishSanctuariesViewComponent } from './components/fish-sanctuaries/fish-sanctuaries-view/fish-sanctuaries-view.component';
import { RiverMonitoringListComponent } from './components/river-monitoring/river-monitoring-list/river-monitoring-list.component';
import { RiverMonitoringCreateEditComponent } from './components/river-monitoring/river-monitoring-create-edit/river-monitoring-create-edit.component';
import { RiverMonitoringViewComponent } from './components/river-monitoring/river-monitoring-view/river-monitoring-view.component';


const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'about', component: AboutComponent },
  { path: 'resources', component: ResourcesComponent },
  { path: 'ongoing-campaigns', component: OngoingCampaignsComponent },
  { path: 'my-account', component: MyAccountComponent },
  { path: 'flora-fauna', component: FloraFaunaListComponent },
  { path: "flora-fauna-add", component: FloraFaunaCreateEditComponent },
  { path: "flora-fauna-edit/:id", component: FloraFaunaCreateEditComponent },
  { path: 'shop', component: ShopComponent },
  // { path: "voices-from-the-river", component: ArticlesComponent },
  // { path: "voices-from-the-river/:id", component: ArticledetailsComponent },
  //   {
  //   path: 'voices-from-the-river',
  //   loadChildren: () => import('./components/voices-from-the-river/voices-from-the-river.module').then(m => m.VoicesFromTheRiverModule)
  // },
  // {
  //   path: 'river-monitoring',
  //   loadChildren: () => import('./components/river-monitoring/river-monitoring.module').then(m => m.RiverMonitoringModule)
  // },
  { path: 'river-monitoring', component: RiverMonitoringListComponent },
  { path: "river-monitoring-add", component: RiverMonitoringCreateEditComponent },
  { path: "river-monitoring-edit/:id", component: RiverMonitoringCreateEditComponent },
  { path: "river-monitoring/:id", component: RiverMonitoringViewComponent },
  { path: 'flood-watch', component: FloodWatchComponent },
  { path: "fish-sanctuaries-edit/:id", component: FishSanctuariesCreateEditComponent },
  { path: "fish-sanctuaries-add", component: FishSanctuariesCreateEditComponent },
  { path: "fish-sanctuaries/:id", component: FishSanctuariesViewComponent },
  { path: 'fish-sanctuaries', component: FishSanctuariesListComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
