import { Component, OnInit, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { FloraFaunaService } from 'src/app/services/flora-fauna.service';
import { RiverMonitoringService } from 'src/app/services/river-monitoring.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { DeleteModalComponent } from '../../modals/delete-modal/delete-modal.component';
import { MatDialog } from '@angular/material/dialog';
// import { TabsetComponent } from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-flora-fauna-list',
  templateUrl: './flora-fauna-list.component.html',
  styleUrls: ['./flora-fauna-list.component.scss']
})
export class FloraFaunaListComponent implements OnInit {
  @ViewChild('search', { static: false }) public searchElementRef: ElementRef | undefined;
  pageNumber = 1;
  fauna: any = [];
  flora: any = [];
  page = 1;
  pageSize=30;
  paginate=false;
  totalNumberOfPages=1;
  currentPageCounter=1;
  pageFlora = 1;
  paginateFlora=false;
  totalNumberOfPagesFlora=1;
  currentPageCounterFlora=1;
  user: any;
  selectedFloraFauna: any;
  isLoading=false;

  constructor(public dialog: MatDialog, private floraFaunaService: FloraFaunaService, private riverMonitoringService:RiverMonitoringService, private spinnerService: SpinnerService,
    public router: Router
    ) {
      this.user = localStorage.getItem('User');
      if(this.user != null){
        this.user = JSON.parse(this.user);
      }
  }

  openDialog(selectedRiver:string) {
    this.selectedFloraFauna=selectedRiver;
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      // height: '400px',
      width: '400px',
      data: {
        title: 'DELETE',
        content: 'Are you sure you want to delete this  '+this.selectedFloraFauna.contributorName+'?'
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.action == "delete"){
      this.deleteFloraFauna();
      }
    });
  }

  ngOnInit() {
    this.listRivers();
    this.listFloraFauna();
  }

  deleteFloraFauna(){
   if(this.selectedFloraFauna.table == "flora-fauna"){
    this.spinnerService.setSpinner(true);
    this.floraFaunaService.deleteFloraFauna(this.selectedFloraFauna.id).subscribe(
      (response) => {
        // this.deleteModal.hide();
        this.spinnerService.setSpinner(false);
        console.log(response)
        this.paginate=false;
        this.page=1;
        this.pageFlora=1;
        this.flora=[];
        this.fauna=[];
        this.totalNumberOfPages=1;
        this.currentPageCounter=1;
        this.pageFlora = 1;
        this.paginateFlora=false;
        this.totalNumberOfPagesFlora=1;
        this.currentPageCounterFlora=1;

        this.listRivers();
        this.listFloraFauna();
      },(err)=>{
        // this.deleteModal.hide();
        this.spinnerService.setSpinner(false);
        alert(err)
      }
    );

   }
  }

  // deleteRiver(){
  //   this.spinnerService.setSpinner(true);
  //   this.riverMonitoringService.deleteRiver(this.selectedFloraFauna.id).subscribe(
  //     (response) => {
  //       // this.deleteModal.hide();
  //       this.spinnerService.setSpinner(false);
  //       console.log(response)
  //       this.flora=[];
  //       this.fauna=[];
  //       this.page=1;
  //       this.pageFlora=1;
  //       this.();
  //     },(err)=>{
  //       // this.deleteModal.hide();
  //       this.spinnerService.setSpinner(false);
  //       alert(err)
  //     }
  //   );
  // }

  listFloraFauna() {
    this.spinnerService.setSpinner(true);
    if(this.paginateFlora == true){
       this.pageFlora = this.pageFlora+1;
       this.currentPageCounterFlora++;
    }
    else{
      this.pageFlora = this.pageFlora;
      this.currentPageCounterFlora=1;
    }
    this.floraFaunaService.getFloraFauna(this.pageFlora, this.pageSize).subscribe((data:any) => {
      if (data['count']) {
        this.totalNumberOfPagesFlora=Math.ceil((data['count']/this.pageSize));

        for (var i = 0; i < data['rows'].length; i++) {
          if (data['rows'][i].fauna.length > 0) {
            for (var j = 0; j < data['rows'][i].fauna.length; j++) {
              this.fauna.push({
                id: data['rows'][i].id,
                userId: data['rows'][i].userId,
                // description: data['rows'][i].fauna[j].description,
                // imageURL: data['rows'][i].fauna[j].imageURL,
                img: data['rows'][i].fauna[j].imageURL,
                thumb: data['rows'][i].fauna[j].imageURL,
                commonName: data['rows'][i].commonName,
                scientificName: data['rows'][i].scientificName,
                localName: data['rows'][i].localName,
                location: data['rows'][i].location,
                contributorName: data['rows'][i].contributorName,
                table:"flora-fauna",
                type:"fauna"
              });
            }
          }
          if (data['rows'][i].flora.length > 0) {
            for (var j = 0; j < data['rows'][i].flora.length; j++) {
              this.flora.push({
                id: data['rows'][i].id,
                userId: data['rows'][i].userId,
                img: data['rows'][i].flora[j].imageURL,
                thumb: data['rows'][i].flora[j].imageURL,
                commonName: data['rows'][i].commonName,
                scientificName: data['rows'][i].scientificName,
                localName: data['rows'][i].localName,
                location: data['rows'][i].location,
                contributorName: data['rows'][i].contributorName,
                table:"flora-fauna",
                type:"flora"
              });
              console.log("souj")
              console.log(this.flora)
            }
          }
        }
        if(this.currentPageCounterFlora <= this.totalNumberOfPagesFlora - 1){
          this.paginateFlora=true;
          this.listFloraFauna();
        }
      }

      this.spinnerService.setSpinner(false);
    });
  }

  listRivers() {
    this.spinnerService.setSpinner(true);
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }
    this.riverMonitoringService.getRiverMonitoring(this.page, this.pageSize).subscribe((data:any) => {
      if (data['count']) {
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));

        for (var i = 0; i < data['rows'].length; i++) {
          if (data['rows'][i].faunaPictures.length > 0) {
            for (var j = 0; j < data['rows'][i].faunaPictures.length; j++) {
              this.fauna.push({
                id: data['rows'][i].id,
                userId: data['rows'][i].userId,
                img: data['rows'][i].faunaPictures[j].imageURL,
                thumb: data['rows'][i].faunaPictures[j].imageURL,
                location: data['rows'][i].location,
                contributorName: data['rows'][i].contributorName,
                table:"water-test-details",
                type:"fauna"
              });
            }
          }
          if (data['rows'][i].floraPictures.length > 0) {
            for (var j = 0; j < data['rows'][i].floraPictures.length; j++) {
              this.flora.push({
                id: data['rows'][i].id,
                userId: data['rows'][i].userId,
                img: data['rows'][i].floraPictures[j].imageURL,
                thumb: data['rows'][i].floraPictures[j].imageURL,
                location: data['rows'][i].location,
                contributorName: data['rows'][i].contributorName,
                table:"water-test-details",
                type:"flora"
              });
            }
          }
        }
        if(this.currentPageCounter <= this.totalNumberOfPages - 1){
          this.paginate=true;
          this.listRivers();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }

  onScroll() {
    this.pageNumber = this.pageNumber + 1;
  }
  navigateToEdit(obj: any){
    this.selectedFloraFauna = obj;
    if(obj.table == 'flora-fauna'){
      this.router.navigate(['../flora-fauna-edit/', obj.id])
    }
    else{
      this.router.navigate(['../river-monitoring-edit/', obj.id])
    }
  }
}
