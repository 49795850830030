import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
declare var google: { maps: { Geocoder: new () => any; LatLng: new (arg0: any, arg1: any) => any; GeocoderStatus: { OK: any; }; }; };
import { FloraFaunaService } from 'src/app/services/flora-fauna.service';
import { SpinnerService } from 'src/app/services/spinner.service';
import { HttpClient } from '@angular/common/http';
import { Event, Route, Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
// import { TabsetComponent } from 'ng-uikit-pro-standard';
import { Subscription } from 'rxjs';
import { Location } from '@angular/common';
import { Address } from 'ngx-google-places-autocomplete/objects/address';

@Component({
  selector: 'app-flora-fauna-create-edit',
  templateUrl: './flora-fauna-create-edit.component.html',
  styleUrls: ['./flora-fauna-create-edit.component.scss']
})
export class FloraFaunaCreateEditComponent implements OnInit {
  @ViewChild('search', { static: false })
  public searchElementRef!: ElementRef;
  user:any;
  submitted!: boolean;
  floraFaunaForm!: FormGroup;
  searchControl!: FormControl;
  imgResultAfterCompress!: string;
  imageFile: File | null = null;
  imgResultBeforeCompress!: string;
  centerLoc!: { lat: number; lng: number; };
  geocoder: any;
  note=".jpg,.png, files accepted";
  info = "(Max. size 100KB)";
  routeParamsSubscription: Subscription;
  id: any;
  mode!: string;
  submitBtnText: string;
  imageURL!: string;
  imageUploaderText: string;
  selectedType!: string;
  isAddMode: boolean;

  constructor(public location: Location, private fb: FormBuilder, private floraFaunaService: FloraFaunaService, private spinnerService: SpinnerService,
    public router: Router, private http: HttpClient, private imageCompress: NgxImageCompressService,
    // private mapsAPILoader: MapsAPILoader,
    private route: ActivatedRoute) {
      this.createForm();
      this.user = localStorage.getItem('User');
      if(this.user != ''){
        this.user = JSON.parse(this.user);
      }
      this.routeParamsSubscription = this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
        if (this.id != null) {
          this.getFloraFaunaById();
        }
      });
      let url = this.route.snapshot.url.join().split(',');
  
      if (url[0] == "flora-fauna-add") {
        this.isAddMode = true;
        this.submitBtnText = "ADD";
        this.imageUploaderText = "UPLOAD IMAGE";
      } else {
        this.isAddMode = false;
        this.submitBtnText = "UPDATE";
        this.imageUploaderText = "CHANGE IMAGE";
      }
    }

  getFloraFaunaById() {
    this.floraFaunaService.getFloraFaunaById(this.id).subscribe((data:any) => {
      if(data['fauna'].length == 0) {
        data['type']=1;
        this.imageURL= data['flora'][0].imageURL;
        this.selectedType="Flora";
        //It is a flora type should be set to 1
      }
      else{
        data['type']=2;
        this.imageURL= data['fauna'][0].imageURL;
        this.selectedType="Fauna";
        //It is a fauna type should be set to 2
      }
      this.floraFaunaForm.patchValue((data));
     
    })
  }

  ngOnInit() {
    this.searchControl = new FormControl();
    if(this.isAddMode == true && this.user != null){
      this.setCurrentPosition();
    }
}
private setCurrentPosition() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition((position) => {
      var accuracy = position.coords.accuracy;
      this.floraFaunaForm?.patchValue({
        latitude: +position.coords.latitude,
        longitude: +position.coords.longitude,
      });
      // this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
      this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.floraFaunaForm);
      // this.addMarker()
    });
  }
}
handleAddressChange(address: Address) {
  let location = address.formatted_address;
  let lat = address.geometry.location.lat();
  let lng = address.geometry.location.lng();
  this.floraFaunaForm?.patchValue({
    latitude: lat,
    longitude: lng,
    location:location,
  });
  // this.zoom = 12;
  // map.panTo(curmarker.position);
} 
  createForm() {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      this.user = JSON.parse(user);
    }
    if(this.user){
      this.floraFaunaForm = this.fb.group({
        type: ['', [Validators.required]],
        userId: [this.user.id],
        location: ['', [Validators.required]],
        commonName: ['', [Validators.required]],
        localName: ['', [Validators.required]],
        scientificName: ['', [Validators.required]],
        latitude: ['', [Validators.required]],
        longitude: ['', [Validators.required]],
      });
    }
  }
  async addFloraFauna() {
    this.submitted = true;
    if (this.floraFaunaForm.get('type')!.value == 1) {
      if(this.imageFile != null){
        this.floraFaunaService.addFlora(this.floraFaunaForm.value, this.imageFile!);
        this.spinnerService.setSpinner(true);
      }
    }
    if (this.floraFaunaForm.get('type')!.value == 2) {
      if(this.imageFile != null){
        this.floraFaunaService.addFauna(this.floraFaunaForm.value, this.imageFile!);
        this.spinnerService.setSpinner(true);
      }
    }
  }

  async updateFloraFauna() {
    this.submitted = true;
    if (this.floraFaunaForm!.get('type')!.value == 1) {
      if(this.imageFile != null){
        this.floraFaunaService.updateFlora(this.id, this.floraFaunaForm.value, this.imageFile!);
        this.spinnerService.setSpinner(true);
      }
    }
    if (this.floraFaunaForm!.get('type')!.value == 2) {
      if(this.imageFile != null){
        this.floraFaunaService.updateFauna(this.id, this.floraFaunaForm.value, this.imageFile!);
        this.spinnerService.setSpinner(true);
      }
    }
  }

  bla() {
    this.getAddressByLatitudeAndLongitude(this.floraFaunaForm.get('latitude')!.value, this.floraFaunaForm.get('longitude')!.value, this.floraFaunaForm);
    this.centerLoc = { lat: this.floraFaunaForm.get('latitude')!.value, lng: this.floraFaunaForm.get('longitude')!.value };
    //this.recenterMap();
  }
  async getAddressByLatitudeAndLongitude(lat: any, lng: any, form: FormGroup<any>) {
    var address;
    this.geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);

    await this.geocoder.geocode({ latLng: latlng }, function (results: { formatted_address: any; }[], status: string) {
      if (status == google.maps.GeocoderStatus.OK) {
        var arrAddress = results;
        address = results[0].formatted_address;
        form.patchValue({
          location: address
        });
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  // private setCurrentPosition() {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition((position) => {
  //       var accuracy = position.coords.accuracy;
  //       this.floraFaunaForm.patchValue({
  //         latitude: +position.coords.latitude,
  //         longitude: +position.coords.longitude,
  //       });
  //       this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
  //       this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.floraFaunaForm);
  //     });
  //   }
  //   else {
  //   }
  // }


  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  compressFile() {
    var orientation = -1;
    var filename=  "";
    this.imageCompress.uploadFile().then(({ image }) => {
      this.imgResultBeforeCompress = image;
      if (this.floraFaunaForm.get('type')!.value == 1){
         filename = "flora_"+ Date.now();
      }
      else if(this.floraFaunaForm.get('type')!.value == 2){
        filename = "fauna_"+ Date.now();
     }

       this.imageCompress.compressFile(image, orientation, 20,20).then(
         (result: any) => {
          this.imgResultAfterCompress = result;
          this.imageFile = this.dataURLtoFile(this.imgResultAfterCompress,filename );
        }
      );
    });
  }
  deleteImage(){
    this.imageFile=null;
    this.imgResultAfterCompress="";
  }
}
