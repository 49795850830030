import { Component, OnInit } from '@angular/core';
import { OrolService } from '../../services/orol.service';
// import { butterService } from '../../services';
import { SpinnerService } from '../../services/spinner.service';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-recent-blog-post',
  templateUrl: './recent-blog-post.component.html',
  styleUrls: ['./recent-blog-post.component.scss']
})
export class RecentBlogPostComponent implements OnInit {
  slides: any = [[]];
  cards = [];
  posts:any;


  constructor(private http : HttpClient){}
  ngOnInit() {
  //   this.http.get('https://blogadmin.ourriverourlife.com/blogs').subscribe(result => {
  //     this.posts = result;
  //       for (var i = 0; i < this.posts.length; i++) {
  //         //console.log(this.posts[i].featured_image);
  //         this.cards.push({
  //           featuredTitle: this.posts[i].title,
  //           featuredPhoto: this.posts[i].image.url,
  //           updated: this.posts[i].date,
  //           contributorName: this.posts[i].author,
  //           id:this.posts[i].id
  //         });
  //       }
  //       console.log(this.cards);
  //       this.slides = this.chunk(this.cards, 4);
  //     });
  // }
  // chunk(arr, chunkSize) {
  //   let R = [];
  //   for (let i = 0, len = arr.length; i < len; i += chunkSize) {
  //     R.push(arr.slice(i, i + chunkSize));
  //   }
  //   return R;
  }

  ngOnDestroy() {
    //this.queryArticles.unsubscribe();
  }
  }
