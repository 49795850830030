import { Component, OnInit, inject } from '@angular/core';
import { SpinnerService } from './services/spinner.service';
import { ActivatedRoute, Router, NavigationEnd ,NavigationStart, Event } from '@angular/router';
import { OrolService } from './services/orol.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'our-river-our-life';
  isLoading:boolean=false;
  timeout: any;
  user:any;

  constructor(private router: Router, public spinnerService:SpinnerService, public orolService:OrolService){
    this.user = localStorage.getItem('User');
    if(this.user != ''){
      this.user = JSON.parse(this.user);
    }

    if(this.user != ''){ //Logged In
      this.orolService.userDetailsSubject.next(this.user);
    }

  router.events.subscribe((event: Event) => {

    if (event instanceof NavigationStart) {
      // Show loading indicator
      this.isLoading = true;
    }

    if (event instanceof NavigationEnd) {
      // Hide loading indicator
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        this.isLoading = false;
      }, 1000);
    }
  });

  this.spinnerService.spinnerSubject.subscribe((data:any) => {
      this.isLoading=data;
  });

  // this.orolService.userDetailsSubject.subscribe((data:any) => {
  //   console.log("user details");
  //   console.log((data:any));
  //   // this.errorMessage=data;
  // });
}
ngOnInit(){
}

}
