import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { OrolService } from '../../services/orol.service';
import { SpinnerService } from '../../services/spinner.service';
import { NgxImageCompressService } from 'ngx-image-compress';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit {

  profileForm!: FormGroup;
  public imageFile: File = { } as File;
  imageURL:any = "../../assets/icons/profile.png";
  show: boolean = false;
  images = [];
  submitted: boolean = false;
  imgResultBeforeCompress!: string;
  imgResultAfterCompress: string="../../assets/icons/profile.png";
  reports: any = [];
  pageNumber = 1;
  note=".jpg, .jpeg, .png, files accepted";
  info = "(Max. size 100KB)";
  isLargeImageFile=false;
  isInvalidImageFile=false;
  successMessage="";
  errorMessage="";
  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  user:any;

  constructor(private fb: FormBuilder, private imageCompress: NgxImageCompressService, public orolService: OrolService, private spinnerService: SpinnerService) {
    this.createForm();
    this.user = localStorage.getItem('User');
    if(this.user != ''){
      this.user = JSON.parse(this.user);
    }
  }

  ngOnInit(): void {
    this.getUser();
    this.getWaterTestDetails();
  }

  createForm() {
    this.profileForm = this.fb.group({
      id: [],
      firstName: ['', [Validators.required, Validators.maxLength(50)]],
      lastName: ['', [Validators.required, Validators.maxLength(50)]],
      email: ['', [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      phoneNumber: ['', [Validators.required]],
      avatarURL: this.fb.array([]),
    });
  }
  gotoTop() {
  window.scroll({
    top: 0,
    left: 0,
    behavior: 'smooth'
  });
}
  validate() {
    this.submitted = true;
    if (this.profileForm.get('firstName')!.valid &&
      this.profileForm.get('lastName')!.valid &&
      this.profileForm.get('phoneNumber')!.valid &&
      this.profileForm.get('email')!.valid
      && (this.imageFile != null || this.imgResultAfterCompress != this.imageURL)
    ) {
      this.updateProfile();
    }
    else{
      this.gotoTop();
    }
  }
  async updateProfile() {
    var accessToken = this.user.accessToken;
    this.orolService.updateProfile(this.profileForm.value, this.imageFile).subscribe(
     (res:any) => {
      const User: any = {
        'id': res.id,
        'accessToken': accessToken,
        'firstName': res.firstName,
        'lastName': res.lastName,
        'phoneNumber': res.phoneNumber,
        'email': res.email,
        'avatarURL': res.avatarURL,
      }
      localStorage.removeItem('User');
      localStorage.setItem('User', JSON.stringify(User));
      this.orolService.userDetailsSubject.next(User);
      this.spinnerService.setSpinner(false);
        this.show = false;
        this.images = [];
        this.successMessage="User Profile successfully updated!";
        setTimeout(() => {
            this.successMessage="";
        }, 5000);
        this.gotoTop();
     },
     (err) => {
       this.spinnerService.setSpinner(false);
        this.gotoTop();
        this.errorMessage="Error updating the user profile :" + err.message;
       // return err;
     },
   );
    // .then(((data:any)) => {
    //   console.log((data:any));
    //   this.show = false;
    //   this.images = [];
    // });
  }
  getUser() {
    this.orolService.getUser().subscribe((data:any) => {
      if (JSON.stringify(data) != '{}') {
        if(data['avatarURL']){
        this.imgResultAfterCompress = data['avatarURL'][0];
      }

        this.profileForm.patchValue({
          id: data['id'],
          firstName: data['firstName'],
          lastName: data['lastName'],
          email: data['email'],
          phoneNumber: data['phoneNumber'],
          // avatarURL:data['avatarURL'][0]
        });
      }
      else {

      }
    });
  }
  deleteImage(){
    this.imgResultAfterCompress="";
    this.isLargeImageFile=false;
    this.isInvalidImageFile=false;
    this.submitted = true;
    this.imageFile={} as File;
    this.imgResultAfterCompress=this.imageURL;
  }

  compressFile() {
    this.isLargeImageFile=false;
    this.isInvalidImageFile=false;
    var orientation = -1;
    this.imageCompress.uploadFile().then(({ image }) => {
      this.imgResultBeforeCompress = image;
      this.imageCompress.compressFile(image, orientation, 50, 50).then(
        result => {
          this.imgResultAfterCompress = result;
          this.imageFile = this.dataURLtoFile(this.imgResultAfterCompress, "avatar_"+this.user.id+"_"+Date.now());
          var type = this.imageFile.type.split('/');
          if(type[1] == "jpeg" || type[1] == "jpg" || type[1] == "png"){
          if(this.imageFile.size > 100000){//250kb (in bytes)
            this.isLargeImageFile=true;
            // this.deleteImage();
          }
        }
        else{

          this.isInvalidImageFile=true;
        }
        }
      );
    });
  }

  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
  //old update
  updateUser() {
    this.orolService.updateUser(this.profileForm.value).subscribe((data:any) => {
      var user = data;
      const User: any = {
        'id': user.id,
        'accessToken': user.accessToken,
        'firstName': user.firstName,
        'lastName': user.lastName,
        'phoneNumber': user.phoneNumber,
        'email': user.email,
        'avatarURL': user.avatarURL ? user.avatarURL[0] : [],
      }
      localStorage.setItem('User', JSON.stringify(User));
      this.orolService.userDetailsSubject.next(User);
      this.spinnerService.setSpinner(false);
    });

  }
  onScroll() {
    this.pageNumber = this.pageNumber + 1;
  }
  // getWaterTestDetails() {
  //  var user:any = localStorage.getItem('User');
  //   if(user != ''){
  //     user = JSON.parse(user);
  //   }
  //   this.spinnerService.setSpinner(true);
  //   this.orolService.getWaterTestDetails().subscribe((data:any) => {
  //     if (data['count']) {
  //       this.reports = data['rows'].filter(r => r.userId == user.id);
  //     }
  //     this.spinnerService.setSpinner(false);
  //   });
  // }
  getWaterTestDetails() {
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    this.orolService.getWaterTestDetail(this.page, this.pageSize).subscribe((data:any) => {
      if (data['count']) {
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
        for(var i=0; i<data['rows'].length;i++){
          if(data['rows'][i].userId == user.id){
            this.reports.push(data['rows'][i]);
          }
        }
        // this.reports = data['rows'].filter(r => r.userId == user.id);
      }
      if(this.currentPageCounter <= this.totalNumberOfPages - 1){
        this.paginate=true;
        this.getWaterTestDetails();
      }
      this.spinnerService.setSpinner(false);
    });
  }
}
