import { Component,OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormControl } from '@angular/forms';
declare var google: any;

@Component({
  selector: 'app-flood-watch',
    templateUrl: './flood-watch.component.html',
    styleUrls: ['./flood-watch.component.scss']
})
export class FloodWatchComponent implements OnInit {
  ngOnInit(){

  }

}
