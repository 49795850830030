import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Route, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { NgZone } from '@angular/core';
import { RiverMonitoringService } from '../../../services/river-monitoring.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SpinnerService } from '../../../services/spinner.service';
import { CONSTANTS } from 'src/app/utils/constants/constants';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-river-monitoring-view',
  templateUrl: './river-monitoring-view.component.html',
  styleUrls: ['./river-monitoring-view.component.scss']
})
export class RiverMonitoringViewComponent implements OnInit {
  @ViewChild('river_monitoring_stepper', { static: false })
  river_monitoring_stepper!: MatStepper;
  @ViewChild('search', { static: false }) public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap, { static: false })map!: GoogleMap;

  //Constants
  public SURROUNDINGS = CONSTANTS.SURROUNDINGS;
  public WATER_LEVELS = CONSTANTS.WATER_LEVELS;
  public WEATHER_CONDITIONS = CONSTANTS.WEATHER_CONDITIONS;
  public BACTERIA = CONSTANTS.BACTERIA;
  defaultImageURL: string = "../../../assets/icons/default_image_upload.jpg";
  defaultImageURLTemp: string = "../../../assets/icons/default_image_upload.jpg";
  public iconUrl = '../../../assets/icons/marker.svg';
  avatarURL: String = "../../assets/icons/profile.png";
  note = ".jpg, .jpeg, .png, files accepted";
  info = "(Max. size 100KB)";

  // REGEX Patterns
  decimalPattern = /^\d+(\.\d)?\d*$/;

  //Related to google maps
  zoom = 12;
  center!: google.maps.LatLngLiteral;
  centerLoc!: google.maps.LatLngLiteral;
  geocoder: any;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  markers = [] as any[];

  // Required for pagination of the list page
  public page = 1;
  public pageSize = 30;
  public paginate = false;
  public totalNumberOfPages = 1;
  public currentPageCounter = 1;
  pageNumber = 1;

  //Used to save all uploaded image files
  images = [];
  public imageFilesRiver: File[] = [];
  public imageFilesSurrounding: File[] = [];
  public imageFilesFlora: File[] = [];
  public imageFilesFauna: File[] = [];
  public imageFilesGroup: File[] = [];
  public imageFilesActivity: File[] = [];
  public imageFilesArtwork: File[] = [];

  //Used for stepper validation
  submitStep1: boolean = false;
  submitStep2: boolean = false;
  submitStep3: boolean = false;
  submitStep4: boolean = false;
  submitStep5: boolean = false;
  submitStep6: boolean = false;
  submitStepFinal: boolean = false;

  //Others
  userControl = new FormControl();
  activityForm: FormGroup | undefined;
  id: string = '';
  user: any;
  isAddMode = true;
  errorMessage: String = "";
  public imageFile: File = {} as File;
  public imageFileTemp: File = {} as File;
  imageFileErrorMessage: String = "";
  reports: any = [];
  lastClickedIndex: any;
  public riverMonitoring_details: any = [];

  constructor(public location: Location, private fb: FormBuilder, private riverMonitoringService: RiverMonitoringService, private router: Router, private imageCompress: NgxImageCompressService,
    private ngZone: NgZone, private spinnerService: SpinnerService, private route: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('User')!);
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.createForm();
  }

  createForm() { 
    this.activityForm = this.fb.group({
      userId: [''],
      generalInformation: this.fb.group({
        activityDate: [(new Date()), [Validators.required]],
        activityTime: ['', [Validators.required]],
        testerName: ['', [Validators.required, Validators.maxLength(100)]],
        latitude: [[], [Validators.pattern(this.decimalPattern)]],
        longitude: [[], [Validators.pattern(this.decimalPattern)]],
        location: ['', [Validators.required, Validators.maxLength(200)]],
      }),
      waterLevelAndWeather: this.fb.group({
        airTemperature: [[], [Validators.required, Validators.pattern(this.decimalPattern)]],
        waterLevel: ['', [Validators.required]],
        weather: ['', [Validators.required]],
      }),
      surroundings: this.fb.array([]),
      waterTesting: this.fb.group({
        waterTemperature: [[], [Validators.pattern(this.decimalPattern)]],
        pH: [[], [Validators.pattern(this.decimalPattern)]],
        dissolvedOxygen: [[], [Validators.pattern(this.decimalPattern)]],
        hardness: [[], [Validators.pattern(this.decimalPattern)]],
        nitrate: [[], [Validators.pattern(this.decimalPattern)]],
        nitrite: [[], [Validators.pattern(this.decimalPattern)]],
        chlorine: [[], [Validators.pattern(this.decimalPattern)]],
        alkalinity: [[], [Validators.pattern(this.decimalPattern)]],
        iron: [[], [Validators.pattern(this.decimalPattern)]],
        bacteria: ['', [Validators.pattern(this.decimalPattern)]],
        turbidity: [[], [Validators.pattern(this.decimalPattern)]],
        phosphate: [[], [Validators.pattern(this.decimalPattern)]],
        ammonia: [[], [Validators.pattern(this.decimalPattern)]],
        lead: [[], [Validators.pattern(this.decimalPattern)]],
        totalDissolvedSolids: [[], [Validators.pattern(this.decimalPattern)]],
        conductivity: [[], [Validators.pattern(this.decimalPattern)]],
      }),
      riverPictures: this.fb.array([]),
      surroundingPictures: this.fb.array([]),
      floraPictures: this.fb.array([]),
      faunaPictures: this.fb.array([]),
      groupPictures: this.fb.array([]),
      activityPictures: this.fb.array([]),
      artworkPictures: this.fb.array([]),
    });
  }


  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }
  ngOnInit() {
    this.getRiverMonitoringDetails();
  }


  getRiverMonitoringDetails() {
    this.riverMonitoringService.getRiverMonitoringDetails(this.route.snapshot.paramMap.get("id")).subscribe(
      (res:any) => {
        this.riverMonitoring_details = res;
        var activityDate = new Date(res['generalInformation'].activityDate);
        res['generalInformation'].activityDate = activityDate
        this.activityForm!.patchValue(res);
        this.patchArrayValues(res);
      },
      (err) => {
        console.log(err.error);
        console.log(err.status);
      },
      () => { 

      })
  }

  patchArrayValues(res: any) {
    this.populateSurroundings();
    this.populateRiverPictures();
    this.populateSurroundingPictures();
    this.populateFloraPictures();
    this.populateFaunaPictures();
    this.populateGroupPictures();
    this.populateActivityPictures();
    this.populateArtworkPictures();
    this.setBacteria(res['waterTesting'].bacteria);
  }

  populateArtworkPictures() {
    let index = -1;
    this.riverMonitoring_details.artworkPictures.forEach((element: any, index: any) => {
      this.addArtworkPicture(element)
    });
  }

  populateActivityPictures() {
    let index = -1;
    this.riverMonitoring_details.activityPictures.forEach((element: any, index: any) => {
      this.addActivityPicture(element)
    });
  }

  populateGroupPictures() {
    let index = -1;
    this.riverMonitoring_details.groupPictures.forEach((element: any, index: any) => {
      this.addGroupPicture(element)
    });
  }

  populateFaunaPictures() {
    let index = -1;
    this.riverMonitoring_details.faunaPictures.forEach((element: any, index: any) => {
      this.addFaunaPicture(element)
    });
  }

  populateFloraPictures() {
    let index = -1;
    this.riverMonitoring_details.floraPictures.forEach((element: any, index: any) => {
      this.addFloraPicture(element)
    });
  }

  populateSurroundingPictures() {
    let index = -1;
    this.riverMonitoring_details.surroundingPictures.forEach((element: any, index: any) => {
      this.addSurroundingPicture(element)
    });
  }

  populateRiverPictures() {
    let index = -1;
    this.riverMonitoring_details.riverPictures.forEach((element: any, index: any) => {
      this.addRiverPicture(element)
    });
  }

  populateSurroundings() {
    this.riverMonitoring_details.surroundings.forEach((element: string) => {
      const index = this.SURROUNDINGS.map(e => e.name).indexOf(element);
      this.SURROUNDINGS[index].selected = true;

      //Update Form
      this.addSurrounding(element);
    });
  }

  //All Form Arrays
  //River Picture Form Array
  riverPictures(i?: number): FormArray {
    return this.activityForm!.get("riverPictures") as FormArray
  }

  getRiverPictures() {
    let index = -1;
    this.riverMonitoring_details.riverPictures.forEach((element: any, index: any) => {
      this.addRiverPicture(element)
    });
  }

  newRiverPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addRiverPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.riverPictures().push(this.newRiverPicture(item));
  }
  removeRiverPicture(index: number) {
    this.imageFilesRiver.splice(index, 1);
    this.riverPictures().removeAt(index);
  }

  removeAllRiverPictures() {
    this.imageFilesRiver = [];
    this.riverPictures().clear();
  }
  //End of River

  //Surrounding Picture Form Array
  surroundingPictures(i?: number): FormArray {
    return this.activityForm!.get("surroundingPictures") as FormArray
  }

  getSurroundingPictures() {
    let index = -1;
    this.riverMonitoring_details.surroundingPictures.forEach((element: any, index: any) => {
      this.addSurroundingPicture(element)
    });
  }
  newSurroundingPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addSurroundingPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.surroundingPictures().push(this.newSurroundingPicture(item));

  }
  removeSurroundingPicture(index: number) {
    this.imageFilesSurrounding.splice(index, 1);
    this.surroundingPictures().removeAt(index);
  }

  removeAllSurroundingPictures() {
    this.imageFilesSurrounding = [];
    this.surroundingPictures().clear();
  }
  //End of Surrounding

  //Flora Picture Form Array
  floraPictures(i?: number): FormArray {
    return this.activityForm!.get("floraPictures") as FormArray
  }

  getFloraPictures() {
    let index = -1;
    this.riverMonitoring_details.floraPictures.forEach((element: any, index: any) => {
      this.addFloraPicture(element)
    });
  }
  newFloraPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addFloraPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.floraPictures().push(this.newFloraPicture(item));
  }
  removeFloraPicture(index: number) {
    this.imageFilesFlora.splice(index, 1);
    this.floraPictures().removeAt(index);
  }

  removeAllFloraPictures() {
    this.imageFilesFlora = [];
    this.floraPictures().clear();
  }
  //End of Flora

  //Fauna Picture Form Array
  faunaPictures(i?: number): FormArray {
    return this.activityForm!.get("faunaPictures") as FormArray
  }

  getFaunaPictures() {
    let index = -1;
    this.riverMonitoring_details.faunaPictures.forEach((element: any, index: any) => {
      this.addFaunaPicture(element)
    });
  }
  newFaunaPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addFaunaPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.faunaPictures().push(this.newFaunaPicture(item));
  }
  removeFaunaPicture(index: number) {
    this.imageFilesFauna.splice(index, 1);
    this.faunaPictures().removeAt(index);
  }

  removeAllFaunaPictures() {
    this.imageFilesFauna = [];
    this.faunaPictures().clear();
  }
  //End of Fauna

  //Activity Picture Form Array
  activityPictures(i?: number): FormArray {
    return this.activityForm!.get("activityPictures") as FormArray
  }

  getActivityPictures() {
    let index = -1;
    this.riverMonitoring_details.activityPictures.forEach((element: any, index: any) => {
      this.addActivityPicture(element)
    });
  }
  newActivityPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addActivityPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.activityPictures().push(this.newActivityPicture(item));
  }
  removeActivityPicture(index: number) {
    this.imageFilesActivity.splice(index, 1);
    this.activityPictures().removeAt(index);
  }

  removeAllActivityPictures() {
    this.imageFilesActivity = [];
    this.activityPictures().clear();
  }
  //End of ACtivity

  //group Picture Form Array
  groupPictures(i?: number): FormArray {
    return this.activityForm!.get("groupPictures") as FormArray
  }

  getGroupPictures() {
    let index = -1;
    this.riverMonitoring_details.groupPictures.forEach((element: any, index: any) => {
      this.addGroupPicture(element)
    });
  }
  newGroupPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addGroupPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.groupPictures().push(this.newGroupPicture(item));
  }
  removeGroupPicture(index: number) {
    this.imageFilesGroup.splice(index, 1);
    this.groupPictures().removeAt(index);
  }

  removeAllGroupPictures() {
    this.imageFilesGroup = [];
    this.groupPictures().clear();
  }
  //End of group picture


  //artwork Picture Form Array
  artworkPictures(i?: number): FormArray {
    return this.activityForm!.get("artworkPictures") as FormArray
  }

  getArtworkPictures() {
    let index = -1;
    this.riverMonitoring_details.artworkPictures.forEach((element: any, index: any) => {
      this.addArtworkPicture(element)
    });
  }
  newArtworkPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addArtworkPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.artworkPictures().push(this.newArtworkPicture(item));
  }
  removeArtworkPicture(index: number) {
    this.imageFilesArtwork.splice(index, 1);
    this.artworkPictures().removeAt(index);
  }

  removeAllArtworkPictures() {
    this.imageFilesArtwork = [];
    this.artworkPictures().clear();
  }
  //End of artwork 

  //Surrounding Array
  surroundings(i?: number): FormArray {
    return this.activityForm!.get("surroundings") as FormArray
  }

  getSurroundings() {
    let index = -1;
    this.riverMonitoring_details.surroundings.forEach((element: any, index: any) => {
      this.addSurrounding(element)
    });
  }
  // newSurrounding(item): FormGroup {
  //   return item.name
  // }

  addSurrounding(item: any) {
    this.surroundings().push(new FormControl(item));
    console.log(this.activityForm!.value);
  }
  removeSurrounding(index: number) {
    this.surroundings().removeAt(index);
  }


  onScroll() {
    this.pageNumber = this.pageNumber + 1;
  }

  navigate(mode: string) {
    if (mode == 'list') {
      this.router.navigate(['./river-monitoring']);
    }
    if (mode == 'home') {
      this.router.navigate(['./home']);
    }
  }

  defaultError(err: { error: String; }) {
    this.gotoTop();
    this.spinnerService.setSpinner(false);
    this.errorMessage = err.error;
    this.clearErrorMessage();
  }

  clearErrorMessage() {
    setTimeout(() => {
      this.errorMessage = "";
    }, 5000);
  }

  setBacteria(name: any) {
    this.activityForm!.patchValue({
      waterTesting: {
        bacteria: name
      }
    });
  }


  goBack(river_monitoring_stepper: MatStepper){
    river_monitoring_stepper.previous();
  }

  goForward(){
    this.river_monitoring_stepper.next();
  } 
}
