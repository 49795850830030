import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Route, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
declare var Orol: any;
import { Observable, Subject, catchError, of, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrolService {
  baseURL:String = "https://api.ourriverourlife.com/";
  public userDetailsSubject = new BehaviorSubject({});
  userDetails = this.userDetailsSubject.asObservable();
  constructor(private router: Router, public httpClient: HttpClient, private spinnerService: SpinnerService) { }

  public getBlogs() {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    // this.spinnerService.setSpinner(true);
    // const httpHeaders: HttpHeaders = new HttpHeaders({
    //   Authorization: 'Bearer ' + user.accessToken
    // });
    return this.httpClient.get(this.baseURL + "blogs");
  }
  public getRecentBlogs() {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    // const httpHeaders: HttpHeaders = new HttpHeaders({
    //   Authorization: 'Bearer ' + user.accessToken
    // });
    return this.httpClient.get(this.baseURL + "blogs/top10");
  }

  public createFishSanctuary(obj: { locationDetails: { name: string | Blob; sanctuaryPictures: any; riverName: string | Blob; activityDate: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; extent: string | Blob; }; speciesPictures: any; habitatCharacteristics: { physicalInfrastructure: string | Blob; leftBankHabitat: { naturalVegetation: string | Blob; naturalPhysicalFeatures: string | Blob; manMadePhysicalFeatures: string | Blob; bankErosion: string | Blob; }; rightBankHabitat: { naturalVegetation: string | Blob; naturalPhysicalFeatures: string | Blob; manMadePhysicalFeatures: string | Blob; bankErosion: string | Blob; }; }; managementActions: { foodProvisioning: string | Blob; fishingAnglingAllowed: string | Blob; swimmingAllowed: string | Blob; patrollingAllowed: string | Blob; }; culturalHistoricalSignificance: string | Blob; }, sanctuaryFiles: File[], speciesFiles: File[]) {
    // this.spinnerService.setSpinner(true);
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    for (var i = 0; i < sanctuaryFiles.length; i++) {
      form.append('sanctuaryFiles', sanctuaryFiles[i]);
    }
    for (var i = 0; i < speciesFiles.length; i++) {
      form.append('speciesFiles', speciesFiles[i]);
    }


    form.append("userId", user.id);
    form.append("locationDetails[name]", obj.locationDetails.name);
    form.append("locationDetails[sanctuaryPictures]", JSON.stringify(obj.locationDetails.sanctuaryPictures));
    form.append("locationDetails[riverName]", obj.locationDetails.riverName);
    form.append("locationDetails[activityDate]", obj.locationDetails.activityDate);
    form.append("locationDetails[location]", obj.locationDetails.location);
    form.append("locationDetails[latitude]", obj.locationDetails.latitude);
    form.append("locationDetails[longitude]", obj.locationDetails.longitude);
    form.append("locationDetails[extent]", obj.locationDetails.extent);
    form.append("speciesPictures", JSON.stringify(obj.speciesPictures));

    form.append("habitatCharacteristics[physicalInfrastructure]", obj.habitatCharacteristics.physicalInfrastructure);
    form.append("habitatCharacteristics[leftBankHabitat][naturalVegetation]", obj.habitatCharacteristics.leftBankHabitat.naturalVegetation);
    form.append("habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures]", obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures);
    form.append("habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures]", obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures);
    form.append("habitatCharacteristics[leftBankHabitat][bankErosion]", obj.habitatCharacteristics.leftBankHabitat.bankErosion);
    form.append("habitatCharacteristics[rightBankHabitat][naturalVegetation]", obj.habitatCharacteristics.rightBankHabitat.naturalVegetation);
    form.append("habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures]", obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures);
    form.append("habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures]", obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures);
    form.append("habitatCharacteristics[rightBankHabitat][bankErosion]", obj.habitatCharacteristics.rightBankHabitat.bankErosion);
    form.append("managementActions[foodProvisioning]", obj.managementActions.foodProvisioning);
    //  form.append("managementActions[foodProvisioning][type]", obj.managementActions.foodProvisioning.type);
    // form.append("managementActions[foodProvisioning][description]", obj.managementActions.foodProvisioning.description);
    form.append("managementActions[fishingAnglingAllowed]", obj.managementActions.fishingAnglingAllowed);
    form.append("managementActions[swimmingAllowed]", obj.managementActions.swimmingAllowed);
    form.append("managementActions[patrollingAllowed]", obj.managementActions.patrollingAllowed);
    // form.append("managementActions[patrollingAllowed][type]", obj.managementActions.patrollingAllowed.type);
    // form.append("managementActions[patrollingAllowed][description]", obj.managementActions.patrollingAllowed.description);
    form.append("culturalHistoricalSignificance", obj.culturalHistoricalSignificance);

    console.log(form);

    console.log(obj);

    return this.httpClient.post(this.baseURL + "fish-sanctuaries/create-fish-sanctuary", form, { headers: httpHeaders });



  }

  public createWaterTestDetails(x: { generalInformation: { activityDate: string | Blob; activityTime: string | Blob; testerName: string | Blob; latitude: string | Blob; longitude: string | Blob; location: string | Blob; }; waterLevelAndWeather: { airTemperature: string | Blob; waterLevel: string | Blob; weather: string | Blob; }; surroundings: string | Blob; waterTesting: { waterTemperature: string | Blob; pH: string | Blob; dissolvedOxygen: string | Blob; hardness: string | Blob; nitrate: string | Blob; nitrite: string | Blob; chlorine: string | Blob; alkalinity: string | Blob; iron: string | Blob; bacteria: string | Blob; turbidity: string | Blob; phosphate: string | Blob; ammonia: string | Blob; lead: string | Blob; totalDissolvedSolids: string | Blob; conductivity: string | Blob; }; flora: string | Blob; fauna: string | Blob; artwork: string | Blob; groupPicture: string | Blob; activity: string | Blob; river: string | Blob; }, imageFilesRiver: File[], imageFilesSurrounding: File[], imageFilesFlora: File[],
    imageFilesFauna: File[], imageFilesGroup: File[], imageFilesActivity: File[], imageFilesArtwork: File[]) {
    // this.spinnerService.setSpinner(true);
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    for (var i = 0; i < imageFilesRiver.length; i++) {
      form.append('river', imageFilesRiver[i]);
    }
    for (var i = 0; i < imageFilesSurrounding.length; i++) {
      form.append('surrounding', imageFilesSurrounding[i]);
    }
    for (var i = 0; i < imageFilesFlora.length; i++) {
      form.append('flora', imageFilesFlora[i]);
    }
    for (var i = 0; i < imageFilesFauna.length; i++) {
      form.append('fauna', imageFilesFauna[i]);
    }
    for (var i = 0; i < imageFilesGroup.length; i++) {
      form.append('groupPicture', imageFilesGroup[i]);
    }
    for (var i = 0; i < imageFilesActivity.length; i++) {
      form.append('activity', imageFilesActivity[i]);
    }
    for (var i = 0; i < imageFilesArtwork.length; i++) {
      form.append('artwork', imageFilesArtwork[i]);
    }
    form.append("userId", user.id);
    form.append("generalInformation[activityDate]", x.generalInformation.activityDate);
    form.append("generalInformation[activityTime]", x.generalInformation.activityTime);
    form.append("generalInformation[testerName]", x.generalInformation.testerName);
    form.append("generalInformation[latitude]", x.generalInformation.latitude);
    form.append("generalInformation[longitude]", x.generalInformation.longitude);
    form.append("generalInformation[location]", x.generalInformation.location);
    form.append("waterLevelAndWeather[airTemperature]", x.waterLevelAndWeather.airTemperature);
    form.append("waterLevelAndWeather[waterLevel]", x.waterLevelAndWeather.waterLevel);
    form.append("waterLevelAndWeather[weather]", x.waterLevelAndWeather.weather);
    form.append("surroundings", x.surroundings);
    form.append("waterTesting[waterTemperature]", x.waterTesting.waterTemperature);
    form.append("waterTesting[pH]", x.waterTesting.pH);
    form.append("waterTesting[dissolvedOxygen]", x.waterTesting.dissolvedOxygen);
    form.append("waterTesting[hardness]", x.waterTesting.hardness);
    form.append("waterTesting[nitrate]", x.waterTesting.nitrate);
    form.append("waterTesting[nitrite]", x.waterTesting.nitrite);
    form.append("waterTesting[chlorine]", x.waterTesting.chlorine);
    form.append("waterTesting[alkalinity]", x.waterTesting.alkalinity);
    form.append("waterTesting[iron]", x.waterTesting.iron);
    form.append("waterTesting[bacteria]", x.waterTesting.bacteria);
    form.append("waterTesting[turbidity]", x.waterTesting.turbidity);
    form.append("waterTesting[phosphate]", x.waterTesting.phosphate);
    form.append("waterTesting[ammonia]", x.waterTesting.ammonia);
    form.append("waterTesting[lead]", x.waterTesting.lead);
    form.append("waterTesting[totalDissolvedSolids]", x.waterTesting.totalDissolvedSolids);
    form.append("waterTesting[conductivity]", x.waterTesting.conductivity);
    form.append("flora", x.flora);
    form.append("fauna", x.fauna);
    form.append("artwork", x.artwork);
    form.append("groupPicture", x.groupPicture);
    form.append("activity", x.activity);
    form.append("river", x.river);
    // form.append("certificateURL", x.certificateURL);
    return this.httpClient.post(this.baseURL + "water-test-details/create-web", form, { headers: httpHeaders });

  }


  public generateReport(x: any) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });



    return this.httpClient.post(this.baseURL + "pdf/generateReportWeb", x, { headers: httpHeaders });

  }

  public getAccessToken(username: any, mode: string) { //Username can be email or phone number
    var obj;
    if (mode == "phone") {
      obj = {
        "phoneNumber": username
      };
    }
    else {//email
      obj = {
        "email": username
      };
    }
    this.spinnerService.setSpinner(true);
    return this.httpClient.post(this.baseURL + "user/auth", obj);
  }

  public signInPhone(phone: any) {
    this.spinnerService.setSpinner(true);
    var obj = {
      "phoneNumber": phone
    };
    return this.httpClient.post(this.baseURL + "user/sign-in", obj);
  }
  // public signInWeb(email: string, phone: string, firstName: string, lastName: string) {//create user in the user table
  //   this.spinnerService.setSpinner(true);
  //   var obj = {
  //     "email": email,
  //     "phoneNumber": phone,
  //     "firstName": firstName,
  //     "lastName": lastName
  //   };
  //   return this.httpClient.post(this.baseURL + "user/sign-in-web", obj)
  //     .toPromise()
  //     .then((res) => {
  //       return res;
  //     })
  //     .catch(err => { console.log("Oops! Sign In Web " + err) });
  // }

  public create(email: string, phone: string, firstName: string, lastName: string)  {
    this.spinnerService.setSpinner(true);
    var obj = {
      "email": email,
      "phoneNumber": phone,
      "firstName": firstName,
      "lastName": lastName
    };
    return this.httpClient.post(this.baseURL + "user", obj)
    .pipe(
      catchError(err => {
          console.log('Handling error locally and rethrowing it...', err);
          return throwError(() => err);
      })
  )
  }

  // public errorHandler(error: HttpErrorResponse) {
  //   return Observable.throw(error.message || "server error.");
  // }

  public addAlert(x: { location: string | Blob; latitude: string | Blob; longitude: string | Blob; activityDate: string | Blob; activityTime: string | Blob; experience: string | Blob; }, images: File[]) {
    this.spinnerService.setSpinner(true);
    const form = new FormData;
    for (var i = 0; i < images.length; i++) {
      form.append('photos', images[i]);
    }

    form.append("location", x.location);
    form.append("latitude", x.latitude);
    form.append("longitude", x.longitude);
    form.append("activityDate", x.activityDate);
    form.append("activityTime", x.activityTime);
    form.append("experience", x.experience);

    this.httpClient.post(this.baseURL + "flood-alert/create-alert", form).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./home']);

      },
      (err) => {
        this.spinnerService.setSpinner(false);
      },
    );
  }


  public searchByDate(startDate: string, endDate: string) {
    this.spinnerService.setSpinner(true);
    return this.httpClient.get(this.baseURL + "flood-alert/searchByDate/search?start=" + startDate + "&end=" + endDate);
  }

  public getFloodAlert(page: string | number, limit: string | number) {
    this.spinnerService.setSpinner(true);
    return this.httpClient.get(this.baseURL + "flood-alert?page=" + page + "&limit=" + limit);
  }
  public getFishSanctuary(page: string | number, limit: string | number) {
    this.spinnerService.setSpinner(true);
    return this.httpClient.get(this.baseURL + "fish-sanctuaries?page=" + page + "&limit=" + limit);
  }

  public getWaterTestDetail(page: string | number, limit: string | number) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    // const httpHeaders: HttpHeaders = new HttpHeaders({
    //   Authorization: 'Bearer ' + user.accessToken
    // });
    return this.httpClient.get(this.baseURL + "water-test-details?page=" + page + "&limit=" + limit);
  }


  public getUser() {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    console.log(user);
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.get(this.baseURL + "user/" + user.id, { headers: httpHeaders });
  }

  public updateUser(userObj: any) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.put(this.baseURL + "user/" + user.id, userObj, { headers: httpHeaders });
  }


  public createblog(x: { templateType: string | Blob; userId: string | Blob; featuredTitle: string | Blob; featuredDescription: string | Blob; featuredVideo: string | Blob; featuredAdditionalVideos: string | Blob; }, images: File) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    form.append('featuredPhoto', images);

    form.append("templateType", x.templateType);
    form.append("userId", x.userId);
    form.append("featuredTitle", x.featuredTitle);
    form.append("featuredDescription", x.featuredDescription);
    form.append("featuredVideo", x.featuredVideo);
    form.append("featuredAdditionalVideos", x.featuredAdditionalVideos);

    this.httpClient.post(this.baseURL + "blogs/create-blog", form, { headers: httpHeaders }).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./home']);

      },
      (err) => {
        this.spinnerService.setSpinner(false);
      },
    );
  }
  public updateProfile(userInfo: { id: string | Blob; firstName: string | Blob; lastName: string | Blob; email: string | Blob; phoneNumber: string | Blob; avatarURL: string | Blob; }, images: File) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    var accessToken = user.accessToken;
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    // for (var i = 0; i < images.length; i++) {
    form.append('avatar', images);
    // }
    form.append("id", userInfo.id);
    form.append("firstName", userInfo.firstName);
    form.append("lastName", userInfo.lastName);
    form.append("email", userInfo.email);
    form.append("phoneNumber", userInfo.phoneNumber);
    form.append("avatarURL", userInfo.avatarURL);


    return this.httpClient.post(this.baseURL + "user/update-profile", form, { headers: httpHeaders });
    //  .subscribe(
    //   (res) => {
    //     res['accessToken']=accessToken; //required
    //     this.spinnerService.setSpinner(false);
    //     localStorage.removeItem('User');
    //     localStorage.setItem('User', JSON.stringify(res));
    //     this.userDetailsSubject.next(res);
    //     return res;
    //
    //   },
    //   (err) => {
    //     this.spinnerService.setSpinner(false);
    //     return err;
    //   },
    // );
  }


  public errorHandler(error: any) {
    if (error) {
      if (error == 'Error: Session expired') { //401 Unauthorized
        Orol.login();
        //emit login page
      }
      else if (error.status == 404) {
        this.router.navigate(['/', '404']);
      }
      else if (error.status == 500) {
        this.router.navigate(['/', '500']);
      }
    }
  }
}
