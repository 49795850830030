import { Component, OnInit } from '@angular/core';
import {OrolService} from '../../services/orol.service';
import {SpinnerService} from '../../services/spinner.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

@Component({
  selector: 'app-maps-fish-sanctuaries',
  templateUrl: './maps-fish-sanctuaries.component.html',
  styleUrls: ['./maps-fish-sanctuaries.component.scss']
})
export class MapsFishSanctuariesComponent implements OnInit {
  center: any = {lat: 27.891535, lng: 78.078743};
  public zoom: number = 4;

  mapOptions: google.maps.MapOptions = {
  // initial center position for the map
    center: this.center,
    zoom: this.zoom,
    mapId: 'DEMO_MAP_ID',
  };

  public iconUrl = '../../../assets/icons/marker.svg';
  public markers:any[] =[];
  public markers2:any[] =[];
  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  selectedMarker: any;

  constructor(private orolService: OrolService, private spinnerService:SpinnerService) {
  }

  ngOnInit(){
    this.getFishSanctuary();
  }

  async initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement  } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
    const infoContent = document.getElementById("iw-content") as HTMLElement
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement, this.mapOptions
    );

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
   
    // Add some markers to the map.
    const markers = this.markers.map((marker1:any, i:number) => {
      var lat = marker1.locationDetails.latitude;
      var lng = marker1.locationDetails.longitude;
      if(lat && lng){
        map.panTo(new google.maps.LatLng(lat + 6, lng));
      const label = labels[i % labels.length];
      const pinGlyph = new google.maps.marker.PinElement({
        glyph: label,
        glyphColor: "white",
      })

      const marker = new google.maps.marker.AdvancedMarkerElement({
          position: {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
          },
        content: pinGlyph.element,
      });
      // markers can only be keyboard focusable when they have click listeners
      // open info window when marker is clicked
      marker.addListener("click", () => {
        this.selectedMarker= marker1;
        infoWindow.setContent(infoContent);
        infoWindow.open(map, marker);
      });
     
      return marker;
    }
    else{
      return new google.maps.marker.AdvancedMarkerElement();
    }
    });
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({markers, map})
  }

  addMarker(data:any) {
    console.log("boo")
    console.log(data)
    for(var i=0; i<data.length;i++){
      console.log(data[i]);
      this.markers.push(data[i])
      // this.markers.push({
      //   lat:data[i]['locationDetails'].latitude,
      //   lng: data[i]['locationDetails'].longitude,
      // });
    }
    if(this.totalNumberOfPages == this.page){
      this.initMap();
    }
  }

  getFishSanctuary() {
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }

    this.orolService.getFishSanctuary(this.page, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
        this.addMarker(data['rows'])
        if(this.currentPageCounter <= this.totalNumberOfPages - 1){
          this.paginate=true;
          this.getFishSanctuary();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }

}

