import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { WindowService } from '../../../services/window.service';
import { OrolService } from '../../../services/orol.service';
import { SpinnerService } from '../../../services/spinner.service';
import { MdbModalRef } from 'mdb-angular-ui-kit/modal';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements AfterViewInit {
  windowRef: any;
  errorMessage: any;
  public otp: number | undefined;
  phoneNumber: any;

  constructor(private orolService: OrolService, 
    public modalRefVerifyOTP: MdbModalRef<VerifyOtpComponent>,
    private win: WindowService, 
    private router: Router, 
    private spinnerService: SpinnerService) { 
  }

  ngAfterViewInit(): void {
  }

  ngOnInit(): void {
    this.windowRef = this.win.windowRef;
  }

  onOtpChange(event: number | undefined) {
    this.otp = event;
  }

  verifyOTPCode() {
    this.spinnerService.setSpinner(true);
    this.windowRef.confirmationResult
      .confirm(this.otp)
      .then((result: any) => {
        this.getAccessToken("phone");
        this.modalRefVerifyOTP.close();
      })
      .catch((error:Error) => {
        this.spinnerService.setSpinner(false);
        this.errorMessage = "Incorrect code entered";
        console.log(error, "Incorrect code entered")
      });
  }

  getAccessToken(mode: string) {
    this.orolService.getAccessToken(this.phoneNumber, mode).subscribe((res:any) => {
      var user = res.user;
      const userJson = localStorage.getItem('User');
      var storedUser;
      var accessToken
      if (userJson) {
        storedUser = JSON.parse(userJson);
        if(user.access==undefined){
          accessToken=storedUser.accessToken;
        }else{
          accessToken=user.access
        }
      } else {
        console.log('User data not found in localStorage.');
      }
      console.log(user.access);
      const User: any = {
        'id': user.id,
        'accessToken': accessToken,
        'firstName': user.firstName,
        'lastName': user.lastName,
        'phoneNumber': user.phoneNumber,
        'email': user.email,
        'avatarURL': user.avatarURL,
      }
      localStorage.setItem('User', JSON.stringify(User));
      this.orolService.userDetailsSubject.next(JSON.stringify(User));

      if (user.firstName == '' || user.firstName == undefined || user.lastName == '' || user.lastName == undefined || user.email == '' || user.email == undefined) {
        ///route to edit profile page
        this.router.navigate(['./my-account']);
        this.spinnerService.setSpinner(false);
      }
      else {
        this.router.navigate(['./home']);
        window.location.reload();
        this.spinnerService.setSpinner(false);
      }
    });
  }
}
