import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-articledetails',
  templateUrl: './articledetails.component.html',
  styleUrls: ['./articledetails.component.scss']
})
export class ArticledetailsComponent implements OnInit {

  data: any = {};
  loading = true;
  errors: any;
  public safeURL: SafeResourceUrl|undefined;

  constructor(private http: HttpClient, private route: ActivatedRoute, private _sanitizer: DomSanitizer) { }

  ngOnInit() {

    // this.http.get('https://blogadmin.ourriverourlife.com/blogs/' + this.route.snapshot.paramMap.get("id")).subscribe(result => {

    //   this.data = result;
    //   this.data.description = this.data.description.replaceAll('/uploads', 'https://blogadmin.ourriverourlife.com/uploads');
    //   console.log(this.data.description);
    //   this.data.description = this._sanitizer.bypassSecurityTrustHtml(this.data.description);
    //   this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.data.videocode);
    // });
    // setTimeout(() => {
    //   window.scrollTo(0, 0);
    // }, 500);


  }

  ngOnDestroy() {
    // this.queryArticle.unsubscribe();
  }

}
