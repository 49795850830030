import { Component, OnInit } from '@angular/core';
import {CdkAccordionModule} from '@angular/cdk/accordion';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  imports: [CdkAccordionModule],
})
export class FeaturesComponent implements OnInit {
  feature1_show:boolean=false;
  feature2_show:boolean=false;
  feature3_show:boolean=false;
  feature4_show:boolean=false;
  items = [
    {
      name:'River Monitoring',
      minText:'Get involved by creating a group to observe, document and monitor the health of your rivers, streams or lakes.', 
      imgURL:'../../assets/icons/river_monitoring_white_icon.svg', 
      description:'The river monitoring web app allows you to document basic observations, along with water quality testing and '+
      ' documentation of flora and fauna, in locations chosen by you for long term monitoring.',
      routerLink:'/river-monitoring'
    },
    
    {
      'name':'Flood Watch',
      minText:'Flood Watch enables you to mark real time flood alerts along with documentation and images.', 
      imgURL:'../../assets/icons/flood_watch_white_icon.svg', 
      description:'Our aim is to bring visibility to the ground situation, taking into consideration your experiences.'+
      ' With climate change and an increase in flooding events, timely alerts can help inform citizens and authorities'+
      ' with map locations and visual media, to monitor flooding in real time. Monitoring flooding events on a yearly'+
      ' basis can further help citizens, government authorities and scientists alike, in bolstering disaster'+
      ' preparedness and flood response during the monsoon.',
      routerLink:'/flood-watch'
    },

    {
      'name':'Flora & Fauna',
      minText:'Help us discover and document the natural wonders of freshwater ecosystems, through your eyes and lenses.', 
      imgURL:'../../assets/icons/flora_fauna_white_icon.svg', 
      description:'Freshwater ecosystems have unique flora and fauna, so many of which are yet to be described or documented by'+
      ' science.'+
      ' With decades of alteration and modification of river banks and declining water quality in our freshwater'+
      ' ecosystems, it is challenging to understand the pace at which we are losing aquatic flora and fauna. With long'+
      ' term monitoring, it becomes easier to assess the status of species, enabling conservation planning and action to'+
      ' help protect the river ecosystem as a whole.',
      routerLink:'/flora-fauna'
    },

    {
      'name':'Fish Sanctuaries',
      minText:'Help us document and better understand Fish Sanctuaries across India.',
      imgURL:'../../assets/icons/voices_from_the_river_white_icon.svg',
      'description':'Fish sanctuaries can be found as formal or informal protected stretches of rivers in India,'+
      ' offering protection to freshwater fish. This portal is the first citizen-science platform to collate'+
      ' information on existing fish sanctuaries across India. The ultimate goal is to create a database of all existing fish sanctuaries to better enable their protection, especially'+
      ' since many are being threatened or destroyed without having been documented.',
      routerLink:'/fish-sanctuaries'
    }
  ];
  expandedIndex = 0;
  constructor() { }

  ngOnInit(): void {
  }

  featureShow(index:any){
    if(index == 0){
      this.feature1_show=true;
    }
    else if(index == 1){
      this.feature2_show=true;
    }
    else if(index == 2){
      this.feature3_show=true;
    }
    else if(index == 3){
      this.feature4_show=true;
    }
  }
  featureHide(index:any){
    if(index == 0){
      this.feature1_show=false;
    }
    else if(index == 1){
      this.feature2_show=false;
    }
    else if(index == 2){
      this.feature3_show=false;
    }
    else if(index == 3){
      this.feature4_show=false;
    }
  }
   showOpenArrow = (index:any) => {
    if(index == 0){
      return !this.feature1_show;
    }
    else if(index == 1){
      return !this.feature2_show;
    }
    else if(index == 2){
      return !this.feature3_show;
    }
    else if(index == 3){
      return !this.feature4_show;
    }
    else{
      return null;
    }
  }

  showCloseArrow = (index:any) => {
    if(index == 0){
      return this.feature1_show;
    }
    else if(index == 1){
      return this.feature2_show;
    }
    else if(index == 2){
      return this.feature3_show;
    }
    else if(index == 3){
      return this.feature4_show;
    }
    else{
      return null;
    }
  }

}
