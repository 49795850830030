import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';

@Injectable({
  providedIn: 'root'
})
export class FishSanctuariesService {
  baseURL:String = "https://api.ourriverourlife.com/";

  constructor(private router: Router, public httpClient: HttpClient, private spinnerService: SpinnerService) { }

  /**
  * Method to get the list of fish sanctuaries
  * @param { page } page - pageNumber
  * @param { limit } limit - limit of records to be fetched
  * @returns { Promise } - Object {"access_token": "", "token_type": "Bearer","expires_in": 1637589288}
  **/
  public getFishSanctuarie(page: string | number, limit: string | number) {
    return this.httpClient.get(this.baseURL + "fish-sanctuaries?page=" + page + "&limit=" + limit);
  }

  public getFishSanctuariesDetails(id: string | null) {
    this.spinnerService.setSpinner(true);
    return this.httpClient.get(this.baseURL + "fish-sanctuaries/" + id);
  }

  public createFishSanctuary(obj: { locationDetails: { name: string | Blob; sanctuaryPictures: any; riverName: string | Blob; activityDate: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; extent: string | Blob; }; speciesPictures: any; habitatCharacteristics: { physicalInfrastructure: string | any[]; leftBankHabitat: { naturalVegetation: string | any[]; naturalPhysicalFeatures: string | any[]; manMadePhysicalFeatures: string | any[]; bankErosion: string | Blob; }; rightBankHabitat: { naturalVegetation: string | any[]; naturalPhysicalFeatures: string | any[]; manMadePhysicalFeatures: string | any[]; bankErosion: string | Blob; }; }; managementActions: { foodProvisioning: { type: string | Blob; description: string | Blob; }; fishingAnglingAllowed: { type: string | Blob; }; swimmingAllowed: { type: string | Blob; }; patrollingAllowed: { type: string | Blob; description: string | Blob; }; }; culturalHistoricalSignificance: string | Blob; }, sanctuaryFiles: File[], speciesFiles: File[]) {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    for (var i = 0; i < sanctuaryFiles.length; i++) {
      form.append('sanctuaryFiles', sanctuaryFiles[i]);
    }
    for (var i = 0; i < speciesFiles.length; i++) {
      form.append('speciesFiles', speciesFiles[i]);
    }

    form.append("userId", user.id);
    form.append("contributorName", user.firstName + ' ' + user.lastName)
    form.append("locationDetails[name]", obj.locationDetails.name);
    form.append("locationDetails[sanctuaryPictures]", JSON.stringify(obj.locationDetails.sanctuaryPictures));
    form.append("locationDetails[riverName]", obj.locationDetails.riverName);
    form.append("locationDetails[activityDate]", obj.locationDetails.activityDate);
    form.append("locationDetails[location]", obj.locationDetails.location);
    form.append("locationDetails[latitude]", obj.locationDetails.latitude);
    form.append("locationDetails[longitude]", obj.locationDetails.longitude);
    form.append("locationDetails[extent]", obj.locationDetails.extent);
    form.append("speciesPictures", JSON.stringify(obj.speciesPictures));

    for (var i = 0; i < obj.habitatCharacteristics.physicalInfrastructure.length; i++) {
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][id]', obj.habitatCharacteristics.physicalInfrastructure[i].id);
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][name]', obj.habitatCharacteristics.physicalInfrastructure[i].name);
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][description]', obj.habitatCharacteristics.physicalInfrastructure[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.naturalVegetation.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.naturalVegetation.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].description);
    }
    form.append("habitatCharacteristics[leftBankHabitat][bankErosion]", obj.habitatCharacteristics.leftBankHabitat.bankErosion);
    form.append("habitatCharacteristics[rightBankHabitat][bankErosion]", obj.habitatCharacteristics.rightBankHabitat.bankErosion);
    form.append("managementActions[foodProvisioning][type]", obj.managementActions.foodProvisioning.type);
    form.append("managementActions[foodProvisioning][description]", obj.managementActions.foodProvisioning.description);
    form.append("managementActions[fishingAnglingAllowed][type]", obj.managementActions.fishingAnglingAllowed.type);
    form.append("managementActions[swimmingAllowed][type]", obj.managementActions.swimmingAllowed.type);
    form.append("managementActions[patrollingAllowed][type]", obj.managementActions.patrollingAllowed.type);
    form.append("managementActions[patrollingAllowed][description]", obj.managementActions.patrollingAllowed.description);
    form.append("culturalHistoricalSignificance", obj.culturalHistoricalSignificance);

    return this.httpClient.post(this.baseURL + "fish-sanctuaries/create-fish-sanctuary", form, { headers: httpHeaders });
  }

  public updateFishSanctuary(id: string, obj: { locationDetails: { name: string | Blob; sanctuaryPictures: any; riverName: string | Blob; activityDate: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; extent: string | Blob; }; speciesPictures: any; habitatCharacteristics: { physicalInfrastructure: string | any[]; leftBankHabitat: { naturalVegetation: string | any[]; naturalPhysicalFeatures: string | any[]; manMadePhysicalFeatures: string | any[]; bankErosion: string | Blob; }; rightBankHabitat: { naturalVegetation: string | any[]; naturalPhysicalFeatures: string | any[]; manMadePhysicalFeatures: string | any[]; bankErosion: string | Blob; }; }; managementActions: { foodProvisioning: { type: string | Blob; description: string | Blob; }; fishingAnglingAllowed: { type: string | Blob; }; swimmingAllowed: { type: string | Blob; }; patrollingAllowed: { type: string | Blob; description: string | Blob; }; }; culturalHistoricalSignificance: string | Blob; recognizeFish: string | Blob; }, sanctuaryFiles: File[], speciesFiles: File[]) {
    console.log("in service")
    console.log(obj.locationDetails.name);
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    // const httpHeaders: HttpHeaders = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarydMIgtiA2YeB1Z0kl'
    // });
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    for (var i = 0; i < sanctuaryFiles.length; i++) {
      form.append('sanctuaryFiles', sanctuaryFiles[i]);
    }
    for (var i = 0; i < speciesFiles.length; i++) {
      form.append('speciesFiles', speciesFiles[i]);
    }

    form.append("userId", user.id);
    form.append("contributorName", user.firstName + ' ' + user.lastName)
    form.append("locationDetails[name]", obj.locationDetails.name);
    form.append("locationDetails[sanctuaryPictures]", JSON.stringify(obj.locationDetails.sanctuaryPictures));
    form.append("locationDetails[riverName]", obj.locationDetails.riverName);
    form.append("locationDetails[activityDate]", obj.locationDetails.activityDate);
    form.append("locationDetails[location]", obj.locationDetails.location);
    form.append("locationDetails[latitude]", obj.locationDetails.latitude);
    form.append("locationDetails[longitude]", obj.locationDetails.longitude);
    form.append("locationDetails[extent]", obj.locationDetails.extent);
    form.append("speciesPictures", JSON.stringify(obj.speciesPictures));

    for (var i = 0; i < obj.habitatCharacteristics.physicalInfrastructure.length; i++) {
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][id]', obj.habitatCharacteristics.physicalInfrastructure[i].id);
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][name]', obj.habitatCharacteristics.physicalInfrastructure[i].name);
      form.append('habitatCharacteristics[physicalInfrastructure][' + i + '][description]', obj.habitatCharacteristics.physicalInfrastructure[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.naturalVegetation.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][naturalVegetation][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.naturalVegetation[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][naturalPhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].id);
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].name);
      form.append('habitatCharacteristics[leftBankHabitat][manMadePhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.naturalVegetation.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][naturalVegetation][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.naturalVegetation[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][naturalPhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures[i].description);
    }
    for (var i = 0; i < obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures.length; i++) {
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][id]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].id);
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][name]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].name);
      form.append('habitatCharacteristics[rightBankHabitat][manMadePhysicalFeatures][' + i + '][description]', obj.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures[i].description);
    }
    form.append("habitatCharacteristics[leftBankHabitat][bankErosion]", obj.habitatCharacteristics.leftBankHabitat.bankErosion);
    form.append("habitatCharacteristics[rightBankHabitat][bankErosion]", obj.habitatCharacteristics.rightBankHabitat.bankErosion);
    form.append("managementActions[foodProvisioning][type]", obj.managementActions.foodProvisioning.type);
    form.append("managementActions[foodProvisioning][description]", obj.managementActions.foodProvisioning.description);
    form.append("managementActions[fishingAnglingAllowed][type]", obj.managementActions.fishingAnglingAllowed.type);
    form.append("managementActions[swimmingAllowed][type]", obj.managementActions.swimmingAllowed.type);
    form.append("managementActions[patrollingAllowed][type]", obj.managementActions.patrollingAllowed.type);
    form.append("managementActions[patrollingAllowed][description]", obj.managementActions.patrollingAllowed.description);
    form.append("culturalHistoricalSignificance", obj.culturalHistoricalSignificance);
    form.append("recognizeFish", obj.recognizeFish);

    return this.httpClient.put(this.baseURL + "fish-sanctuaries/update-fish-sanctuary/" + id, form, { headers: httpHeaders });
  }

  public deleteFishSanctuary(id: string){
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.delete(this.baseURL + "fish-sanctuaries/"+id, { headers: httpHeaders });
  }
}
