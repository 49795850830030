import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, FormArray } from '@angular/forms';
// import { MapsAPILoader } from '@agm/core';
// import { IMyOptions } from 'ng-uikit-pro-standard';
import { Observable, catchError, map, of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { OrolService } from '../../services/orol.service';
import { Event, Route, Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SpinnerService } from '../../services/spinner.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit {
  @ViewChild(GoogleMap, { static: false })
  map!: GoogleMap;
   //Related to google maps
   zoom = 12;
   center!: google.maps.LatLngLiteral;
   centerLoc!: google.maps.LatLngLiteral;
   geocoder: any;
   options: google.maps.MapOptions = {
     mapTypeId: 'hybrid',
     zoomControl: false,
     scrollwheel: false,
     disableDoubleClickZoom: true,
     maxZoom: 15,
     minZoom: 8,
   };


   markers = [] as any[];

   options2: any = {
    componentRestrictions: { country: 'IN' }
  } 

  imgResultBeforeCompress!: string;
  imgResultAfterCompress: String[] = [];
  note = ".jpg,.png, files accepted";
  info = "(Max. size 100KB)";
  public iconUrl = '../../../assets/icons/marker.svg';
  public imageFiles: File[] = [];
  public imageFile!: File;
  file: any;
  images: any = [];
  @ViewChild('search', { static: true })
  public searchElementRef!: ElementRef;
  public myDatePickerOptions: any = {
    dateFormat: 'dd mmm yyyy',
    closeAfterSelect: true
  };
  mapsForm!: FormGroup;
  decimalPattern = /^\d+(\.\d)?\d*$/;
  // public searchControl!: FormControl;
  show: boolean = false;
  buttonName = 'Show';
  hide: any;
  selectedImage: any = [];
  submitted: boolean = false;

  setMarker(event: google.maps.MapMouseEvent) {
    var latLng = event.latLng?.toJSON();
    var lat = latLng?.lat != null ? latLng?.lat : 0;
    var lng = latLng?.lng != null ? latLng?.lng : 0;
    this.addMarker(lat, lng)
    this.getAddressByLatitudeAndLongitude(lat, lng, this.mapsForm)
  }

  patchLatLngFormValue(lat:any, lng:any){
    this.mapsForm.patchValue({
      latitude: lat,
      longitude: lng,
    });
  }

  patchLocFormValue(loc:any){
    this.mapsForm.patchValue({
      location: loc
    });
  }

  addMarker(lat: any, lng: any, loc?:any) {
    this.markers=[];
    let position= {
      lat: lat,
      lng: lng
    };
    this.markers.push({
      position: {
        lat: lat,
        lng: lng
      },
      label: {
        className: 'marker-label',
        text: 'You are here',
     },
     title: 'Marker Title',
        options : {
        draggable: false, 
        icon: {url: this.iconUrl},
     }
      // options: { animation: google.maps.BOUNCE },
    });
    this.patchLatLngFormValue(lat, lng)
    this.map.panTo(position)
    this.spinnerService.setSpinner(false);
  }

  toggle(mode: string) {
    this.validate();
    this.show = !this.show;
    if (mode == '') {
      this.setCurrentPosition();
    }
  }

  constructor(public router: Router, private fb: FormBuilder, private http: HttpClient, private orolService: OrolService,
    private imageCompress: NgxImageCompressService,private spinnerService: SpinnerService) {
    this.createForm();
  }

  ngOnInit() {
    this.spinnerService.setSpinner(true);
    // this.searchControl = new FormControl();
    this.populateMap();
  }

  populateMap() {
    this.setCurrentTime();
    this.setCurrentPosition();
  }

  // placeMarker($event: { coords: { lat: any; lng: any; }; }) {
  //   this.mapsForm.patchValue({
  //     latitude: $event.coords.lat,
  //     longitude: $event.coords.lng,
  //     // location: place.formatted_address,
  //   });
  //   this.getAddressByLatitudeAndLongitude($event.coords.lat, $event.coords.lng, this.mapsForm);
  //   // this.geocodeLatLng();
  //   // this.centerLoc = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
  // }

  handleAddressChange(address: Address) {
    let location = address.formatted_address;
    let lat = address.geometry.location.lat();
    let lng = address.geometry.location.lng();
    this.patchLatLngFormValue(lat, lng);
    this.patchLocFormValue(location);
    this.addMarker(lat,lng);
    this.zoom = 12;
    // map.panTo(curmarker.position);
  }

  setCurrentTime() {
    let dt = new Date();
    let normalizeHour = dt.getHours() >= 13 ? dt.getHours() - 12 : dt.getHours();
    var hrs = normalizeHour.toString();
    let normalizeMins = dt.getMinutes();
    var mins = normalizeMins.toString();
    if (normalizeHour < 10) {
      hrs = "0" + normalizeHour;
    }

    if (normalizeMins < 10) {
      mins = "0" + mins;
    }

    var formattedTime = dt.getHours() >= 13 ? hrs + ':' + mins +  ' ' + 'PM' : hrs + ':' + mins +  ' ' +  'AM';
    this.mapsForm.patchValue({
      activityTime: formattedTime
    });
  }

  // On Change of lat and lng
  bla() {
    // this.getAddressByLatitudeAndLongitude(this.mapsForm.get('latitude')!.value, this.mapsForm.get('longitude')!.value, this.mapsForm);
    // this.centerLoc = { lat: this.mapsForm.get('latitude')!.value, lng: this.mapsForm.get('longitude')!.value };
    
    // this.getAddressByLatitudeAndLongitude(this.mapsForm!.get('latitude')!.value, 
    // this.mapsForm!.get('longitude')!.value,
    // this.mapsForm!);

    // this.centerLoc = {
    //   lat: this.mapsForm!.get('latitude')!.value, 
    //   lng:this.mapsForm!.get('longitude')!.value
    // };
    // this.patchLatLngFormValue(lat,lng)
    // this.addMarker()
  }

  // recenterMap() {
  //   // this.latitude = this.mapsForm.get('latitude').value;
  //   // this.longitude = this.mapsForm.get('longitude').value;
  // }

  createForm() {
    this.mapsForm = this.fb.group({
      location: ['', [Validators.required]],
      latitude: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],
      longitude: ['', [Validators.required, Validators.pattern(this.decimalPattern)]],
      activityDate: [(new Date()), [Validators.required]],
      activityTime: ['', [Validators.required]],
      photos: this.fb.array([]),
      experience: ['', Validators.maxLength(500)]
    });
  }

  validate() {
    this.submitted = true;
    if (this.mapsForm.get('location')!.valid &&
      this.mapsForm.get('latitude')!.valid &&
      this.mapsForm.get('longitude')!.valid &&
      this.mapsForm.get('activityDate')!.valid &&
      this.mapsForm.get('activityTime')!.valid
      // && this.imageFiles.length > 0
    ) {
    }
  }

  async getAddressByLatitudeAndLongitude(lat: number, lng: number, form: FormGroup<any>) {
    var address;
    this.geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);

    await this.geocoder.geocode({ latLng: latlng },  (results: { formatted_address: any; }[], status: string) => {
      if (status == google.maps.GeocoderStatus.OK) {
        var arrAddress = results;
        address = results[0].formatted_address;
        this.patchLocFormValue(address)
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  private setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var accuracy = position.coords.accuracy;
        this.mapsForm!.patchValue({
          latitude: +position.coords.latitude,
          longitude: +position.coords.longitude,
        });
        this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.mapsForm);
        this.addMarker(this.mapsForm!.get('latitude')!.value, this.mapsForm!.get('longitude')!.value);
      });
    }
    else {
    }
  }

  private setCurrentPosition1() {

    if (!navigator.geolocation) {
      console.log('Geolocation API not supported by this browser.');
    } else {
      console.log('Checking location...');
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    }

    // if (navigator.geolocation) {
    //   navigator.geolocation.getCurrentPosition(this.showPosition,this.showError);
    // } else {
    //   alert("Geolocation is not supported by this browser.");
    // }

    // navigator.permissions && navigator.permissions.query({name: 'geolocation'})
    // .then(function(PermissionStatus) {
    //     if (PermissionStatus.state == 'granted') {
    //          alert("allowed")
    //     } else if (PermissionStatus.state == 'prompt') {
    //           alert("denied")
    //     } else {
    //          alert("oh no denied")
    //     }
    // })
    // console.log(navigator.geolocation)
    // if (navigator.geolocation.getCurrentPosition!) {
    //   navigator.geolocation.getCurrentPosition((position) => {
    //     var accuracy = position.coords.accuracy;
    //     this.patchLatLngFormValue(+position.coords.latitude, +position.coords.longitude)
    //     this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
    //     this.addMarker(this.mapsForm!.get('latitude')!.value, this.mapsForm!.get('longitude')!.value);
    //     this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.mapsForm);
    //   });
    // }
    // else{
    //   alert("Geolocation is not supported by this browser.")
    // }
  }
   success(position:any) {
    console.log(position);
  }
  error(error:any) {
    alert('Geolocation error!');
    alert(error.message);
  }

  //  showPosition(position) {
  //  alert("Latitude: " + position.coords.latitude + "<br>Longitude: " + position.coords.longitude);    
  // }
  
  //  showError(error) {
  //   switch(error.code) {
  //     case error.PERMISSION_DENIED:
  //       console.log("User denied the request for Geolocation.")
  //       break;
  //     case error.POSITION_UNAVAILABLE:
  //      console.log("Location information is unavailable.")
  //       break;
  //     case error.TIMEOUT:
  //       console.log("The request to get user location timed out.")
  //       break;
  //     case error.UNKNOWN_ERROR:
  //       console.log("An unknown error occurred.")
  //       break;
  //   }
  // }

  async addAlert() {
    this.validate();
    await this.orolService.addAlert(this.mapsForm.value, this.imageFiles);
    this.show = false;
    this.setCurrentPosition();
    this.images = [];
    this.imageFiles = [];
  }
  deleteImage() {
    this.imageFiles = [];
    this.images = [];
    this.imgResultAfterCompress = [];
  }
  onFileChange(event: { target: { files: string | any[]; }; }) {
    this.images = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        this.imageFiles.push(event.target.files[i]);
        var _filename = "floodAlert_" + Date.now();
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);//base64
          this.compressFile(event.target.result, _filename);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  compressFile(base64URL: string, filename: string) {
    var orientation = -1;
    this.imgResultBeforeCompress = base64URL;
    this.imageCompress.compressFile(base64URL, orientation, 50, 50).then(
      (result) => {
        this.imgResultAfterCompress.push(result);
        this.imageFile = this.dataURLtoFile(result, filename);
      });
  }
  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
