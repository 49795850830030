import { Injectable, NgZone, inject } from '@angular/core';
import { User } from "../services/user";
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  signInWithPhoneNumber,
  ApplicationVerifier,
  getAuth,
  fetchSignInMethodsForEmail
} from '@angular/fire/auth';
// import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";
import { Subject, Subscription, throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { SpinnerService } from '../../services/spinner.service';
import { OrolService } from '../../services/orol.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  // Inject Firebase auth service
  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;

  public errorMessageSubject = new BehaviorSubject("");
  errorMessage = this.errorMessageSubject.asObservable();
  userData: any; // Save logged in user data

  constructor(
    // private win: WindowService,
    // public afs: AngularFirestore,   // Inject Firestore service
    // public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone,
    public orolService: OrolService,
    public spinnerService: SpinnerService
    // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.authStateSubscription = this.authState$.subscribe((user: any) => {
      //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
      // if (user) {
      //   this.userData = user;
      //   localStorage.setItem('User', JSON.stringify(this.userData));
      //   JSON.parse(localStorage.getItem('User')!);
      // } else {
      //   localStorage.setItem('User', "");
      // }
    })
    // this.afAuth.authState.subscribe((user: any) => {
    //   if (user) {
    //     this.userData = user;
    //     // localStorage.setItem('user', JSON.stringify(this.userData));
    //     // JSON.parse(localStorage.getItem('User')!);
    //   } else {
    //     // localStorage.setItem('user', null);
    //     // JSON.parse(localStorage.getItem('User')!);
    //   }
    // })
  }
  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.authStateSubscription.unsubscribe();
  }

  // Sign in with email/password
  signInWithEmailAndPassword(email: string, password: string) {
    return signInWithEmailAndPassword(this.auth, email, password);
  }
  // Sign in with phone number
  signInWithPhoneNumber(phone: any, appVerifier:any) {
    return signInWithPhoneNumber(this.auth, "+91" + phone, appVerifier);
  }

  // Sign up with email/password (Sign up to firebase and save user details in the user table)
  async SignUp(email: string, password: string, phoneNumber: string, firstName: string, lastName: string) {
    let firebaseUser:any;
    this.spinnerService.setSpinner(true);
    //Check if email already exists in firebase
    const auth = getAuth();
    // let signInMethods = await fetchSignInMethodsForEmail(auth, email);
    // if (signInMethods.length > 0) {
    //   alert("user exists")
    // } else {
    //   let signInMethods2 = await getUserByPhoneNumber(auth, email);
    //   alert("let us create for you")
    // }
        // Save Data in the local User Table
        return this.orolService.create(email, phoneNumber, firstName, lastName)
        .subscribe({
          next: (response) => {
            this.spinnerService.setSpinner(false);
            return createUserWithEmailAndPassword(this.auth, email, password)
            .then((fbUser: any) => {
              return this.SendVerificationMail(fbUser.user);
            })
            .then(() => {
              this.errorMessageSubject.next("Please check your email inbox for a verification email and login again.");
            })
            .catch((error: { code: any; message: string; }) => {
              this.spinnerService.setSpinner(false);
              switch (error.code) {
                case 'auth/email-already-in-use':{
                  // alert(auth.currentUser.emailVerified)
                  this.errorMessageSubject.next(`Email address ${email} already in use.`);
                  break;
                }
                case 'auth/invalid-email':
                  this.errorMessageSubject.next(`Email address ${email} is invalid.`);
                  break;
                case 'auth/operation-not-allowed':
                  this.errorMessageSubject.next(`Error during sign up.`);
                  break;
                case 'auth/weak-password':
                  this.errorMessageSubject.next('Password is not strong enough. Add additional characters including special characters and numbers.');
                  break;
                default:
                  this.errorMessageSubject.next(error.message);
                  break;
              }
            });
          },
          error: (err) => {
            this.spinnerService.setSpinner(false);
               console.log(err);
              this.errorMessageSubject.next(err.error.message);
              return throwError(() => err);
          }
      });
  }

  // Send email verfificaiton when new user sign up
  async SendVerificationMail(currentUser:any) {
    return (sendEmailVerification(currentUser));
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail: any) {
    return sendPasswordResetEmail(this.auth, passwordResetEmail);

  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('User')!);
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  // GoogleAuth() {
  //   return this.AuthLogin(new auth.GoogleAuthProvider());
  // }

  // Auth logic to run auth providers
  AuthLogin(provider: any) {
    return signInWithPopup(this.auth, provider)
      .then((result: any) => {
        this.ngZone.run(() => {
          this.router.navigate(['home']);
        })
        // this.SetUserData(result.user);
      }).catch((error: string) => {
        this.errorMessageSubject.next(error);
      })
  }

  // SignInWithPhoneNumber(phone,appVerifier){
  //   return signInWithPhoneNumber(this.auth, "+91" + phone, appVerifier)
  //   .then(result => {
  //     this.windowRef.confirmationResult = result;
  //     console.log(result);
  //     this.orolService.signInPhone(phone).subscribe((res) => {
  //     });
  //     this.spinnerService.setSpinner(false);

  //     this.userName.emit(phone);
  //     this.isVerifyOTP.emit(true);
  //     this.isLogin.emit(false);
  //   })
  //   .catch(error => {
  //     this.spinnerService.setSpinner(false);
  //     console.log("signInWithPhoneNumber " + error)
  //     this.errorMessage = error.message;
  //   });
  // }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  // SetUserData(user: { uid: any; email: any; displayName: any; photoURL: any; emailVerified: any; }) {
  //   const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
  //   const userData: User = {
  //     uid: user.uid,
  //     email: user.email,
  //     displayName: user.displayName,
  //     photoURL: user.photoURL,
  //     emailVerified: user.emailVerified
  //   }
  //   return userRef.set(userData, {
  //     merge: true
  //   })
  // }

  // Sign out
  SignOut() {
    return signOut(this.auth).then(() => {
      localStorage.removeItem('User');
      this.orolService.userDetailsSubject.next({});
    })
  }
}
