import { Component, OnInit } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Subscription } from "rxjs";

@Component({
  selector: "app-articles",
  templateUrl: "./articles.component.html",
  styleUrls: ["./articles.component.scss"]
})
export class ArticlesComponent implements OnInit {
  data: any = {};
  loading = true;
  li: any;
  lis = [];
  errors: any;
  leftArticlesCount: any;
  leftArticles: any[] =[];
  rightArticles: any[]=[];


  constructor(private http: HttpClient) { }

  ngOnInit() {

    // this.http.get('https://blogadmin.ourriverourlife.com/blogs').subscribe(result => {
    //   this.data = result;
    //   this.leftArticlesCount = Math.ceil(this.data.length / 5);
    //   this.leftArticles = this.data.slice(0, this.leftArticlesCount);
    //   this.rightArticles = this.data;
    // });
  }
  removeHTML(str: string) {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = str;
    return tmp.textContent || tmp.innerText || "";
  }
  ngOnDestroy() {
  }
}
