import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "../../shared/services/auth.service";
import { OrolService } from "../../services/orol.service";
import { SignInComponent } from "../auth/sign-in/sign-in.component";
import { MdbModalRef, MdbModalService } from "mdb-angular-ui-kit/modal";
import { MatSidenav } from "@angular/material/sidenav";
import { MatExpansionPanel } from "@angular/material/expansion";
@Component({
  selector: "app-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
})
export class ToolbarComponent implements OnInit {
  modalRefSignIn: MdbModalRef<SignInComponent> | null = null;
  @ViewChild("phone") phone: ElementRef | undefined;
  @ViewChild("sidenav") sidenav!: MatSidenav;
  @ViewChild("panel") panel!: MatExpansionPanel;

  user: any = {};
  loading = false;
  //Setting the value if user exists in local storage or is logged in
  isLoggedIn = false;
  loginForm: any;
  name: any = "";
  userName: any = "";
  password: any = "";
  phoneNumber: any = "";
  // isLogin = false;
  isRegister: boolean = false;
  isRecoverPassword: boolean = false;
  isVerifyOTP: boolean = false;
  submitted = false;
  isEyeHidden: boolean = true;
  isGetInvolved: boolean = false;
  panelOpenState = true;
  avatarURL: String = "../../assets/icons/profile.png";
  constructor(
    private formBuilder: FormBuilder,
    public router: Router,
    public route: ActivatedRoute,
    private orolService: OrolService,
    private authService: AuthService,
    private modalService: MdbModalService
  ) {
    this.orolService.userDetailsSubject.subscribe((data: any) => {
      //Check for Empty Object
      if (data && Object.keys(data).length != 0) {
        this.user = data;
        this.isLoggedIn = true;
      } else {
        this.user = {};
        this.isLoggedIn = false;
      }
    });
  }

  ngOnInit() {}

  login() {
    this.modalRefSignIn = this.modalService.open(SignInComponent);
  }

  // setIsRegister(event: boolean) {
  //   this.isRegister = event;
  // }

  // setIsRecoverPassword(event: boolean) {
  //   this.isRecoverPassword = event;
  // }
  // setIsLogin(event: boolean) {
  //   // this.isLogin = event;
  // }
  // setisVerifyOTP(event) {
  //   this.isVerifyOTP = event;
  // }
  // setUserName(event) {
  //   this.userName = event;
  // }
  // setPassword(event) {
  //   this.password = event;
  // }
  // setPhoneNumber(event) {
  //   this.phoneNumber = event;
  // }
  logout() {
    this.authService.SignOut();
    this.router.navigate(['../'], { relativeTo: this.route });
  }
  closeSidenav() {
    this.sidenav.close();
  }
  closeDropDown() {
    this.panel.close();
  }
}
