import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import {OrolService} from '../../services/orol.service';
import { FloraFaunaService } from '../../services/flora-fauna.service';
import { RiverMonitoringService } from '../../services/river-monitoring.service';
import {SpinnerService} from '../../services/spinner.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
@Component({
  selector: 'app-maps-fauna',
  templateUrl: './maps-fauna.component.html',
  styleUrls: ['./maps-fauna.component.scss']
})
export class MapsFaunaComponent implements OnChanges {

  center: any = {lat: 27.891535, lng: 78.078743};
  public zoom: number = 4;

  mapOptions: google.maps.MapOptions = {
  // initial center position for the map
    center: this.center,
    zoom: this.zoom,
    mapId: 'DEMO_MAP_ID',
  };

  public iconUrl = '../../../assets/icons/marker.svg';
  public markers:any[] =[];

  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  selectedMarker: any;

  public pageFauna = 1;
  public paginateFauna=false;
  public totalNumberOfPagesFauna=1;
  public currentPageCounterFauna=1;

  constructor(private riverMonitoringService: RiverMonitoringService, private spinnerService:SpinnerService, private floraFaunaService: FloraFaunaService) {
    this.listRivers();
    this.listFauna();
  }
  ngOnChanges(changes: SimpleChanges): void {
    this.listRivers();
    this.listFauna();
  }

  ngOnInit(){
  }

  async initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement  } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement, this.mapOptions
    );

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const infoContent = document.getElementById("iw-content") as HTMLElement
    // Add some markers to the map.
    const markers = this.markers.map((loc:any, i:number) => {
      var lat = loc.latitude? loc.latitude : loc.generalInformation?.latitude ? loc.generalInformation?.latitude:undefined;
      var lng = loc.longitude? loc.longitude : loc.generalInformation?.longitude ? loc.generalInformation?.longitude:undefined;
      var location = loc.location? loc.location : loc.generalInformation?.location ? loc.generalInformation?.location:undefined;
      var fauna = loc.fauna? loc.fauna : loc.faunaPictures ? loc.faunaPictures:undefined;

      if(lat && lng){
        map.panTo(new google.maps.LatLng(lat + 6, lng));
        const label = labels[i % labels.length];
        const pinGlyph = new google.maps.marker.PinElement({
          glyph: label,
          glyphColor: "white",
        })

        const marker = new AdvancedMarkerElement({
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          content: pinGlyph.element,
        });
        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener("click", () => {
          this.selectedMarker = loc;
          this.selectedMarker = loc;
          this.selectedMarker.location = location;
          this.selectedMarker.fauna=fauna;
            infoWindow.setContent(infoContent);
            infoWindow.open(map, marker);
        });
        return marker;
      }
      else{
        return new google.maps.marker.AdvancedMarkerElement();
      }
    });
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({markers, map})
  }

  // Flora and fauna
  addMarker(data:any) {
    for(var i=0; i<data.length;i++){
      this.markers.push(data[i]);
    }
    if(this.totalNumberOfPages == this.page){
      this.initMap();
    }
  }

  listFauna() {
    this.spinnerService.setSpinner(true);
    if(this.paginateFauna == true){
       this.pageFauna = this.pageFauna+1;
       this.currentPageCounterFauna++;
    }
    else{
      this.pageFauna = this.pageFauna;
      this.currentPageCounterFauna=1;
    }
    this.floraFaunaService.getFloraFauna(this.pageFauna, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
        this.totalNumberOfPagesFauna=Math.ceil((data['count']/this.pageSize));
        data.rows = data.rows.filter((row:any) => row.fauna.length > 0)
        this.addMarker(data.rows);
        if(this.currentPageCounterFauna <= this.totalNumberOfPagesFauna - 1){
          this.paginateFauna=true;
          this.listFauna();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }
  listRivers() {
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }
    this.riverMonitoringService.getRiverMonitoring(this.page, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
        this.addMarker(data.rows)
        if(this.currentPageCounter <= this.totalNumberOfPages - 1){
          this.paginate=true;
          this.listRivers();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }

}
// just an interface for type safety.
interface marker {
  latitude: number;
  longitude: number;
  // label?: string;
  // draggable: boolean;
  location?: string;
  fauna?: Array <string>;
  // activityTime:string;
  // activityDate: string;
  // experience : string;
}
