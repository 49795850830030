import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, AbstractControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgZone } from '@angular/core';
import { FishSanctuariesService } from '../../../services/fish-sanctuaries.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SpinnerService } from '../../../services/spinner.service';
import { CONSTANTS } from 'src/app/utils/constants/constants';
import { dataURLtoFile } from 'src/app/utils/functions/functions';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';

@Component({
  selector: 'app-fish-sanctuaries-view',
  templateUrl: './fish-sanctuaries-view.component.html',
  styleUrls: ['./fish-sanctuaries-view.component.scss']
}) 
export class FishSanctuariesViewComponent implements OnInit {
  @ViewChild('fish_sanctuaries_stepper', { static: false })
  fish_sanctuaries_stepper!: MatStepper;
  @ViewChild('search', { static: false })
  @ViewChild(GoogleMap, { static: false })map!: GoogleMap;
  public searchElementRef!: ElementRef;
    //Related to google maps
    zoom = 12;
    center!: google.maps.LatLngLiteral;
    centerLoc!: google.maps.LatLngLiteral;
    geocoder: any;
    options: google.maps.MapOptions = {
      mapTypeId: 'hybrid',
      zoomControl: false,
      scrollwheel: false,
      disableDoubleClickZoom: true,
      maxZoom: 15,
      minZoom: 8,
    };
    markers = [] as any[];
  public iconUrl = '../../../assets/icons/marker.svg';
  note = ".jpg, .jpeg, .png, files accepted";
  info = "(Max. size 100KB)";
  decimalPattern = /^\d+(\.\d)?\d*$/;
   imageFilesSanctuary: File[] = [];
   imageFilesSpecies: File[] = [];
  physicalInfrastructureOther = '';
  manMadePhysicalFeaturesOtherLeft = '';
  manMadePhysicalFeaturesOtherRight = '';
  naturalPhysicalFeaturesOtherLeft = '';
  naturalPhysicalFeaturesOtherRight = '';
  submitStep1: boolean = false;
  submitStep2: boolean = false;
  submitStep3: boolean = false;
  submitStep4: boolean = false;
  pageNumber = 1;
  id: string = '';
  fishSanctuaries_details:any =[];
  fishSanctuaryForm: FormGroup | undefined;
  latitude!: number;
  longitude!: number;
  getAddress: any;
  user: any;
  isAddMode = true;

  public PHYSICAL_INFRASTRUCTURE = CONSTANTS.PHYSICAL_INFRASTRUCTURE;
  public NATURAL_VEGETATION_LEFT = CONSTANTS.NATURAL_VEGETATION;
  public NATURAL_VEGETATION_RIGHT = CONSTANTS.NATURAL_VEGETATION;
  public NATURAL_PHYSICAL_FEATURES_LEFT = CONSTANTS.NATURAL_PHYSICAL_FEATURES;
  public NATURAL_PHYSICAL_FEATURES_RIGHT = CONSTANTS.NATURAL_PHYSICAL_FEATURES;
  public MANMADE_PHYSICAL_FEATURES_LEFT = CONSTANTS.MANMADE_PHYSICAL_FEATURES;
  public MANMADE_PHYSICAL_FEATURES_RIGHT = CONSTANTS.MANMADE_PHYSICAL_FEATURES;
  public isPopulated: boolean = false;
  errorMessage: any;
  submitStepFinal!: boolean;
  isDisabledStep1: boolean = true;

  constructor(private imageCompress: NgxImageCompressService, public location: Location, private fb: FormBuilder, private fishSanctuariesService: FishSanctuariesService, private router: Router, 
    private ngZone: NgZone, private spinnerService: SpinnerService, private route: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('User')!);
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.createForm();
  }

  ngOnInit(): void {
      this.getFishSanctuariesDetails();
  }
  
  onStepChange(event: any) {
    console.log(event);
  }
  populateMap() {
    if (this.user) {
      this.setCurrentTime();
      // this.mapsAPILoader.load().then(() => {
      //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
      //     types: ["address"]
      //   });

      //   autocomplete.addListener("place_changed", () => {
      //     this.ngZone.run(() => {
      //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
      //       //verify result
      //       if (place.geometry === undefined || place.geometry === null) {
      //         return;
      //       }

      //       //set latitude, longitude and zoom
      //       this.fishSanctuaryForm.get('locationDetails').patchValue({
      //         latitude: place.geometry.location.lat(),
      //         longitude: place.geometry.location.lng(),
      //         location: place.formatted_address,
      //       });
      //       this.centerLoc = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      //     });
      //   });
      // });
    }
  }
    handleAddressChange(address: Address) {
    let location = address.formatted_address;
    let lat = address.geometry.location.lat();
    let lng = address.geometry.location.lng();
    this.fishSanctuaryForm!.get('locationDetails')!.patchValue({
      latitude: lat,
      longitude: lng,
      location:location,
    });
    this.addMarker(lat,lng);
    this.zoom = 12;
    // map.panTo(curmarker.position);
  } 
  patchLatLngFormValue(lat:any, lng:any){
    this.fishSanctuaryForm!.get('locationDetails')!.patchValue({
      latitude: lat,
      longitude: lng,
    });
}
  addMarker(lat: any, lng: any) {
    this.markers=[];
    let position= {
      lat: lat,
      lng: lng
    };
    this.markers.push({
      position: {
        lat: lat,
        lng: lng,
      },
      label: {
        className: 'marker-label',
        text: 'You are here',
      },
      title: 'Marker title ',
      options: { animation: google.maps.Animation.BOUNCE, icon: {url: this.iconUrl}, },
    });
    this.patchLatLngFormValue(lat, lng)
    this.map.panTo(position)
    this.spinnerService.setSpinner(false);
  }
  

  getPhysicalInfrastructure() {
    this.fishSanctuaries_details.habitatCharacteristics.physicalInfrastructure.forEach((element: any) => {
      const index = this.PHYSICAL_INFRASTRUCTURE.map(e => e.name).indexOf(element.name);
      this.PHYSICAL_INFRASTRUCTURE[index].selected = true;
      this.PHYSICAL_INFRASTRUCTURE[index].description = element.description;
      //Update Form
      this.addPhysicalInfrastructure(element);
    });
  }

  getNaturalVegetationLeftBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.leftBankHabitat.naturalVegetation.forEach((element: any) => {
      const index = this.NATURAL_VEGETATION_LEFT.map(e => e.name).indexOf(element.name);
      this.NATURAL_VEGETATION_LEFT[index].selected = true;

      //Update Form
      this.addNaturalVegetation('left', element);
    });
  }
  getNaturalVegetationRightBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.rightBankHabitat.naturalVegetation.forEach((element: any) => {
      const index = this.NATURAL_VEGETATION_RIGHT.map(e => e.name).indexOf(element.name);
      this.NATURAL_VEGETATION_RIGHT[index].selected = true;
      //Update Form
      this.addNaturalVegetation('right', element);
    });
  }

  getNaturalPhysicalFeaturesLeftBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.leftBankHabitat.naturalPhysicalFeatures.forEach((element: any) => {
      if (element.id != 'undefined') {
        const index = this.NATURAL_PHYSICAL_FEATURES_LEFT.map(e => e.name).indexOf(element.name);
        this.NATURAL_PHYSICAL_FEATURES_LEFT[index].selected = true;
        this.NATURAL_PHYSICAL_FEATURES_LEFT[index].description = element.description;
        //Update Form
        this.addNaturalPhysicalFeatures('left', element)
      }
    });
  }

  getNaturalPhysicalFeaturesRightBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.rightBankHabitat.naturalPhysicalFeatures.forEach((element: any) => {
      if (element.id != 'undefined') {
        const index = this.NATURAL_PHYSICAL_FEATURES_RIGHT.map(e => e.name).indexOf(element.name);
        this.NATURAL_PHYSICAL_FEATURES_RIGHT[index].selected = true;
        this.NATURAL_PHYSICAL_FEATURES_RIGHT[index].description = element.description;
        //Update Form
        this.addNaturalPhysicalFeatures('right', element)
      }
    });
  }
  getManmadePhysicalFeaturesLeftBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.leftBankHabitat.manMadePhysicalFeatures.forEach((element: any) => {
      if (element.id != 'undefined') {
        const index = this.MANMADE_PHYSICAL_FEATURES_LEFT.map(e => e.name).indexOf(element.name);
        this.MANMADE_PHYSICAL_FEATURES_LEFT[index].selected = true;
        this.MANMADE_PHYSICAL_FEATURES_LEFT[index].description = element.description;
        //Update Form
        this.addManMadePhysicalFeatures('left', element);
      }
    });
  }
  getManmadePhysicalFeaturesRightBankHabitat() {
    this.fishSanctuaries_details.habitatCharacteristics.rightBankHabitat.manMadePhysicalFeatures.forEach((element: any) => {
      if (element.id != 'undefined') {
        const index = this.MANMADE_PHYSICAL_FEATURES_RIGHT.map(e => e.name).indexOf(element.name);
        this.MANMADE_PHYSICAL_FEATURES_RIGHT[index].selected = true;
        this.MANMADE_PHYSICAL_FEATURES_RIGHT[index].description = element.description;
        //Update Form
        this.addManMadePhysicalFeatures('right', element)
      }
    });
  }

  patchArrayValues() {
    this.getPhysicalInfrastructure();
    this.getNaturalVegetationLeftBankHabitat();
    this.getNaturalVegetationRightBankHabitat();
    this.getNaturalPhysicalFeaturesLeftBankHabitat();
    this.getNaturalPhysicalFeaturesRightBankHabitat();
    this.getManmadePhysicalFeaturesLeftBankHabitat();
    this.getManmadePhysicalFeaturesRightBankHabitat();
    this.getSanctuaryPictures();
    this.getSpeciesPictures();
    // this.isPopulated = true;
  }
  getFishSanctuariesDetails() {
    this.fishSanctuariesService.getFishSanctuariesDetails(this.route.snapshot.paramMap.get("id")).subscribe(
      (res:any) => {
        this.fishSanctuaries_details = res;
        var activityDate = res['locationDetails'].activityDate.split(' ');
        res['locationDetails'].activityDate = activityDate[2] +"-"+activityDate[1] +"-"+activityDate[3]
        if(res.speciesPictures.length > 0) {
          this.fishSanctuaryForm?.patchValue({
            recognizeFish:0
          })
        }

        this.fishSanctuaryForm!.patchValue(res);
        this.patchArrayValues();
      },
      (err) => {
        console.log(err.error);
        console.log(err.status);
      },
      () => {

      })
  }
  createForm() {
    if (!this.isAddMode) { 
      this.fishSanctuaryForm = this.fb.group({
        id: [''],
        userId: [''],
        // contributorName: [''],
        locationDetails: this.fb.group({
          name: ['', [Validators.required, Validators.maxLength(100)]],
          riverName: ['', [Validators.required, Validators.maxLength(100)]],
          activityDate: [(new Date()), [Validators.required]],
          location: ['', [Validators.required, Validators.maxLength(200)]],
          latitude: ['', [Validators.pattern(this.decimalPattern)]],
          longitude: ['', [Validators.pattern(this.decimalPattern)]],
          extent: ['', [Validators.required, Validators.maxLength(100)]],
          sanctuaryPictures: this.fb.array([]),
        }),
        habitatCharacteristics: this.fb.group({
          physicalInfrastructure: this.fb.array([]),
          leftBankHabitat: this.fb.group({
            naturalVegetation: this.fb.array([]),
            naturalPhysicalFeatures: this.fb.array([]),
            manMadePhysicalFeatures: this.fb.array([]),
            bankErosion: [false, [Validators.required]],
          }),
          rightBankHabitat: this.fb.group({
            naturalVegetation: this.fb.array([]),
            naturalPhysicalFeatures: this.fb.array([]),
            manMadePhysicalFeatures: this.fb.array([]),
            bankErosion: [false, [Validators.required]],
          }),
        }),
        managementActions: this.fb.group({
          foodProvisioning: this.fb.group({
            type: ['', [Validators.required, Validators.maxLength(100)]],
            description: ['', [Validators.required, Validators.maxLength(100)]],
          }),
          fishingAnglingAllowed: this.fb.group({
            type: ['', [Validators.required, Validators.maxLength(100)]],
          }),
          swimmingAllowed: this.fb.group({
            type: ['', [Validators.required, Validators.maxLength(100)]],
          }),
          patrollingAllowed: this.fb.group({
            type: ['', [Validators.required, Validators.maxLength(100)]],
            description: ['', [Validators.required, Validators.maxLength(100)]],
          }),
        }),
        speciesPictures: this.fb.array([]),
        culturalHistoricalSignificance: ['', [Validators.maxLength(500)]],
        recognizeFish: ['1']
      });
    }
    else {
      this.fishSanctuaryForm = this.fb.group({
        id: [''],
        userId: [''],
        contributorName: [''],
        locationDetails: this.fb.group({
          name: ['', [Validators.required, Validators.maxLength(100)]],
          riverName: ['', [Validators.required, Validators.maxLength(100)]],
          activityDate: [(new Date()), [Validators.required]],
          location: ['', [Validators.required, Validators.maxLength(200)]],
          latitude: ['', [Validators.pattern(this.decimalPattern)]],
          longitude: ['', [Validators.pattern(this.decimalPattern)]],
          extent: ['', [Validators.required, Validators.maxLength(100)]],
          sanctuaryPictures: this.fb.array([]),
        }),
        habitatCharacteristics: this.fb.group({
          physicalInfrastructure: this.fb.array([]),
          leftBankHabitat: this.fb.group({
            naturalVegetation: this.fb.array([]),
            naturalPhysicalFeatures: this.fb.array([]),
            manMadePhysicalFeatures: this.fb.array([]),
            bankErosion: [false, [Validators.required]],
          }),
          rightBankHabitat: this.fb.group({
            naturalVegetation: this.fb.array([]),
            naturalPhysicalFeatures: this.fb.array([]),
            manMadePhysicalFeatures: this.fb.array([]),
            bankErosion: [false, [Validators.required]],
          }),
        }),
        managementActions: this.fb.group({
          foodProvisioning: this.fb.group({
            type: ['1', [Validators.required, Validators.maxLength(100)]],
            description: ['', [Validators.required, Validators.maxLength(100)]],
          }),
          fishingAnglingAllowed: this.fb.group({
            type: ['1', [Validators.required, Validators.maxLength(100)]],
          }),
          swimmingAllowed: this.fb.group({
            type: ['1', [Validators.required, Validators.maxLength(100)]],
          }),
          patrollingAllowed: this.fb.group({
            type: ['1', [Validators.required, Validators.maxLength(100)]],
            description: ['', [Validators.required, Validators.maxLength(100)]],
          }),
        }),
        speciesPictures: this.fb.array([]),
        culturalHistoricalSignificance: ['', [Validators.maxLength(500)]],
        recognizeFish: ['1']
      });
    }
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }

  // placeMarker($event: { coords: { lat: any; lng: any; }; }) {
  //   this.fishSanctuaryForm!.get('locationDetails')!.patchValue({
  //     latitude: $event.coords.lat,
  //     longitude: $event.coords.lng,
  //   });
  //   this.getAddressByLatitudeAndLongitude($event.coords.lat, $event.coords.lng, this.fishSanctuaryForm!.get('locationDetails'));
  // }

  setCurrentTime() {
    let dt = new Date();
    let normalizeHour = dt.getHours() >= 13 ? dt.getHours() - 12 : dt.getHours();
    var hrs = normalizeHour.toString();
    let normalizeMins = dt.getMinutes();
    var mins = normalizeMins.toString();
    if (normalizeHour < 10) {
      hrs = "0" + normalizeHour;
    }

    if (normalizeMins < 10) {
      mins = "0" + mins;
    }

    var formattedTime = dt.getHours() >= 13 ? hrs + ':' + mins +  ' ' + 'PM' : hrs + ':' + mins +  ' ' +  'AM';
    this.fishSanctuaryForm!.get('locationDetails')!.patchValue({
      activityTime: formattedTime
    });
  }

  bla() {
    this.getAddressByLatitudeAndLongitude(this.fishSanctuaryForm!.get('locationDetails')!.get('latitude')!.value,
      this.fishSanctuaryForm!.get('locationDetails')!.get('longitude')!.value, this.fishSanctuaryForm!.get('locationDetails')!.value);
    this.centerLoc = {
      lat: this.fishSanctuaryForm!.get('locationDetails')!.get('latitude')!.value,
      lng: this.fishSanctuaryForm!.get('locationDetails')!.get('longitude')!.value,
    };
    this.addMarker(this.fishSanctuaryForm!.get('locationDetails')!.get('latitude')!.value, this.fishSanctuaryForm!.get('locationDetails')!.get('longitude')!.value)
  }

  //Man made physical Features
  manMadePhysicalFeatures(mode: string): FormArray {
    if (mode == 'left') {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get('leftBankHabitat')!.get('manMadePhysicalFeatures') as FormArray
    }
    else {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get('rightBankHabitat')!.get('manMadePhysicalFeatures') as FormArray
    }
  }
  newManmadePhysicalFeatures(item: { id: any; name: any; description: any; }): FormGroup {
    return this.fb.group({
      id: item.id,
      name: item.name,
      description: item.description
    })
  }
  addManMadePhysicalFeatures(mode: string, item: any) {
    this.manMadePhysicalFeatures(mode).push(this.newManmadePhysicalFeatures(item));
  }
  removeManMadePhysicalFeatures(index: number, mode: string) {
    this.manMadePhysicalFeatures(mode).removeAt(index);
  }
  setManMadePhysicalFeaturesLeftOther(event: { target: { value: string; }; }, mode: any, obj: { id: any; name: any; }) {
    let localOtherIndex = this.MANMADE_PHYSICAL_FEATURES_LEFT.findIndex(record => record.name === 'Other');
    let otherIndex = this.manMadePhysicalFeatures(mode).value.findIndex((record: { name: string; }) => record.name === 'Other');

    //Update local Array
    this.MANMADE_PHYSICAL_FEATURES_LEFT[localOtherIndex].description = event.target.value;

    //Update Form Array
    this.manMadePhysicalFeatures(mode).at(otherIndex).patchValue({
      'id': obj.id,
      'name': obj.name,
      'description': event.target.value
    });
  }
  setManMadePhysicalFeaturesRightOther(event: { target: { value: string; }; }, mode: any, obj: { id: any; name: any; }) {
    let localOtherIndex = this.MANMADE_PHYSICAL_FEATURES_RIGHT.findIndex(record => record.name === 'Other');
    let otherIndex = this.manMadePhysicalFeatures(mode).value.findIndex((record: { name: string; }) => record.name === 'Other');

    //Update local Array
    this.MANMADE_PHYSICAL_FEATURES_RIGHT[localOtherIndex].description = event.target.value;

    //Update Form Array
    this.manMadePhysicalFeatures(mode).at(otherIndex).patchValue({
      'id': obj.id,
      'name': obj.name,
      'description': event.target.value
    });
  }

  addManmadePhysicalFeaturesOther(mode: string) {
    if (mode == 'left') {
      this.addManMadePhysicalFeatures(this.manMadePhysicalFeaturesOtherLeft, mode);
    }
    else {
      this.addManMadePhysicalFeatures(this.manMadePhysicalFeaturesOtherRight, mode);
    }
  }

  //Natural Physical features
  naturalPhysicalFeatures(mode: string): FormArray {
    if (mode == "left") {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get("leftBankHabitat")!.get('naturalPhysicalFeatures') as FormArray
    }
    else {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get("rightBankHabitat")!.get('naturalPhysicalFeatures') as FormArray
    }
  }

  newNaturalPhysicalFeatures(item: { id: any; name: any; description: any; }): FormGroup {
    return this.fb.group({
      id: item.id,
      name: item.name,
      description: item.description
    })
  }

  addNaturalPhysicalFeatures(mode: string, item: any) {
    this.naturalPhysicalFeatures(mode).push(this.newNaturalPhysicalFeatures(item));
  }

  removeNaturalPhysicalFeatures(index: number, mode: string) {
    this.naturalPhysicalFeatures(mode).removeAt(index);
  }
  setNaturalPhysicalFeaturesLeftOther(event: { target: { value: string; }; }, mode: any, obj: { id: any; name: any; }) {
    let localOtherIndex = this.NATURAL_PHYSICAL_FEATURES_LEFT.findIndex(record => record.name === 'Other');
    let otherIndex = this.naturalPhysicalFeatures(mode).value.findIndex((record: { name: string; }) => record.name === 'Other');

    //Update local Array
    this.NATURAL_PHYSICAL_FEATURES_LEFT[localOtherIndex].description = event.target.value;

    //Update Form Array
    this.naturalPhysicalFeatures(mode).at(otherIndex).patchValue({
      'id': obj.id,
      'name': obj.name,
      'description': event.target.value
    });
  }
  setNaturalPhysicalFeaturesRightOther(event: { target: { value: string; }; }, mode: any, obj: { id: any; name: any; }) {
    let localOtherIndex = this.NATURAL_PHYSICAL_FEATURES_RIGHT.findIndex(record => record.name === 'Other');
    let otherIndex = this.naturalPhysicalFeatures(mode).value.findIndex((record: { name: string; }) => record.name === 'Other');

    //Update local Array
    this.NATURAL_PHYSICAL_FEATURES_RIGHT[localOtherIndex].description = event.target.value;

    //Update Form Array
    this.naturalPhysicalFeatures(mode).at(otherIndex).patchValue({
      'id': obj.id,
      'name': obj.name,
      'description': event.target.value
    });
  }

  addNaturalPhysicalFeaturesOther(mode: string) {
    if (mode == 'left') {
      this.addNaturalPhysicalFeatures(this.naturalPhysicalFeaturesOtherLeft, mode);
    }
    else {
      this.addNaturalPhysicalFeatures(this.naturalPhysicalFeaturesOtherRight, mode);
    }
  }

  //Natural Vegetation
  naturalVegetation(mode: string): FormArray {
    if (mode == "left") {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get("leftBankHabitat")!.get('naturalVegetation') as FormArray
    }
    else {
      return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get("rightBankHabitat")!.get('naturalVegetation') as FormArray
    }
  }
  newNaturalVegetation(item: { id: any; name: any; description: any; }): FormGroup {
    return this.fb.group({
      id: item.id,
      name: item.name,
      description: item.description
    })
  }

  addNaturalVegetation(mode: string, item: any) {
    this.naturalVegetation(mode).push(this.newNaturalVegetation(item));
  }

  removeNaturalVegetation(index: number, mode: string) {
    this.naturalVegetation(mode).removeAt(index);
  }

  //Physical Infrastruucture array
  physicalInfrastructure(): FormArray {
    return this.fishSanctuaryForm!.get("habitatCharacteristics")!.get("physicalInfrastructure") as FormArray
  }

  newPhysicalInfrstructure(item: { id: any; name: any; description: any; }): FormGroup {
    return this.fb.group({
      id: item.id,
      name: item.name,
      description: item.description
    })
  }

  addPhysicalInfrastructure(item: any) {
    this.physicalInfrastructure().push(this.newPhysicalInfrstructure(item));
  }

  removeSkill(i: number) {
    this.physicalInfrastructure().removeAt(i);
  }

  removePhysicalInfrastructure(index: number) {
    this.physicalInfrastructure().removeAt(index);
  }
  setPhysicalInfrastructureOther(event: { target: { value: string; }; }, obj: { id: any; name: any; }) {
    let localOtherIndex = this.PHYSICAL_INFRASTRUCTURE.findIndex(record => record.name === 'Other');
    let otherIndex = this.physicalInfrastructure().value.findIndex((record: { name: string; }) => record.name === 'Other');

    //Update local Array
    this.PHYSICAL_INFRASTRUCTURE[localOtherIndex].description = event.target.value;

    //Update Form Array
    this.physicalInfrastructure().at(otherIndex).patchValue({
      'id': obj.id,
      'name': obj.name,
      'description': event.target.value
    });
  }

  //Species Pictures Form Array
  speciesPictures(i?: number): FormArray {
    return this.fishSanctuaryForm!.get("speciesPictures") as FormArray
  }
  getSpeciesPictures() {
    let index = -1;
    this.fishSanctuaries_details.speciesPictures.forEach((element: any) => {
      this.addSpeciesPictures(element);
    });
  }
  newSpeciesPictures(): FormGroup {
    return this.fb.group({
      imageURL: '',
      commonName: '',
      localName: '',
      scientificName: ''
    })
  }
  newSpeciesPictures1(item: { imageURL: any; commonName: any; localName: any; scientificName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      commonName: item.commonName,
      localName: item.localName,
      scientificName: item.scientificName
    })
  }
  addSpeciesPictures(item: any) {
    this.speciesPictures().push(this.newSpeciesPictures1(item));
  }
  removeSpeciesPictures(index: number) {
    this.imageFilesSpecies.splice(index, 1);
    this.speciesPictures().removeAt(index);
  }
  removeAllSpeciesPictures() {
    this.imageFilesSpecies = [];
    this.speciesPictures().clear();
  }

  //Sanctuary Pictures Form Array
  sanctuaryPictures(i?: number): FormArray {
    return this.fishSanctuaryForm!.get("locationDetails")!.get("sanctuaryPictures") as FormArray
  }

  getSanctuaryPictures() {
    let index = -1;
    this.fishSanctuaries_details.locationDetails.sanctuaryPictures.forEach((element: any, index: any) => {
      this.addSanctuaryPictures(element)
    });
  }

  newSanctuaryPictures(): FormGroup {
    return this.fb.group({
      imageURL: '',
      description: ''
    })
  }
  newSanctuaryPictures1(item: { imageURL: any; description: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description
    })
  }

  addSanctuaryPictures(item: any) {
    this.sanctuaryPictures().push(this.newSanctuaryPictures1(item));
  }
  removeSanctuaryPictures(index: number) {
    this.imageFilesSanctuary.splice(index, 1);
    this.sanctuaryPictures().removeAt(index);
  }

  removeAllSanctuaryPictures() {
    this.imageFilesSanctuary = [];
    this.sanctuaryPictures().clear();
  }

  goBack(fish_sanctuaries_stepper: MatStepper){
    fish_sanctuaries_stepper.previous();
  }

  goForward(){
    this.fish_sanctuaries_stepper.next();
  } 

  navigate(mode: string) {
    if (mode == 'home') {
      this.router.navigate(['./home']);
    }
    if (mode == "list") {
      this.router.navigate(['./fish-sanctuaries']);
    }
  }

  clearErrorMessage() {
    setTimeout(() => {
      this.errorMessage = "";
    }, 5000);
  }

  defaultError(err: any) {
    this.gotoTop();
    this.spinnerService.setSpinner(false);
    this.errorMessage = err.error;
    this.clearErrorMessage();
  }

  createFishSanctuary(navigateMode:any) {
    this.spinnerService.setSpinner(true);
    this.fishSanctuariesService.createFishSanctuary(this.fishSanctuaryForm!.value, this.imageFilesSanctuary, this.imageFilesSpecies).
      subscribe(
        (data:any) => {
          this.spinnerService.setSpinner(false);
          this.fishSanctuaryForm!.patchValue(data);
          this.id = data['id'];
          this.goForward();
          // this.fish_sanctuaries_stepper.next();
        },
        (err) => {
          this.defaultError(err);
        },
        () => {
          if (navigateMode != "") {
            this.navigate(navigateMode);
          }
        }
      );
  }

  updateFishSanctuary(navigateMode:any) {
    this.spinnerService.setSpinner(true);
    this.fishSanctuariesService.updateFishSanctuary(this.id, this.fishSanctuaryForm!.value, this.imageFilesSanctuary, this.imageFilesSpecies).
      subscribe(
        (data:any) => {
          this.spinnerService.setSpinner(false);
          this.fishSanctuaryForm!.patchValue(data);
          //Update Id to the object or go to edit mode
          this.fishSanctuaryForm!.patchValue(data);
          // this.fish_sanctuaries_stepper.next();
        },
        (err:Error) => {
          this.defaultError(err);
        },
        () => {
          if (navigateMode != "") {
            this.navigate(navigateMode);
          }
        }
      );
  }

  onScroll() {
    this.pageNumber = this.pageNumber + 1;
  }

  compressFile(base64URL: any, filename: string, mode: string) {
    var orientation = -1;
    this.imageCompress.compressFile(base64URL, orientation, 50, 50).then(
      result => {
        if (mode == "sanctuary") {
          var item = {
            imageURL: result,
            description: ''
          }
          this.imageFilesSanctuary.push(dataURLtoFile(result, filename));
          this.addSanctuaryPictures(item)
        }
        else if (mode == "species") {
          var item1 = {
            imageURL: result,
            commonName: '',
            localName: '',
            scientificName: ''
          }
          this.imageFilesSpecies.push(dataURLtoFile(result, filename));
          this.addSpeciesPictures(item1)
        }
      });
  }

  uploadSpeciesPhotos(event: { target: { files: string | any[]; }; }) {
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "species_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "species");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  uploadSanctuaryPictures(event: { target: { files: string | any[]; }; }) {
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "sanctuary_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "sanctuary");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 500);
      }
    }
  }

  private setCurrentPosition() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var accuracy = position.coords.accuracy;
        this.fishSanctuaryForm!.get('locationDetails')!.patchValue({
          latitude: +position.coords.latitude,
          longitude: +position.coords.longitude,
        });
        this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
        this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.fishSanctuaryForm!.get('locationDetails'));
        this.addMarker(position.coords.latitude, position.coords.longitude);
      });
    }
    else {
    }
  }

  async getAddressByLatitudeAndLongitude(lat: number | google.maps.LatLng | google.maps.LatLngLiteral, lng: number | boolean | null | undefined, form: AbstractControl<any, any> | null) {
    var address;
    this.geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    await this.geocoder.geocode({ latLng: latlng }, function (results: { formatted_address: any; }[], status: string) {
      if (status == google.maps.GeocoderStatus.OK) {
        var arrAddress = results;
        address = results[0].formatted_address;
        form!.patchValue({
          location: address
        });
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  changePhysicalInfrastructure(obj:any, index:number) {
    this.PHYSICAL_INFRASTRUCTURE[obj.id].selected = !obj.selected;
    if (this.PHYSICAL_INFRASTRUCTURE[obj.id].selected == true) {
      this.physicalInfrastructure().push(this.fb.control({ 'id': obj.id, 'name': obj.name, 'description': obj.description }));
    }
    else {
      let index = this.physicalInfrastructure().value.findIndex((record:any) => record === (obj.name));
      this.removePhysicalInfrastructure(index);
    }
  }

  changeNaturalVegetation(obj:any, mode:string) {
    obj.selected = !obj.selected;
    if (obj.selected == true) {
      this.addNaturalVegetation(mode, obj);
    }
    else {
      let index = this.naturalVegetation(mode).value.findIndex((record: { name: any; }) => record.name === (obj.name));
      this.removeNaturalVegetation(index, mode);
    }
  }

  changeNaturalPhysicalFeatures(obj:any, mode:string) {
    obj.selected = !obj.selected;
    if (obj.selected == true) {
      this.addNaturalPhysicalFeatures(mode, obj);
    }
    else {
      let index = this.naturalPhysicalFeatures(mode).value.findIndex((record: { name: any; }) => record.name === (obj.name));
      this.removeNaturalPhysicalFeatures(index, mode);
    }
  }

  changeManMadePhysicalFeatures(obj:any, mode:string) {
    obj.selected = !obj.selected;
    if (obj.selected == true) {
      this.addManMadePhysicalFeatures(mode, obj);
    }
    else {
      let index = this.manMadePhysicalFeatures(mode).value.findIndex((record: { name: any; }) => record.name === (obj.name));
      this.removeManMadePhysicalFeatures(index, mode);
    }
  }
}
