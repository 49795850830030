import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Route, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
declare var Orol: any;
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FloraFaunaService {
  baseURL:String = "https://api.ourriverourlife.com/";

  constructor(private router: Router, public httpClient: HttpClient, private spinnerService: SpinnerService) { }

  public addFlora(x: { userId: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; commonName: string | Blob; localName: string | Blob; scientificName: string | Blob; }, images: File) {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    // for (var i = 0; i < images.length; i++) {
    form.append('flora', images);
    // }

    form.append("userId", x.userId);
    form.append("location", x.location);
    form.append("latitude", x.latitude);
    form.append("longitude", x.longitude);
    form.append("commonName", x.commonName);
    form.append("localName", x.localName);
    form.append("scientificName", x.scientificName);

    this.httpClient.post(this.baseURL + "flora-fauna/create-flora", form, { headers: httpHeaders }).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./flora-fauna']);

      },
      (err) => {
        alert(err)
        this.spinnerService.setSpinner(false);
      },
    );
  }

  public updateFlora(id:any, x: { userId: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; commonName: string | Blob; localName: string | Blob; scientificName: string | Blob; }, images: File) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    // for (var i = 0; i < images.length; i++) {
    form.append('flora', images);
    // }

    form.append("userId", x.userId);
    form.append("location", x.location);
    form.append("latitude", x.latitude);
    form.append("longitude", x.longitude);
    form.append("commonName", x.commonName);
    form.append("localName", x.localName);
    form.append("scientificName", x.scientificName);

    this.httpClient.put(this.baseURL + "flora-fauna/update-flora/"+id, form, { headers: httpHeaders }).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./flora-fauna']);

      },
      (err) => {
        this.spinnerService.setSpinner(false);
      },
    );
  }

  public addFauna( x: { userId: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; commonName: string | Blob; localName: string | Blob; scientificName: string | Blob; }, images: File) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    // for (var i = 0; i < images.length; i++) {
    form.append('fauna', images);
    // }

    form.append("userId", x.userId);
    form.append("location", x.location);
    form.append("latitude", x.latitude);
    form.append("longitude", x.longitude);
    form.append("commonName", x.commonName);
    form.append("localName", x.localName);
    form.append("scientificName", x.scientificName);

    this.httpClient.post(this.baseURL + "flora-fauna/create-fauna", form, { headers: httpHeaders }).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./flora-fauna']);
      },
      (err) => {
        this.spinnerService.setSpinner(false);
      },
    );
  }

  public updateFauna(id:any, x: { userId: string | Blob; location: string | Blob; latitude: string | Blob; longitude: string | Blob; commonName: string | Blob; localName: string | Blob; scientificName: string | Blob; }, images: File) {
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    const form = new FormData;
    // for (var i = 0; i < images.length; i++) {
    form.append('fauna', images);
    // }

    form.append("userId", x.userId);
    form.append("location", x.location);
    form.append("latitude", x.latitude);
    form.append("longitude", x.longitude);
    form.append("commonName", x.commonName);
    form.append("localName", x.localName);
    form.append("scientificName", x.scientificName);

    this.httpClient.put(this.baseURL + "flora-fauna/update-fauna/"+id, form, { headers: httpHeaders }).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        this.router.navigate(['./flora-fauna']);
      },
      (err) => {
        this.spinnerService.setSpinner(false);
      },
    );
  }

  public getFloraFauna(page: string | number, limit: string | number) {
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    // this.spinnerService.setSpinner(true);
    // const httpHeaders: HttpHeaders = new HttpHeaders({
    //   Authorization: 'Bearer ' + user.accessToken
    // });
    return this.httpClient.get(this.baseURL + "flora-fauna?page=" + page + "&limit=" + limit);
  }

  public getFloraFaunaById(id: string) {
    this.spinnerService.setSpinner(true);
    return this.httpClient.get(this.baseURL + "flora-fauna/" + id);
  }

  public deleteFloraFauna(id: string){
    var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.delete(this.baseURL + "flora-fauna/"+id, { headers: httpHeaders });
  }
}
