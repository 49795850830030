import { Component, OnInit } from '@angular/core';
import {OrolService} from '../../services/orol.service';
import {SpinnerService} from '../../services/spinner.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

@Component({
  selector: 'app-maps-river-monitoring',
  templateUrl: './maps-river-monitoring.component.html',
  styleUrls: ['./maps-river-monitoring.component.scss']
})
export class MapsRiverMonitoringComponent implements OnInit {
  center: any = {lat: 27.891535, lng: 78.078743};
  public zoom: number = 4;

  mapOptions: google.maps.MapOptions = {
  // initial center position for the map
    center: this.center,
    zoom: this.zoom,
    mapId: 'DEMO_MAP_ID',
  };

  public iconUrl = '../../../assets/icons/marker.svg';
  public markers:any[] =[];
  public markers2:any[] =[];
  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  selectedMarker: any;

  constructor(private orolService: OrolService, private spinnerService:SpinnerService) {
  }

  ngOnInit(){
    this.getWaterTestDetails();
  }

  async initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;
  
    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement, this.mapOptions
    );

    const infoContent = document.getElementById("iw-content") as HTMLElement
  
    // const infoWindow = new google.maps.InfoWindow({
    //   content: infoContent,
    //   disableAutoPan: false,
    // });
    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  
    // Add some markers to the map.
    const markers = this.markers.map((loc:any, i:number) => {
      var lat = loc['generalInformation'].latitude;
      var lng = loc['generalInformation'].longitude;

      if(lat && lng){
        map.panTo(new google.maps.LatLng(lat + 6, lng));
        const label = labels[i % labels.length];
        const pinGlyph = new google.maps.marker.PinElement({
          glyph: label,
          glyphColor: "white",
      })

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: {
          lat: parseFloat(lat),
          lng: parseFloat(lng),
        },
        content: pinGlyph.element,
      });
      // markers can only be keyboard focusable when they have click listeners
      // open info window when marker is clicked
      // marker.addListener("click", () => {
      //   this.selectedMarker= loc;
      //     infoWindow.setContent(infoContent);
      //     infoWindow.open(map, marker);
      // });
     
      return marker;
    }
    else{
      return new google.maps.marker.AdvancedMarkerElement();
    }
    });
  
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({ markers, map });
  }

  addMarker(data:any) {
    for(var i=0; i<data.length;i++){
      this.markers.push(data[i]);
    }
    if(this.totalNumberOfPages == this.page){
      this.initMap();
    }
  }

  getWaterTestDetails() {
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }

    this.orolService.getWaterTestDetail(this.page, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
        this.addMarker(data['rows'])
        if(this.currentPageCounter <= this.totalNumberOfPages - 1){
          this.paginate=true;
          this.getWaterTestDetails();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }
}
