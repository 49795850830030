// import { Component, OnChanges, OnInit } from '@angular/core';
// import {OrolService} from '../../services/orol.service';
// import {SpinnerService} from '../../services/spinner.service';
// import { MarkerClusterer } from "@googlemaps/markerclusterer";

import { Component, OnChanges, SimpleChanges, Input } from '@angular/core';
import {OrolService} from '../../services/orol.service';
import { FloraFaunaService } from '../../services/flora-fauna.service';
import { RiverMonitoringService } from '../../services/river-monitoring.service';
import {SpinnerService} from '../../services/spinner.service';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

@Component({
  selector: 'app-maps-flora',
  templateUrl: './maps-flora.component.html',
  styleUrls: ['./maps-flora.component.scss']
})
export class MapsFloraComponent implements OnChanges {
  @Input()startDate!: string;
  @Input()endDate!: string;

  center: any = {lat: 27.891535, lng: 78.078743};
  public zoom: number = 4;

  mapOptions: google.maps.MapOptions = {
  // initial center position for the map
    center: this.center,
    zoom: this.zoom,
    mapId: 'DEMO_MAP_ID',
  };

  public iconUrl = '../../../assets/icons/marker.svg';
  public markers:any[] =[];

  public page = 1;
  public pageSize=30;
  public paginate=false;
  public totalNumberOfPages=1;
  public currentPageCounter=1;
  selectedMarker: any;

  public pageFlora = 1;
  public paginateFlora=false;
  public totalNumberOfPagesFlora=1;
  public currentPageCounterFlora=1;

  constructor(private orolService: OrolService, private spinnerService:SpinnerService, private floraFaunaService: FloraFaunaService, private riverMonitoringService: RiverMonitoringService) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    // this.listRivers();
    // this.listFlora();
  }

  ngOnInit(){
    this.listRivers();
    this.listFlora();
  }

  async initMap() {
    // Request needed libraries.
    const { Map, InfoWindow } = await google.maps.importLibrary("maps") as google.maps.MapsLibrary;
    const { AdvancedMarkerElement, PinElement  } = await google.maps.importLibrary("marker") as google.maps.MarkerLibrary;

    const map = new google.maps.Map(
      document.getElementById("map") as HTMLElement, this.mapOptions
    );

    const infoWindow = new google.maps.InfoWindow({
      content: "",
      disableAutoPan: false,
    });

    // Create an array of alphabetical characters used to label the markers.
    const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const infoContent = document.getElementById("iw-content") as HTMLElement
    // Add some markers to the map.
    const markers = this.markers.map((loc:any, i:number) => {
      console.log("woooooooooooooooo")
      console.log(loc)
      var lat = loc.latitude? loc.latitude : loc.generalInformation?.latitude ? loc.generalInformation?.latitude:undefined;
      var lng = loc.longitude? loc.longitude : loc.generalInformation?.longitude ? loc.generalInformation?.longitude:undefined;
      var location = loc.location? loc.location : loc.generalInformation?.location ? loc.generalInformation?.location:undefined;
      var flora = loc.flora? loc.flora : loc.floraPictures ? loc.floraPictures:undefined;
      if(lat && lng){
        map.panTo(new google.maps.LatLng(lat + 6, lng));
        const label = labels[i % labels.length];
        const pinGlyph = new google.maps.marker.PinElement({
          glyph: label,
          glyphColor: "white",
        })

        const marker = new AdvancedMarkerElement({
            position: {
              lat: parseFloat(lat),
              lng: parseFloat(lng),
            },
          content: pinGlyph.element,
        });
        // markers can only be keyboard focusable when they have click listeners
        // open info window when marker is clicked
        marker.addListener("click", () => {
          this.selectedMarker = loc;
          this.selectedMarker.location = location;
          this.selectedMarker.flora=flora;
            infoWindow.setContent(infoContent);
            infoWindow.open(map, marker);
        });
        return marker;
      }
      else{
        return new google.maps.marker.AdvancedMarkerElement();
      }
    });
    // Add a marker clusterer to manage the markers.
    new MarkerClusterer({markers, map})
  }

  addMarker(data:any) {
    for(var i=0; i<data.length;i++){
      this.markers.push(data[i])
    }
    if(this.totalNumberOfPages == this.page){
      this.initMap();
    }
  }

    // //Water test details
    // addMarker2(data:any) {
    //   for(var i=0; i<data.length;i++){
    //     this.markers.push({
    //       lat:data[i]['generalInformation'].latitude,
    //       lng: data[i]['generalInformation'].longitude,
    //     });
    //   }
    //   if(this.totalNumberOfPages == this.page){
    //     this.initMap();
    //   }
    // }

  listRivers() {
    if(this.paginate == true){
       this.page = this.page+1;
       this.currentPageCounter++;
    }
    else{
      this.page = this.page;
      this.currentPageCounter=1;
    }
    this.riverMonitoringService.getRiverMonitoring(this.page, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
        this.totalNumberOfPages=Math.ceil((data['count']/this.pageSize));
        this.addMarker(data.rows)
        if(this.currentPageCounter <= this.totalNumberOfPages - 1){
          this.paginate=true;
          this.listRivers();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }

  listFlora() {
    this.spinnerService.setSpinner(true);
    if(this.paginateFlora == true){
       this.pageFlora = this.pageFlora+1;
       this.currentPageCounterFlora++;
    }
    else{
      this.pageFlora = this.pageFlora;
      this.currentPageCounterFlora=1;
    }
    this.floraFaunaService.getFloraFauna(this.pageFlora, this.pageSize).subscribe((data:any)=>{
      if(data['count']){
          this.totalNumberOfPagesFlora=Math.ceil((data['count']/this.pageSize));
          data.rows = data.rows.filter((row:any) => row.flora.length > 0)
          this.addMarker(data.rows)
        if(this.currentPageCounterFlora <= this.totalNumberOfPagesFlora - 1){
          this.paginateFlora=true;
          this.listFlora();
        }
      }
      this.spinnerService.setSpinner(false);
    });
  }
}

