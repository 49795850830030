import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FishSanctuariesService } from '../../../services/fish-sanctuaries.service';
import { SpinnerService } from '../../../services/spinner.service';
import { Location } from '@angular/common';
import {MatDialog, MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import { DeleteModalComponent } from '../../modals/delete-modal/delete-modal.component';
import { OrolService } from 'src/app/services/orol.service';
import { fadeInAnimation } from '../../../animations/index';
import { Router } from '@angular/router';

@Component({
  selector: 'app-list',
  templateUrl: './fish-sanctuaries-list.component.html',
  styleUrls: ['./fish-sanctuaries-list.component.scss'],
        // make fade in animation available to this component
        animations: [fadeInAnimation],
        // attach the fade in animation to the host (root) element of this component
        host: { '[@fadeInAnimation]': '' }
})
export class FishSanctuariesListComponent implements OnInit {
  user:any;
  page: number = 1;
  limit: number = 15;
  fishSanctuaries: any = [];
  count: number = 0;
  errorMessage: any;
  selectedSanctuary: any;
  isLoggedIn: boolean =false;

  constructor(public dialog: MatDialog, 
    private orolService: OrolService,
    private fishSanctuariesService: FishSanctuariesService, 
    private spinnerService: SpinnerService, 
    public location: Location,
    public router: Router) {
      this.orolService.userDetailsSubject.subscribe((data:any) => {
        //Check for Empty Object
        if(data && Object.keys(data).length != 0){
          this.user=data;
          this.isLoggedIn = true;
        }
        else{
          this.user={};
          this.isLoggedIn = false;
        }
      });
  }

  
  openDialog(selectedSanctuary:string, event:any) {
    this.selectedSanctuary=selectedSanctuary;
    const dialogRef = this.dialog.open(DeleteModalComponent, {
      // height: '400px',
      width: '400px',
      data: {
        title: 'DELETE',
        content: 'Are you sure you want to delete this water test detail for '+this.selectedSanctuary.locationDetails.name+'?'
      },
    });
    dialogRef.afterClosed().subscribe(res => {
      if(res.action == "delete"){
      this.deleteFishSanctuary();
      }
    });
    event.stopPropagation()
  }
    @HostListener("window:scroll", [])

  onScroll(): void {
  if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      // you're at the bottom of the page
      if(this.count != 0){
      if(((this.limit * this.page) - this.count) > 0){
      this.page=0;
      }
      else{
        if(this.page!=0){
        this.page = this.page+1
        this.getFishSanctuaries()
        }
      }
      }
    }
  }

  ngOnInit(): void {
    this.getFishSanctuaries();
  }

  navigateToView(id:any){
    // Navigate to the view screen
    this.router.navigate(['../fish-sanctuaries/', id])
  }

  //Pagination needs to be taken care of.
  /**
  * Method to call the list of fish sanctuaries from the service file
  * @param { page } page - pageNumber
  * @param { limit } limit - limit of records to be fetched
  * @returns { Promise } - Object {"access_token": "", "token_type": "Bearer","expires_in": 1637589288}
  **/
  getFishSanctuaries() {
    // this.spinnerService.setSpinner(true);
    this.fishSanctuariesService.getFishSanctuarie(this.page, this.limit).subscribe({
      complete: () => {  

      }, // completeHandler
      error: (err) => { 
        // this.spinnerService.setSpinner(false);
        this.errorMessage = err.error;
        setTimeout(() => {
          this.errorMessage = ""
        }, 5000);
       },    // errorHandler 
      next: (response:any) => { // nextHandler
        var usersItems = response['rows']
        this.count = response['count'];
       // this.fishSanctuaries = usersItems;
       usersItems.forEach((element: any) => {
       this.fishSanctuaries.push(element)
      //  this.spinnerService.setSpinner(false);
      })},     
    })
  }
      
    // var user:any = localStorage.getItem('User');
    // if(user != ''){
    //   user = JSON.parse(user);
    // }
        // var userId = user.id;
        //var usersItems = response['rows'].filter((element) => element.userId == userId)

  deleteFishSanctuary(){
    // this.spinnerService.setSpinner(true);
    this.fishSanctuariesService.deleteFishSanctuary(this.selectedSanctuary.id).subscribe(
      (response) => {
        // this.deleteModal.hide();
        // this.spinnerService.setSpinner(false);
        console.log(response)
        this.fishSanctuaries=[];
        this.page=1;
        this.getFishSanctuaries();
      },(err)=>{
        // this.deleteModal.hide();
        // this.spinnerService.setSpinner(false);
        alert(err)
      }
    );
  }
  showDeleteModal(riverObj:any){
    this.selectedSanctuary = riverObj;
    console.log(this.selectedSanctuary)
    // this.deleteModal.show()
  }
  
}
