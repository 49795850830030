import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { Route, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { NgZone } from '@angular/core';
import { RiverMonitoringService } from '../../../services/river-monitoring.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { SpinnerService } from '../../../services/spinner.service';
import { CONSTANTS } from 'src/app/utils/constants/constants';
import { Location } from '@angular/common';
import { MatStepper } from '@angular/material/stepper';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';


@Component({
  selector: 'app-river-monitoring-create-edit',
  templateUrl: './river-monitoring-create-edit.component.html',
  styleUrls: ['./river-monitoring-create-edit.component.scss']
})
export class RiverMonitoringCreateEditComponent implements OnInit {
  @ViewChild('river_monitoring_stepper', { static: false })
  river_monitoring_stepper!: MatStepper;
  @ViewChild('search', { static: false }) public searchElementRef!: ElementRef;
  @ViewChild(GoogleMap, { static: false })map!: GoogleMap;

  //Constants
  public SURROUNDINGS = CONSTANTS.SURROUNDINGS;
  public WATER_LEVELS = CONSTANTS.WATER_LEVELS;
  public WEATHER_CONDITIONS = CONSTANTS.WEATHER_CONDITIONS;
  public BACTERIA = CONSTANTS.BACTERIA;
  defaultImageURL: string = "../../../assets/icons/default_image_upload.jpg";
  defaultImageURLTemp: string = "../../../assets/icons/default_image_upload.jpg";
  public iconUrl = '../../../assets/icons/marker.svg';
  avatarURL: String = "../../assets/icons/profile.png";
  note = ".jpg, .jpeg, .png, files accepted";
  info = "(Max. size 100KB)";

  // REGEX Patterns
  decimalPattern = /^\d+(\.\d)?\d*$/;

  //Related to google maps
  zoom = 12;
  center!: google.maps.LatLngLiteral;
  centerLoc!: google.maps.LatLngLiteral;
  geocoder: any;
  options: google.maps.MapOptions = {
    mapTypeId: 'hybrid',
    zoomControl: false,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    maxZoom: 15,
    minZoom: 8,
  };
  markers = [] as any[];

  // Required for pagination of the list page
  public page = 1;
  public pageSize = 30;
  public paginate = false;
  public totalNumberOfPages = 1;
  public currentPageCounter = 1;
  pageNumber = 1;

  //Used to save all uploaded image files
  images = [];
  public imageFilesRiver: File[] = [];
  public imageFilesSurrounding: File[] = [];
  public imageFilesFlora: File[] = [];
  public imageFilesFauna: File[] = [];
  public imageFilesGroup: File[] = [];
  public imageFilesActivity: File[] = [];
  public imageFilesArtwork: File[] = [];

  //Used for stepper validation
  submitStep1: boolean = false;
  submitStep2: boolean = false;
  submitStep3: boolean = false;
  submitStep4: boolean = false;
  submitStep5: boolean = false;
  submitStep6: boolean = false;
  submitStepFinal: boolean = false;

  //Others
  userControl = new FormControl();
  activityForm: FormGroup | undefined;
  id: string = '';
  user: any;
  isAddMode = true;
  errorMessage: String = "";
  public imageFile: File = {} as File;
  public imageFileTemp: File = {} as File;
  imageFileErrorMessage: String = "";
  reports: any = [];
  lastClickedIndex: any;
  public riverMonitoring_details: any = [];

  constructor(public location: Location, private fb: FormBuilder, private riverMonitoringService: RiverMonitoringService, private router: Router, private imageCompress: NgxImageCompressService,
    private ngZone: NgZone, private spinnerService: SpinnerService, private route: ActivatedRoute) {
    this.user = JSON.parse(localStorage.getItem('User')!);
    this.id = this.route.snapshot.params['id'];
    this.isAddMode = !this.id;
    this.createForm();
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      // behavior: 'smooth'
    });
  }
  ngOnInit() {
    if (!this.isAddMode) {
      this.getRiverMonitoringDetails();
    }
    else { //Add Mode
      this.populateMap();
      this.setCurrentPosition();
    }
  }

  populateMap() {
    if (this.user) {
      this.setCurrentTime();
    }
  }
    patchLatLngFormValue(lat:any, lng:any){
      this.activityForm!.get('generalInformation')?.patchValue({
        latitude: lat,
        longitude: lng,
      });
  }

  addMarker(lat: any, lng: any) {
    this.markers=[];
    let position= {
      lat: lat,
      lng: lng
    };
    this.markers.push({
      position: {
        lat: this.activityForm!.get('generalInformation')!.get('latitude')!.value,
        lng: this.activityForm!.get('generalInformation')!.get('longitude')!.value,
      },
      label: {
        className: 'marker-label',
        text: 'You are here',
      },
      title: 'Marker title ',
      options: { animation: google.maps.Animation.BOUNCE, icon: {url: this.iconUrl}, },
    });
    this.patchLatLngFormValue(lat, lng)
    this.map.panTo(position)
    this.spinnerService.setSpinner(false);
  }


  getRiverMonitoringDetails() {
    this.riverMonitoringService.getRiverMonitoringDetails(this.route.snapshot.paramMap.get("id")).subscribe(
      (res:any) => {
        this.riverMonitoring_details = res;
        var activityDate = new Date(res['generalInformation'].activityDate);
        res['generalInformation'].activityDate = activityDate
        this.activityForm!.patchValue(res);
        this.patchArrayValues(res);
      },
      (err) => {
        console.log(err.error);
        console.log(err.status);
      },
      () => {

      })
  }

  patchArrayValues(res: any) {
    this.populateSurroundings();
    this.populateRiverPictures();
    this.populateSurroundingPictures();
    this.populateFloraPictures();
    this.populateFaunaPictures();
    this.populateGroupPictures();
    this.populateActivityPictures();
    this.populateArtworkPictures();
    this.setBacteria(res['waterTesting'].bacteria);
  }

  populateArtworkPictures() {
    let index = -1;
    this.riverMonitoring_details.artworkPictures.forEach((element: any, index: any) => {
      this.addArtworkPicture(element)
    });
  }

  populateActivityPictures() {
    let index = -1;
    this.riverMonitoring_details.activityPictures.forEach((element: any, index: any) => {
      this.addActivityPicture(element)
    });
  }

  populateGroupPictures() {
    let index = -1;
    this.riverMonitoring_details.groupPictures.forEach((element: any, index: any) => {
      this.addGroupPicture(element)
    });
  }

  populateFaunaPictures() {
    let index = -1;
    this.riverMonitoring_details.faunaPictures.forEach((element: any, index: any) => {
      this.addFaunaPicture(element)
    });
  }

  populateFloraPictures() {
    let index = -1;
    this.riverMonitoring_details.floraPictures.forEach((element: any, index: any) => {
      this.addFloraPicture(element)
    });
  }

  populateSurroundingPictures() {
    let index = -1;
    this.riverMonitoring_details.surroundingPictures.forEach((element: any, index: any) => {
      this.addSurroundingPicture(element)
    });
  }

  populateRiverPictures() {
    let index = -1;
    this.riverMonitoring_details.riverPictures.forEach((element: any, index: any) => {
      this.addRiverPicture(element)
    });
  }

  populateSurroundings() {
    this.riverMonitoring_details.surroundings.forEach((element: string) => {
      const index = this.SURROUNDINGS.map(e => e.name).indexOf(element);
      this.SURROUNDINGS[index].selected = true;

      //Update Form
      this.addSurrounding(element);
    });
  }

  //move to utils
  setCurrentTime() {
    let dt = new Date();
    let normalizeHour = dt.getHours() >= 13 ? dt.getHours() - 12 : dt.getHours();
    var hrs = normalizeHour.toString();
    let normalizeMins = dt.getMinutes();
    var mins = normalizeMins.toString();
    if (normalizeHour < 10) {
      hrs = "0" + normalizeHour;
    }

    if (normalizeMins < 10) {
      mins = "0" + mins;
    }

    var formattedTime = dt.getHours() >= 13 ? hrs + ':' + mins +  ' ' + 'PM' : hrs + ':' + mins +  ' ' +  'AM';
    this.activityForm!.get('generalInformation')!.patchValue({
      activityTime: formattedTime
    });
  }

  // bla() {
  //   this.getAddressByLatitudeAndLongitude(
  //     this.activityForm!.get('generalInformation')!.get('latitude')!.value,
  //     this.activityForm!.get('generalInformation')!.get('longitude')!.value,
  //     this.activityForm!.get('generalInformation')!);
  //   this.centerLoc = {
  //     lat: this.activityForm!.get('generalInformation')!.get('latitude')!.value, 
  //     lng: this.activityForm!.get('generalInformation')!.get('longitude')!.value
  //   };
  //   this.addMarker()
  // }

  handleAddressChange(address: Address) {
    let location = address.formatted_address;
    let lat = address.geometry.location.lat();
    let lng = address.geometry.location.lng();
    this.activityForm!.get('generalInformation')?.patchValue({
      latitude: lat,
      longitude: lng,
      location:location,
    });
    this.addMarker(lat,lng);
    this.zoom = 12;
    // map.panTo(curmarker.position);
  }


  createForm() {
    this.activityForm = this.fb.group({
      userId: [''],
      generalInformation: this.fb.group({
        activityDate: [(new Date()), [Validators.required]],
        activityTime: ['', [Validators.required]],
        testerName: ['', [Validators.required, Validators.maxLength(100)]],
        latitude: [[], [Validators.pattern(this.decimalPattern)]],
        longitude: [[], [Validators.pattern(this.decimalPattern)]],
        location: ['', [Validators.required, Validators.maxLength(200)]],
      }),
      waterLevelAndWeather: this.fb.group({
        airTemperature: [[], [Validators.required, Validators.pattern(this.decimalPattern)]],
        waterLevel: ['', [Validators.required]],
        weather: ['', [Validators.required]],
      }),
      surroundings: this.fb.array([]),
      waterTesting: this.fb.group({
        waterTemperature: [[], [Validators.pattern(this.decimalPattern)]],
        pH: [[], [Validators.pattern(this.decimalPattern)]],
        dissolvedOxygen: [[], [Validators.pattern(this.decimalPattern)]],
        hardness: [[], [Validators.pattern(this.decimalPattern)]],
        nitrate: [[], [Validators.pattern(this.decimalPattern)]],
        nitrite: [[], [Validators.pattern(this.decimalPattern)]],
        chlorine: [[], [Validators.pattern(this.decimalPattern)]],
        alkalinity: [[], [Validators.pattern(this.decimalPattern)]],
        iron: [[], [Validators.pattern(this.decimalPattern)]],
        bacteria: ['', [Validators.pattern(this.decimalPattern)]],
        turbidity: [[], [Validators.pattern(this.decimalPattern)]],
        phosphate: [[], [Validators.pattern(this.decimalPattern)]],
        ammonia: [[], [Validators.pattern(this.decimalPattern)]],
        lead: [[], [Validators.pattern(this.decimalPattern)]],
        totalDissolvedSolids: [[], [Validators.pattern(this.decimalPattern)]],
        conductivity: [[], [Validators.pattern(this.decimalPattern)]],
      }),
      riverPictures: this.fb.array([]),
      surroundingPictures: this.fb.array([]),
      floraPictures: this.fb.array([]),
      faunaPictures: this.fb.array([]),
      groupPictures: this.fb.array([]),
      activityPictures: this.fb.array([]),
      artworkPictures: this.fb.array([]),
    });
  }

  //All Form Arrays
  //River Picture Form Array
  riverPictures(i?: number): FormArray {
    return this.activityForm!.get("riverPictures") as FormArray
  }

  getRiverPictures() {
    let index = -1;
    this.riverMonitoring_details.riverPictures.forEach((element: any, index: any) => {
      this.addRiverPicture(element)
    });
  }

  newRiverPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addRiverPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.riverPictures().push(this.newRiverPicture(item));
  }
  removeRiverPicture(index: number) {
    this.imageFilesRiver.splice(index, 1);
    this.riverPictures().removeAt(index);
  }

  removeAllRiverPictures() {
    this.imageFilesRiver = [];
    this.riverPictures().clear();
  }
  //End of River

  //Surrounding Picture Form Array
  surroundingPictures(i?: number): FormArray {
    return this.activityForm!.get("surroundingPictures") as FormArray
  }

  getSurroundingPictures() {
    let index = -1;
    this.riverMonitoring_details.surroundingPictures.forEach((element: any, index: any) => {
      this.addSurroundingPicture(element)
    });
  }
  newSurroundingPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addSurroundingPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.surroundingPictures().push(this.newSurroundingPicture(item));

  }
  removeSurroundingPicture(index: number) {
    this.imageFilesSurrounding.splice(index, 1);
    this.surroundingPictures().removeAt(index);
  }

  removeAllSurroundingPictures() {
    this.imageFilesSurrounding = [];
    this.surroundingPictures().clear();
  }
  //End of Surrounding

  //Flora Picture Form Array
  floraPictures(i?: number): FormArray {
    return this.activityForm!.get("floraPictures") as FormArray
  }

  getFloraPictures() {
    let index = -1;
    this.riverMonitoring_details.floraPictures.forEach((element: any, index: any) => {
      this.addFloraPicture(element)
    });
  }
  newFloraPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addFloraPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.floraPictures().push(this.newFloraPicture(item));
  }
  removeFloraPicture(index: number) {
    this.imageFilesFlora.splice(index, 1);
    this.floraPictures().removeAt(index);
  }

  removeAllFloraPictures() {
    this.imageFilesFlora = [];
    this.floraPictures().clear();
  }
  //End of Flora

  //Fauna Picture Form Array
  faunaPictures(i?: number): FormArray {
    return this.activityForm!.get("faunaPictures") as FormArray
  }

  getFaunaPictures() {
    let index = -1;
    this.riverMonitoring_details.faunaPictures.forEach((element: any, index: any) => {
      this.addFaunaPicture(element)
    });
  }
  newFaunaPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addFaunaPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.faunaPictures().push(this.newFaunaPicture(item));
  }
  removeFaunaPicture(index: number) {
    this.imageFilesFauna.splice(index, 1);
    this.faunaPictures().removeAt(index);
  }

  removeAllFaunaPictures() {
    this.imageFilesFauna = [];
    this.faunaPictures().clear();
  }
  //End of Fauna

  //Activity Picture Form Array
  activityPictures(i?: number): FormArray {
    return this.activityForm!.get("activityPictures") as FormArray
  }

  getActivityPictures() {
    let index = -1;
    this.riverMonitoring_details.activityPictures.forEach((element: any, index: any) => {
      this.addActivityPicture(element)
    });
  }
  newActivityPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addActivityPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.activityPictures().push(this.newActivityPicture(item));
  }
  removeActivityPicture(index: number) {
    this.imageFilesActivity.splice(index, 1);
    this.activityPictures().removeAt(index);
  }

  removeAllActivityPictures() {
    this.imageFilesActivity = [];
    this.activityPictures().clear();
  }
  //End of ACtivity

  //group Picture Form Array
  groupPictures(i?: number): FormArray {
    return this.activityForm!.get("groupPictures") as FormArray
  }

  getGroupPictures() {
    let index = -1;
    this.riverMonitoring_details.groupPictures.forEach((element: any, index: any) => {
      this.addGroupPicture(element)
    });
  }
  newGroupPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addGroupPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.groupPictures().push(this.newGroupPicture(item));
  }
  removeGroupPicture(index: number) {
    this.imageFilesGroup.splice(index, 1);
    this.groupPictures().removeAt(index);
  }

  removeAllGroupPictures() {
    this.imageFilesGroup = [];
    this.groupPictures().clear();
  }
  //End of group picture


  //artwork Picture Form Array
  artworkPictures(i?: number): FormArray {
    return this.activityForm!.get("artworkPictures") as FormArray
  }

  getArtworkPictures() {
    let index = -1;
    this.riverMonitoring_details.artworkPictures.forEach((element: any, index: any) => {
      this.addArtworkPicture(element)
    });
  }
  newArtworkPicture(item: { imageURL: any; description: any; fileName: any; }): FormGroup {
    return this.fb.group({
      imageURL: item.imageURL,
      description: item.description,
      fileName: item.fileName
    })
  }

  addArtworkPicture(item: {
      imageURL: string; description: string; fileName: any; //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
    }) {
    this.artworkPictures().push(this.newArtworkPicture(item));
  }
  removeArtworkPicture(index: number) {
    this.imageFilesArtwork.splice(index, 1);
    this.artworkPictures().removeAt(index);
  }

  removeAllArtworkPictures() {
    this.imageFilesArtwork = [];
    this.artworkPictures().clear();
  }
  //End of artwork 

  //Surrounding Array
  surroundings(i?: number): FormArray {
    return this.activityForm!.get("surroundings") as FormArray
  }

  getSurroundings() {
    let index = -1;
    this.riverMonitoring_details.surroundings.forEach((element: any, index: any) => {
      this.addSurrounding(element)
    });
  }
  // newSurrounding(item): FormGroup {
  //   return item.name
  // }

  addSurrounding(item: any) {
    this.surroundings().push(new FormControl(item));
    console.log(this.activityForm!.value);
  }
  removeSurrounding(index: number) {
    this.surroundings().removeAt(index);
  }

  //end of surroundings array

  validateStep1() {
    console.log(this.activityForm!.value);
    var navigate = "";
    this.gotoTop();
    this.submitStep1 = true;

    if (this.activityForm!.get('generalInformation')!.get('activityDate')!.valid &&
      this.activityForm!.get('generalInformation')!.get('activityTime')!.valid &&
      this.activityForm!.get('generalInformation')!.get('testerName')!.valid &&
      this.activityForm!.get('generalInformation')!.get('location')!.valid
    ) {
      if (this.isAddMode) {
        if (this.id == undefined) {
          this.createWaterTestDetails(navigate);
        }
        else {
          this.updateWaterTestDetails(navigate);
        }
      }
      else {
        this.updateWaterTestDetails(navigate);
      }
    }
  }
  validateStep2() {
    var navigate = "";
    this.gotoTop();
    this.submitStep2 = true;

    if (this.activityForm?.get('waterLevelAndWeather')?.get('airTemperature')?.valid &&
      this.activityForm?.get('waterLevelAndWeather')?.get('waterLevel')?.valid &&
      this.activityForm?.get('waterLevelAndWeather')?.get('weather')?.valid &&
      this.activityForm.get('riverPictures')?.value.length > 0
    ) {
      this.updateWaterTestDetails(navigate);
    }
  }
  validateStep3() {
    var navigate = "";
    var surrArray = this.activityForm!.get('surroundings')! as FormArray;
    this.gotoTop();
    this.submitStep3 = true;


    if ((surrArray.length) > 0 &&  this.activityForm!.get('surroundingPictures')?.value.length > 0){
      this.updateWaterTestDetails(navigate);
    }
  }
  validateStep4() {
    var navigate = "";
    this.gotoTop();
    this.submitStep4 = true;
    this.updateWaterTestDetails(navigate);

  }
  validateStep5() {
    var navigate = "";
    this.gotoTop();
    this.submitStep5 = true;
    this.updateWaterTestDetails(navigate);
  }

  validateStep6() {
    var navigate = "";
    this.gotoTop();
    this.submitStep6 = true;
    if (this.imageFilesGroup.length > 0 || this.imageFilesActivity.length > 0 || this.imageFilesArtwork.length > 0) {
      this.updateWaterTestDetails(navigate);
    }
    else {
      this.goForward();
    }
  }

  validateStepFinal() {
    var navigate = "list";
    this.submitStepFinal = true;
    this.generateReport(this.id);
  }

  onScroll() {
    this.pageNumber = this.pageNumber + 1;
  }
  // getWaterTestDetails() {
  //   this.spinnerService.setSpinner(true);
  //   if (this.paginate == true) {
  //     this.page = this.page + 1;
  //     this.currentPageCounter++;
  //   }
  //   else {
  //     this.page = this.page;
  //     this.currentPageCounter = 1;
  //   }
  //  var user:any = localStorage.getItem('User');
  //   if(user != ''){
  //     user = JSON.parse(user);
  //   }

  //   this.riverMonitoringService.getWaterTestDetails(this.page, this.pageSize).subscribe(
  //     ((data:any)) => {
  //       if (data['count']) {
  //         this.totalNumberOfPages = Math.ceil((data['count'] / this.pageSize));
  //         for (var i = 0; i < data['rows'].length; i++) {
  //           if (data['rows'][i].userId == user.id) {
  //             this.reports.push(data['rows'][i]);
  //           }
  //         }
  //         // this.reports = data['rows'].filter(r => r.userId == user.id);
  //       }
  //       if (this.currentPageCounter <= this.totalNumberOfPages - 1) {
  //         this.paginate = true;
  //         this.getWaterTestDetails();
  //       }
  //       this.spinnerService.setSpinner(false);
  //     },
  //     (err) => {
  //       this.errorMessage = err.error;
  //       setTimeout(() => {
  //         this.errorMessage = "";
  //       }, 5000);
  //       this.spinnerService.setSpinner(false);
  //     },
  //     () => {
  //       this.spinnerService.setSpinner(false);
  //     }
  //   );
  // }

  navigate(mode: string) {
    if (mode == 'list') {
      this.router.navigate(['./river-monitoring']);
    }
    if (mode == 'home') {
      this.router.navigate(['./home']);
    }
  }

  defaultError(err: { error: String; }) {
    this.gotoTop();
    this.spinnerService.setSpinner(false);
    this.errorMessage = err.error;
    this.clearErrorMessage();
  }

  clearErrorMessage() {
    setTimeout(() => {
      this.errorMessage = "";
    }, 5000);
  }

  createWaterTestDetails(navigateMode: string) {
    this.spinnerService.setSpinner(true);
    this.riverMonitoringService.createWaterTestDetails(this.activityForm!.value, this.imageFilesRiver,
      this.imageFilesSurrounding, this.imageFilesFlora, this.imageFilesFauna, this.imageFilesGroup, this.imageFilesActivity, this.imageFilesArtwork).
      subscribe((data:any) => {
          this.spinnerService.setSpinner(false);
          console.log(data);
          // data.map((key: any,value: any) => {
          //   console.log(`${key} : ${value}`)
          // });
          for (const [key, value] of Object.entries(data)) {
            console.log(`${key}: ${value}`);
          }
          this.activityForm!.patchValue(data);
          console.log(this.activityForm!.value);
          this.id = data['id'];
          this.goForward();
        },
        (err) => {
          this.defaultError(err);
        },
        () => {
          if (navigateMode != "") {
            this.navigate(navigateMode);
          }
        }
      )
  };

  updateWaterTestDetails(navigateMode: string) {
    this.spinnerService.setSpinner(true);
    this.riverMonitoringService.updateWaterTestDetails(this.id, this.activityForm!.value, this.imageFilesRiver,
      this.imageFilesSurrounding, this.imageFilesFlora, this.imageFilesFauna, this.imageFilesGroup, this.imageFilesActivity, this.imageFilesArtwork).
      subscribe((data:any) => {
          this.spinnerService.setSpinner(false);
          this.clearAllImageFiles();
          this.activityForm!.patchValue(data);
          this.goForward();
        },
        (err) => {
          this.defaultError(err.statusText);
        },
        () => {
          if (navigateMode == 'list')
            this.router.navigate(['./river-monitoring']);
        }
      )
  };


  //Call Generate REPORT
  generateReport(data: any) {
    this.riverMonitoringService.generateReport(data).subscribe(
      (res) => {
        this.spinnerService.setSpinner(false);
        console.log(res);
      },
      (err) => {
        this.spinnerService.setSpinner(false);
        console.log(err);
      },
      () => {
        this.router.navigate(['./river-monitoring']);
      }
    );
  }

  clearAllImageFiles() {
    this.imageFilesRiver = [];
    this.imageFilesSurrounding = [];
    this.imageFilesFlora = [];
    this.imageFilesFauna = [];
    this.imageFilesGroup = [];
    this.imageFilesActivity = [];
    this.imageFilesArtwork = [];
  }

  compressFile(base64URL: string, filename: string, mode: string) {
    var orientation = -1;
    this.imageCompress.compressFile(base64URL, orientation, 50, 50).then(
      result => {
        var item = {
          imageURL: result,
          description: '',
          fileName: filename //Intentionally sending file name so that we can cmpare filename in nodejs to attach description
        }
        if (mode == "river") {
          this.imageFilesRiver.push(this.dataURLtoFile(result, filename,));
          this.addRiverPicture(item);
        }
        else if (mode == "surrounding") {
          this.imageFilesSurrounding.push(this.dataURLtoFile(result, filename));
          this.addSurroundingPicture(item);
        }
        else if (mode == "flora") {
          this.imageFilesFlora.push(this.dataURLtoFile(result, filename));
          this.addFloraPicture(item);
        }
        else if (mode == "fauna") {
          this.imageFilesFauna.push(this.dataURLtoFile(result, filename));
          this.addFaunaPicture(item);

        }
        else if (mode == "activity") {
          this.imageFilesActivity.push(this.dataURLtoFile(result, filename));
          this.addActivityPicture(item);

        }
        else if (mode == "groupPicture") {
          this.imageFilesGroup.push(this.dataURLtoFile(result, filename));
          this.addGroupPicture(item);

        }
        else if (mode == "artwork") {
          this.imageFilesArtwork.push(this.dataURLtoFile(result, filename));
          this.addArtworkPicture(item);
        }
      });
  }
  dataURLtoFile(dataurl: any, filename: string) {
    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  selectRiverPhotos(event: { target: { files: string | any[]; }; }) {
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "river_" + Date.now();
          console.log(Date.now());
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "river");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectSurroundingPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlSurrounding = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "surrounding_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "surrounding");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectFloraPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlFlora = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "flora_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "flora");

          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectFaunaPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlFauna = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "fauna_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "fauna");

          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectGroupPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlGroup = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "groupPicture_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "groupPicture");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectActivityPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlActivity = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "activity_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "activity");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }
  selectArtworkPhotos(event: { target: { files: string | any[]; }; }) {
    // this.imageUrlArtwork = [];
    if (event.target.files && event.target.files[0]) {
      var length = event.target.files.length;
      for (let i = 0; i < event.target.files.length; i++) {
        setTimeout(() => {
          var _filename = "artwork_" + Date.now();
          var reader = new FileReader();
          reader.onload = (event: any) => {
            this.compressFile(event.target.result, _filename, "artwork");
          }
          reader.readAsDataURL(event.target.files[i]);
        }, 1);
      }
    }
  }

  private setCurrentPosition() {

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var accuracy = position.coords.accuracy;
        this.activityForm!.get('generalInformation')?.patchValue({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        this.centerLoc = { lat: position.coords.latitude, lng: position.coords.longitude };
      console.log(this.centerLoc)

        this.getAddressByLatitudeAndLongitude(position.coords.latitude, position.coords.longitude, this.activityForm);
        this.addMarker(position.coords.latitude,position.coords.longitude)
      });
    }
  }

  async getAddressByLatitudeAndLongitude(lat: number | google.maps.LatLng | google.maps.LatLngLiteral, 
    lng: number | boolean | null | undefined, 
    form: AbstractControl<any, any> | undefined) {
    var address;
    this.geocoder = new google.maps.Geocoder();
    var latlng = new google.maps.LatLng(lat, lng);
    await this.geocoder.geocode({ latLng: latlng }, function (results: { formatted_address: any; }[], status: string) {
      if (status == google.maps.GeocoderStatus.OK) {
        var arrAddress = results;
        address = results[0].formatted_address;
        form!.get('generalInformation')!.patchValue({
          location: address
        });
      } else {
        console.log("Geocoder failed due to: " + status);
      }
    });
  }

  setWeather(name: any) {
    this.activityForm!.patchValue({
      waterLevelAndWeather: {
        weather: name
      }
    });
  }

  setWaterLevel(name: any) {
    this.activityForm!.patchValue({
      waterLevelAndWeather: {
        waterLevel: name
      }
    });
  }
  setBacteria(name: any) {
    this.activityForm!.patchValue({
      waterTesting: {
        bacteria: name
      }
    });
  }


  changeSurroundings(obj: { selected: boolean; name: any; }) {
    obj.selected = !obj.selected;
    if (obj.selected == true) {
      this.addSurrounding(obj.name);
    }
    else {
      let index = this.surroundings().value.findIndex((record: any) => record === (obj.name));
      this.removeSurrounding(index);
    }
    console.log(this.activityForm!.value);
  }

  goBack(river_monitoring_stepper: MatStepper){
    river_monitoring_stepper.previous();
  }

  goForward(){
    this.river_monitoring_stepper.next();
  } 
  // checkSelectedSurroundings(capability) {
  //   let value = "-1";
  //   let index = this.surroundingArray.value.findIndex(record => record === capability);
  //   if (index != -1) {
  //     value = this.surroundingArray.value[index];
  //   }
  //   return value;
  // }

  // getSelectedSurroundings(event, obj) {
  //   if (event.element.checked == true) {
  //     this.surroundingArray.push(this.fb.control(obj));
  //   }
  //   else {
  //     let index = this.surroundingArray.value.findIndex(record => record === (obj));
  //     if (index != -1) {
  //       this.surroundingArray.removeAt(index);
  //     }
  //   }
  // }
}
