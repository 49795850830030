export class CONSTANTS {
  static get SURROUNDINGS() {
    return [
      {
        id: '0',
        name: 'Clothes washing',
        selected: false
      },
      {
        id: '1',
        name: 'Cattle grazing',
        selected: false
      },
      {
        id: '2',
        name: 'Vehicles',
        selected: false
      },
      {
        id: '3',
        name: 'Agricultural land',
        selected: false
      },
      {
        id: '4',
        name: 'Plantation',
        selected: false
      },
      {
        id: '5',
        name: 'Bridge',
        selected: false
      },
      {
        id: '6',
        name: 'Industry',
        selected: false
      },
      {
        id: '7',
        name: 'Place of worship',
        selected: false
      },
      {
        id: '8',
        name: 'Village',
        selected: false
      },
      {
        id: '9',
        name: 'Town',
        selected: false
      },
      {
        id: '10',
        name: 'Effluent discharge',
        selected: false
      },
      {
        id: '11',
        name: 'Sewage discharge',
        selected: false
      },
      {
        id: '12',
        name: 'Irrigation pump',
        selected: false
      },
    ]
  }
  static get MANMADE_PHYSICAL_FEATURES() {
    return [
      {
        id: '0',
        name: 'Embankment',
        description: 'Embankment',
        selected: false
      },
      {
        id: '1',
        name: 'Check Dam',
        description: 'Check Dam',
        selected: false
      },
      {
        id: '2',
        name: 'Steps',
        description: 'Steps',
        selected: false
      },
      {
        id: '3',
        name: 'Pebbles',
        description: 'Pebbles',
        selected: false
      },
      {
        id: '4',
        name: 'Building(s)',
        description: 'Building(s)',
        selected: false
      },
      {
        id: '5',
        name: 'Other',
        description: '',
        selected: false
      },
    ];
  };

  static get NATURAL_PHYSICAL_FEATURES() {
    return [
      {
        id: '0',
        name: 'Gorge',
        description: 'Gorge',
        selected: false
      },
      {
        id: '1',
        name: 'Boulders',
        description: 'Boulders',
        selected: false
      },
      {
        id: '2',
        name: 'Rocks',
        description: 'Rocks',
        selected: false
      },
      {
        id: '3',
        name: 'Pebbles',
        description: 'Pebbles',
        selected: false
      },
      {
        id: '4',
        name: 'Gravel',
        description: 'Gravel',
        selected: false
      },
      {
        id: '5',
        name: 'Sand',
        description: 'Sand',
        selected: false
      },
      {
        id: '6',
        name: 'Other',
        description: '',
        selected: false
      },
    ];
  };

  static get NATURAL_VEGETATION() {
    return [
      {
        id: '0',
        name: 'Trees',
        description: 'Trees',
        selected: false
      },
      {
        id: '1',
        name: 'Shrubs',
        description: 'Shrubs',
        selected: false
      },
      {
        id: '2',
        name: 'Grass',
        description: 'Grass',
        selected: false
      },
    ];
  };

  static get PHYSICAL_INFRASTRUCTURE() {
    return [
      {
        id: '0',
        name: 'Temple',
        description: 'Temple',
        selected: false
      },

      {
        id: '1',
        name: 'Shrine',
        description: 'Shrine',
        selected: false
      },
      {
        id: '2',
        name: 'Sacred Grove',
        description: 'Sacred Grove',
        selected: false
      },
      {
        id: '3',
        name: 'Sacred Tree',
        description: 'Sacred Tree',
        selected: false
      },
      {
        id: '4',
        name: 'Prayer Stones',
        description: 'Prayer Stones',
        selected: false
      },
      {
        id: '5',
        name: 'Name Boards',
        description: 'Name Boards',
        selected: false
      },
      {
        id: '6',
        name: 'National Park',
        description: 'National Park',
        selected: false
      },
      {
        id: '7',
        name: 'Wildlife Sanctuary',
        description: 'Wildlife Sanctuary',
        selected: false
      },
      {
        id: '8',
        name: 'Reserved Forest',
        description: 'Reserved Forest',
        selected: false
      },
      {
        id: '9',
        name: 'Other',
        description: '',
        selected: false
      },
    ]
  };

  static get WATER_LEVELS(){
    return[
        {
          name: "Low",
          imageUrl: "../../../../assets/icons/water_level_low_icon.svg",
          imageUrlEnable: "../../../assets/icons/water_level_low_icon_enable.svg",
    
        },
        {
          name: "Normal",
          imageUrl: "../../../assets/icons/water_level_normal_icon.svg",
          imageUrlEnable: "../../../assets/icons/water_level_normal_icon_enable.svg",
    
        },
    
        {
          name: "High",
          imageUrl: "../../../assets/icons/water_level_high_icon.svg",
          imageUrlEnable: "../../../assets/icons/water_level_high_icon_enable.svg",
    
        },
        {
          name: "Flooded",
          imageUrl: "../../../assets/icons/water_level_flooded_icon.svg",
          imageUrlEnable: "../../../assets/icons/water_level_flooded_enable.svg",
    
        },
    ];
  }

  static get WEATHER_CONDITIONS(){
    return[
      {
        name: "Sunny",
        imageUrl: "../../../assets/icons/sunny_icon.svg",
        imageUrlEnable: "../../../assets/icons/sunny_icon_enable.svg",
      },
      {
        name: "Partly Cloudy",
        imageUrl: "../../../assets/icons/partly_cloudy_icon.svg",
        imageUrlEnable: "../../../assets/icons/partly_cloudy_icon_enable.svg",
  
      },
      {
        name: "Cloudy",
        imageUrl: "../../../assets/icons/cloudy_icon.svg",
        imageUrlEnable: "../../../assets/icons/cloudy_icon_enable.svg",
  
      },
      {
        name: "Light Rain",
        imageUrl: "../../../assets/icons/light_rain_icon.svg",
        imageUrlEnable: "../../../assets/icons/light_rain_icon_enable.svg",
  
      },
      {
        name: "Heavy Rain",
        imageUrl: "../../../assets/icons/heavy_rain_icon.svg",
        imageUrlEnable: "../../../assets/icons/heavy_rain_icon_enable.svg",
  
      },
    ];
  }

  static get BACTERIA(){
    return[
      { name: "Present" },
      { name: "Absent" },
    ];
  }
}