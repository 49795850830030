import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from "../../../shared/services/auth.service";
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { SignInComponent } from '../sign-in/sign-in.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { OrolService } from 'src/app/services/orol.service';
@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements AfterViewInit {
  modalRefSignIn: MdbModalRef<SignInComponent> | null = null;
  public errorMessage:string="";
  // @Output() isRegister = new EventEmitter();
  // @Output() isLogin = new EventEmitter();
  // @Output() isRecoverPassword = new EventEmitter();

  registerForm: any;

  constructor(public spinnerService: SpinnerService, public orolService: OrolService, public authService: AuthService,
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    // private router: Router,
    private modalService: MdbModalService,
    public modalRefSignUp: MdbModalRef<SignUpComponent>) {

      this.authService.errorMessage.subscribe((data:any) => {
        this.errorMessage=data;
      });
    }

    ngAfterViewInit(): void {
      this.registerForm = this.formBuilder.group({
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        mobilenumber: ['', Validators.required],
        email: ['', Validators.required],
        password: ['', [Validators.required, Validators.minLength(6)]]
      });
    }

    login(){
      this.modalRefSignUp.close();
      this.modalRefSignIn = this.modalService.open(SignInComponent)
    }

    signUp(email:string, password:string, phone:string, firstName:string, lastName:string ){  
      if(firstName!=""){
        if(lastName!=""){
          var emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
          if(email!="" && email.match(emailPattern)){
            var phonePattern = /^\d{10}$/;
            if(phone!="" && phone.match(phonePattern)){
              if(password!=""){
                this.authService.SignUp(email, password, phone, firstName, lastName)
              }else{
              this.errorMessage = "Please enter valid password name";
              }
            }else{
              this.errorMessage = "Please enter valid phone name";
            }
          }else{
            this.errorMessage = "Please enter valid email name";
          }
        }else{
        this.errorMessage = "Please enter valid second name";
        }
      }else{
        this.errorMessage = "Please enter valid first name";
      }
    }
  }
