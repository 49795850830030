import { ChangeDetectionStrategy } from '@angular/core';
import { ChangeDetectorRef, Component, EventEmitter,Output,OnInit } from '@angular/core';

@Component({
  selector: 'app-maps-view',
  templateUrl: './maps-view.component.html',
  styleUrls: ['./maps-view.component.scss']
})
export class MapsViewComponent implements OnInit {
  mapsMode:number=1;
  filterMode: any = 5;
  startDate!: string;
  endDate: any;

  constructor(public cd:ChangeDetectorRef) {}

  ngOnInit(){
  }
  selectMap(mode:number){
    this.mapsMode=mode;
  }
  selectFilter(mode:number){
    this.filterMode=mode;

    //Today
    if(this.filterMode==1){
      this.getDatesForToday();
    }
    //This Week
    else if(this.filterMode==2){
      this.getDatesForThisWeek();
    }
    //This Month
    else if(this.filterMode==3){
      this.getDatesForThisMonth();
    }

    //This Year
    else if(this.filterMode==4){
      this.getDatesForThisYear();
    }
    //All
    else if(this.filterMode==5){
      this.getAll()
    }
  }
  getAll(){
    this.startDate="";
    this.endDate="";
  }
  getDatesForThisYear() {
    const today = new Date()
    this.startDate = today.getFullYear()+'-01-01'; 
    this.endDate = today.getFullYear() + 1+'-01-01'; 
  }
  getDatesForThisMonth() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    lastDay.setDate(lastDay.getDate() +1)
    this.startDate = firstDay.getFullYear()+'-'+(firstDay.getMonth()+1)+'-'+firstDay.getDate(); 
    this.endDate = lastDay.getFullYear()+'-'+(lastDay.getMonth()+1)+'-'+lastDay.getDate(); 
  }
  getDatesForThisWeek() {
    const today = new Date()
    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1)
    let lastWeek =  new Date()
    lastWeek.setDate(today.getDate() - 6)

    this.startDate = lastWeek.getFullYear()+'-'+(lastWeek.getMonth()+1)+'-'+lastWeek.getDate(); 
    this.endDate = tomorrow.getFullYear()+'-'+(tomorrow.getMonth()+1)+'-'+tomorrow.getDate(); 
  }
  getDatesForToday() {
    const today = new Date()
    let tomorrow =  new Date()
    tomorrow.setDate(today.getDate() + 1)
    this.startDate = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate(); 
    this.endDate = tomorrow.getFullYear()+'-'+(tomorrow.getMonth()+1)+'-'+tomorrow.getDate(); 
  }
}
