import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticker',
  templateUrl: './ticker.component.html',
  styleUrls: ['./ticker.component.scss']
})
export class TickerComponent implements OnInit {
  date = new Date();
  year = this.date.getFullYear();

  constructor() { }

  ngOnInit(): void {
   

  }

}
