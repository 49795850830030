import { Component, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../animations/index';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  // make fade in animation available to this component
  animations: [fadeInAnimation],
  // attach the fade in animation to the host (root) element of this component
  host: { '[@fadeInAnimation]': '' }
})
export class AboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  //
  // ngAfterViewInit() {
  //   jarallax(document.querySelectorAll('.jarallax'), {
  //     speed: 0.2
  //   });
  // }

}
