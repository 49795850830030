import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Route, Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SpinnerService } from '../services/spinner.service';
import { Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RiverMonitoringService {
  public userDetailsSubject = new BehaviorSubject({});
  userDetails = this.userDetailsSubject.asObservable();
  baseURL:String = "https://api.ourriverourlife.com/";

  constructor(private router: Router, public httpClient: HttpClient, private spinnerService: SpinnerService) { }

  /**
  * Method to get the list of River monitoring
  * @param { page } page - pageNumber
  * @param { limit } limit - limit of records to be fetched
  * @returns { Promise } - Object {"access_token": "", "token_type": "Bearer","expires_in": 1637589288}
  **/
  public getRiverMonitoring(page: string | number, limit: string | number) {
    this.spinnerService.setSpinner(true);
    let user:any={};
    if (localStorage.getItem('User')) {
      user = localStorage.getItem('User');
      // localStorage.setItem('User', JSON.stringify(user));
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
        Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.get(this.baseURL + "water-test-details?page=" + page + "&limit=" + limit);
    // return this.httpClient.get(this.baseURL + "water-test-details");
  }

  public getRiverMonitoringDetails(id: string | null) {
   
    this.spinnerService.setSpinner(true);
    
    return this.httpClient.get(this.baseURL + "water-test-details/" + id);
  }

  public createWaterTestDetails(x: { riverPictures: { imageURL: string | string[]; }[]; surroundingPictures: { imageURL: string | string[]; }[]; floraPictures: { imageURL: string | string[]; }[]; faunaPictures: { imageURL: string | string[]; }[]; groupPictures: { imageURL: string | string[]; }[]; activityPictures: { imageURL: string | string[]; }[]; artworkPictures: { imageURL: string | string[]; }[]; generalInformation: { activityDate: string | Blob; activityTime: string | Blob; testerName: string | Blob; latitude: string | Blob; longitude: string | Blob; location: string | Blob; }; waterLevelAndWeather: { airTemperature: string | Blob; waterLevel: string | Blob; weather: string | Blob; }; surroundings: any; waterTesting: { waterTemperature: string | Blob; pH: string | Blob; dissolvedOxygen: string | Blob; hardness: string | Blob; nitrate: string | Blob; nitrite: string | Blob; chlorine: string | Blob; alkalinity: string | Blob; iron: string | Blob; bacteria: string | Blob; turbidity: string | Blob; phosphate: string | Blob; ammonia: string | Blob; lead: string | Blob; totalDissolvedSolids: string | Blob; conductivity: string | Blob; }; certificateURL: string | Blob; }, imageFilesRiver: File[], imageFilesSurrounding: File[], imageFilesFlora: File[],
    imageFilesFauna: File[], imageFilesGroup: File[], imageFilesActivity: File[], imageFilesArtwork: File[]) {
    this.spinnerService.setSpinner(true);
    var user:any = localStorage.getItem('User');
    if(user){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });

    x.riverPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.surroundingPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.floraPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.faunaPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.groupPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.activityPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.artworkPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    const form = new FormData;
    for (var i = 0; i < imageFilesRiver.length; i++) {
      form.append('riverFiles', imageFilesRiver[i]);
    }
    for (var i = 0; i < imageFilesSurrounding.length; i++) {
      form.append('surroundingFiles', imageFilesSurrounding[i]);
    }
    for (var i = 0; i < imageFilesFlora.length; i++) {
      form.append('floraFiles', imageFilesFlora[i]);
    }
    for (var i = 0; i < imageFilesFauna.length; i++) {
      form.append('faunaFiles', imageFilesFauna[i]);
    }
    for (var i = 0; i < imageFilesGroup.length; i++) {
      form.append('groupFiles', imageFilesGroup[i]);
    }
    for (var i = 0; i < imageFilesActivity.length; i++) {
      form.append('activityFiles', imageFilesActivity[i]);
    }
    for (var i = 0; i < imageFilesArtwork.length; i++) {
      form.append('artworkFiles', imageFilesArtwork[i]);
    }
    form.append("userId", user.id);
    form.append("generalInformation[activityDate]", x.generalInformation.activityDate);
    form.append("generalInformation[activityTime]", x.generalInformation.activityTime);
    form.append("generalInformation[testerName]", x.generalInformation.testerName);
    form.append("generalInformation[latitude]", x.generalInformation.latitude);
    form.append("generalInformation[longitude]", x.generalInformation.longitude);
    form.append("generalInformation[location]", x.generalInformation.location);
    form.append("waterLevelAndWeather[airTemperature]", x.waterLevelAndWeather.airTemperature);
    form.append("waterLevelAndWeather[waterLevel]", x.waterLevelAndWeather.waterLevel);
    form.append("waterLevelAndWeather[weather]", x.waterLevelAndWeather.weather);
    form.append("surroundings", JSON.stringify(x.surroundings));
    form.append("waterTesting[waterTemperature]", x.waterTesting.waterTemperature);
    form.append("waterTesting[pH]", x.waterTesting.pH);
    form.append("waterTesting[dissolvedOxygen]", x.waterTesting.dissolvedOxygen);
    form.append("waterTesting[hardness]", x.waterTesting.hardness);
    form.append("waterTesting[nitrate]", x.waterTesting.nitrate);
    form.append("waterTesting[nitrite]", x.waterTesting.nitrite);
    form.append("waterTesting[chlorine]", x.waterTesting.chlorine);
    form.append("waterTesting[alkalinity]", x.waterTesting.alkalinity);
    form.append("waterTesting[iron]", x.waterTesting.iron);
    form.append("waterTesting[bacteria]", x.waterTesting.bacteria);
    form.append("waterTesting[turbidity]", x.waterTesting.turbidity);
    form.append("waterTesting[phosphate]", x.waterTesting.phosphate);
    form.append("waterTesting[ammonia]", x.waterTesting.ammonia);
    form.append("waterTesting[lead]", x.waterTesting.lead);
    form.append("waterTesting[totalDissolvedSolids]", x.waterTesting.totalDissolvedSolids);
    form.append("waterTesting[conductivity]", x.waterTesting.conductivity);
    form.append("floraPictures", JSON.stringify(x.floraPictures));
    form.append("faunaPictures", JSON.stringify(x.faunaPictures));
    form.append("artworkPictures", JSON.stringify(x.artworkPictures));
    form.append("groupPictures", JSON.stringify(x.groupPictures));
    form.append("activityPictures", JSON.stringify(x.activityPictures));
    form.append("riverPictures", JSON.stringify(x.riverPictures));
    form.append("surroundingPictures", JSON.stringify(x.surroundingPictures));
    form.append("certificateURL", x.certificateURL);
    return this.httpClient.post(this.baseURL + "water-test-details/create-web", form, { headers: httpHeaders });
  }
  
  public updateWaterTestDetails(waterTestDetailsId: string, x: { riverPictures: { imageURL: string | string[]; }[]; surroundingPictures: { imageURL: string | string[]; }[]; floraPictures: { imageURL: string | string[]; }[]; faunaPictures: { imageURL: string | string[]; }[]; groupPictures: { imageURL: string | string[]; }[]; activityPictures: { imageURL: string | string[]; }[]; artworkPictures: { imageURL: string | string[]; }[]; generalInformation: { activityDate: string | Blob; activityTime: string | Blob; testerName: string | Blob; latitude: string | Blob; longitude: string | Blob; location: string | Blob; }; waterLevelAndWeather: { airTemperature: string | Blob; waterLevel: string | Blob; weather: string | Blob; }; surroundings: any; waterTesting: { waterTemperature: string | Blob; pH: string | Blob; dissolvedOxygen: string | Blob; hardness: string | Blob; nitrate: string | Blob; nitrite: string | Blob; chlorine: string | Blob; alkalinity: string | Blob; iron: string | Blob; bacteria: string | Blob; turbidity: string | Blob; phosphate: string | Blob; ammonia: string | Blob; lead: string | Blob; totalDissolvedSolids: string | Blob; conductivity: string | Blob; }; certificateURL: string | Blob | undefined; }, imageFilesRiver: File[], imageFilesSurrounding: File[], imageFilesFlora: File[],
    imageFilesFauna: File[], imageFilesGroup: File[], imageFilesActivity: File[], imageFilesArtwork: File[]) {
    this.spinnerService.setSpinner(true);
    var user:any = localStorage.getItem('User');
    
    if(user){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });

    x.riverPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.surroundingPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.floraPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.faunaPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.groupPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.activityPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    x.artworkPictures.forEach((element: any) => {
      if (element.imageURL.includes('data:')) {
        element.imageURL = "";
      }
    });
    const form = new FormData;
    for (var i = 0; i < imageFilesRiver.length; i++) {
      form.append('riverFiles', imageFilesRiver[i]);
    }
    for (var i = 0; i < imageFilesSurrounding.length; i++) {
      form.append('surroundingFiles', imageFilesSurrounding[i]);
    }
    for (var i = 0; i < imageFilesFlora.length; i++) {
      form.append('floraFiles', imageFilesFlora[i]);
    }
    for (var i = 0; i < imageFilesFauna.length; i++) {
      form.append('faunaFiles', imageFilesFauna[i]);
    }
    for (var i = 0; i < imageFilesGroup.length; i++) {
      form.append('groupFiles', imageFilesGroup[i]);
    }
    for (var i = 0; i < imageFilesActivity.length; i++) {
      form.append('activityFiles', imageFilesActivity[i]);
    }
    for (var i = 0; i < imageFilesArtwork.length; i++) {
      form.append('artworkFiles', imageFilesArtwork[i]);
    }
    form.append("userId", user.id);
    form.append("generalInformation[activityDate]", x.generalInformation.activityDate);
    form.append("generalInformation[activityTime]", x.generalInformation.activityTime);
    form.append("generalInformation[testerName]", x.generalInformation.testerName);
    form.append("generalInformation[latitude]", x.generalInformation.latitude);
    form.append("generalInformation[longitude]", x.generalInformation.longitude);
    form.append("generalInformation[location]", x.generalInformation.location);
    form.append("waterLevelAndWeather[airTemperature]", x.waterLevelAndWeather.airTemperature);
    form.append("waterLevelAndWeather[waterLevel]", x.waterLevelAndWeather.waterLevel);
    form.append("waterLevelAndWeather[weather]", x.waterLevelAndWeather.weather);
    form.append("surroundings", JSON.stringify(x.surroundings));
    form.append("waterTesting[waterTemperature]", x.waterTesting.waterTemperature);
    form.append("waterTesting[pH]", x.waterTesting.pH);
    form.append("waterTesting[dissolvedOxygen]", x.waterTesting.dissolvedOxygen);
    form.append("waterTesting[hardness]", x.waterTesting.hardness);
    form.append("waterTesting[nitrate]", x.waterTesting.nitrate);
    form.append("waterTesting[nitrite]", x.waterTesting.nitrite);
    form.append("waterTesting[chlorine]", x.waterTesting.chlorine);
    form.append("waterTesting[alkalinity]", x.waterTesting.alkalinity);
    form.append("waterTesting[iron]", x.waterTesting.iron);
    form.append("waterTesting[bacteria]", x.waterTesting.bacteria);
    form.append("waterTesting[turbidity]", x.waterTesting.turbidity);
    form.append("waterTesting[phosphate]", x.waterTesting.phosphate);
    form.append("waterTesting[ammonia]", x.waterTesting.ammonia);
    form.append("waterTesting[lead]", x.waterTesting.lead);
    form.append("waterTesting[totalDissolvedSolids]", x.waterTesting.totalDissolvedSolids);
    form.append("waterTesting[conductivity]", x.waterTesting.conductivity);
    form.append("floraPictures", JSON.stringify(x.floraPictures));
    form.append("faunaPictures", JSON.stringify(x.faunaPictures));
    form.append("artworkPictures", JSON.stringify(x.artworkPictures));
    form.append("groupPictures", JSON.stringify(x.groupPictures));
    form.append("activityPictures", JSON.stringify(x.activityPictures));
    form.append("riverPictures", JSON.stringify(x.riverPictures));
    form.append("surroundingPictures", JSON.stringify(x.surroundingPictures));
    form.append("certificateURL", x.certificateURL != undefined ? x.certificateURL : "");
    return this.httpClient.put(this.baseURL + "water-test-details/" + waterTestDetailsId, form, { headers: httpHeaders });
  }

  public generateReport(id: any) {
    var user:any = localStorage.getItem('User');
    
    if(user){
      user = JSON.parse(user);
    }
    this.spinnerService.setSpinner(true);
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.post(this.baseURL + "pdf/generateReport", {id: id}, { headers: httpHeaders });
  }

  public deleteRiver(id: string){
        var user:any = localStorage.getItem('User');
    if(user != ''){
      user = JSON.parse(user);
    }
    const httpHeaders: HttpHeaders = new HttpHeaders({
      Authorization: 'Bearer ' + user.accessToken
    });
    return this.httpClient.delete(this.baseURL + "water-test-details/"+id, { headers: httpHeaders });
  }
}
