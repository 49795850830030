
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { HttpClientModule } from '@angular/common/http';

//For toolbar.js
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import {MatMenuModule} from '@angular/material/menu';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatExpansionModule} from '@angular/material/expansion';


import {MatIconModule} from '@angular/material/icon';
import {CdkAccordionModule} from '@angular/cdk/accordion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { HomeComponent } from './components/home/home.component';
import { BannerComponent } from './components/banner/banner.component';
import { FeaturesComponent } from './components/features/features.component';
import { RiverscapesComponent } from './components/riverscapes/riverscapes.component';
import { MapsViewComponent } from './components/maps-view/maps-view.component';
import { MapsFaunaComponent } from './components/maps-fauna/maps-fauna.component';
import { MapsFloraComponent } from './components/maps-flora/maps-flora.component';
import { MapsFloodWatchComponent } from './components/maps-flood-watch/maps-flood-watch.component';
import { MapsRiverMonitoringComponent } from './components/maps-river-monitoring/maps-river-monitoring.component';
import { ContributorsComponent } from './components/contributors/contributors.component';
import { FooterComponent } from './components/footer/footer.component';
import { VideosGalleryComponent } from './components/videos-gallery/videos-gallery.component';
import { RecentBlogPostComponent } from './components/recent-blog-post/recent-blog-post.component';
import { AboutComponent } from './components/about/about.component';
import { ResourcesComponent } from './components/resources/resources.component';
import { OngoingCampaignsComponent } from './components/ongoing-campaigns/ongoing-campaigns.component';
import { MyAccountComponent } from './components/my-account/my-account.component';
import { ShopComponent } from './components/shop/shop.component';
import { SignInComponent } from './components/auth/sign-in/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up/sign-up.component';
import { VerifyEmailComponent } from './components/auth/verify-email/verify-email.component';
import { VerifyOtpComponent } from './components/auth/verify-otp/verify-otp.component';
import { EmptyStateComponent } from './components/empty-state/empty-state.component';
import { AuthService } from "../app/shared/services/auth.service";
// Firebase services + environment module
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { FloraFaunaListComponent } from './components/flora-fauna/flora-fauna-list/flora-fauna-list.component';
import { FloraFaunaCreateEditComponent } from './components/flora-fauna/flora-fauna-create-edit/flora-fauna-create-edit.component';
import { GoogleMapsModule } from '@angular/google-maps';
import { NgxImageCompressService } from 'ngx-image-compress';
// import { VoicesFromTheRiverComponent } from './components/voices-from-the-river/voices-from-the-river.component';
// import { AddBlogComponent } from './components/add-blog/add-blog.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password/forgot-password.component';
import { FloodWatchComponent } from './components/flood-watch/flood-watch.component';
import { MapsComponent } from './components/maps/maps.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { FilterDateComponent } from './components/filter-date/filter-date.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { TickerComponent } from './components/ticker/ticker.component';
import { MapsFishSanctuariesComponent } from './components/maps-fish-sanctuaries/maps-fish-sanctuaries.component';
import { FishSanctuariesCreateEditComponent } from './components/fish-sanctuaries/fish-sanctuaries-create-edit/fish-sanctuaries-create-edit.component';
import { FishSanctuariesListComponent } from './components/fish-sanctuaries/fish-sanctuaries-list/fish-sanctuaries-list.component';
import { FishSanctuariesViewComponent } from './components/fish-sanctuaries/fish-sanctuaries-view/fish-sanctuaries-view.component';
import { RiverMonitoringCreateEditComponent } from './components/river-monitoring/river-monitoring-create-edit/river-monitoring-create-edit.component';
import { RiverMonitoringListComponent } from './components/river-monitoring/river-monitoring-list/river-monitoring-list.component';
import { RiverMonitoringViewComponent } from './components/river-monitoring/river-monitoring-view/river-monitoring-view.component';
import { ArticlesComponent } from "./components/articles/articles.component";
import { ArticledetailsComponent } from "./components/articledetails/articledetails.component";
import { MdbAccordionModule } from 'mdb-angular-ui-kit/accordion';
import { MdbCarouselModule } from 'mdb-angular-ui-kit/carousel';
import { MdbCheckboxModule } from 'mdb-angular-ui-kit/checkbox';
import { MdbCollapseModule } from 'mdb-angular-ui-kit/collapse';
import { MdbDropdownModule } from 'mdb-angular-ui-kit/dropdown';
import { MdbFormsModule } from 'mdb-angular-ui-kit/forms';
import { MdbModalModule } from 'mdb-angular-ui-kit/modal';
import { MdbPopoverModule } from 'mdb-angular-ui-kit/popover';
import { MdbRadioModule } from 'mdb-angular-ui-kit/radio';
import { MdbRangeModule } from 'mdb-angular-ui-kit/range';
import { MdbRippleModule } from 'mdb-angular-ui-kit/ripple';
import { MdbScrollspyModule } from 'mdb-angular-ui-kit/scrollspy';
import { MdbTabsModule } from 'mdb-angular-ui-kit/tabs';
import { MdbTooltipModule } from 'mdb-angular-ui-kit/tooltip';
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { DeleteModalComponent } from './components/modals/delete-modal/delete-modal.component';



@NgModule({
  declarations: [
    AppComponent,
    ToolbarComponent,
    ArticlesComponent,
    ArticledetailsComponent,
    HomeComponent,
    BannerComponent,
    FeaturesComponent,
    RiverscapesComponent,
    MapsViewComponent,
    MapsFaunaComponent,
    MapsFloraComponent,
    MapsFloodWatchComponent,
    MapsRiverMonitoringComponent,
    ContributorsComponent,
    FooterComponent,
    VideosGalleryComponent,
    RecentBlogPostComponent,
    AboutComponent,
    ResourcesComponent,
    OngoingCampaignsComponent,
    MyAccountComponent,
    ShopComponent,
    SignInComponent,
    SignUpComponent,
    VerifyEmailComponent,
    VerifyOtpComponent,
    FloraFaunaListComponent,
    FloraFaunaCreateEditComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    FloodWatchComponent,
    MapsComponent,
    PrivacyPolicyComponent,
    FilterDateComponent,
    TickerComponent,
    MapsFishSanctuariesComponent,
    FishSanctuariesCreateEditComponent,
    FishSanctuariesListComponent,
    FishSanctuariesViewComponent,
    RiverMonitoringCreateEditComponent,
    RiverMonitoringListComponent,
    RiverMonitoringViewComponent,
    DeleteModalComponent,
    EmptyStateComponent
  ],
  imports: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => getAuth()),
    provideFirestore(() => getFirestore()),
    BrowserModule,
    GooglePlaceModule,
    NgOtpInputModule,
    InfiniteScrollModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    GoogleMapsModule,
    // AgmCoreModule.forRoot({
    //   apiKey: "AIzaSyALR2ZDTTyZXGBRFeCV0AHd0S-TV_GWYm8",
    //   libraries: ["places"]
    // }),
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatExpansionModule,
    MatMenuModule,
    MatIconModule,
    CdkAccordionModule,
    MdbAccordionModule,
    MdbCarouselModule,
    MdbCheckboxModule,
    MdbCollapseModule,
    MdbDropdownModule,
    MdbFormsModule,
    MdbModalModule,
    MdbPopoverModule,
    MdbRadioModule,
    MdbRangeModule,
    MdbRippleModule,
    MdbScrollspyModule,
    MdbTabsModule,
    MdbTooltipModule,
    MdbValidationModule,
    MatStepperModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    NgxMaterialTimepickerModule,
    MatSidenavModule
  ],
  providers: [AuthService, NgxImageCompressService, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  {provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: {hasBackdrop: true}}],
  bootstrap: [AppComponent]
})
export class AppModule { }