import { AfterViewInit, Component, EventEmitter, OnInit, Output, NgZone } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MdbModalRef, MdbModalService } from 'mdb-angular-ui-kit/modal';
import { AuthService } from "../../../shared/services/auth.service";
import { WindowService } from '../../../services/window.service';
import * as firebase from 'firebase/app';
// import { AngularFireAuth } from "@angular/fire/auth";
// import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { SpinnerService } from '../../../services/spinner.service';
import { OrolService } from '../../../services/orol.service';
import { SignUpComponent } from '../sign-up/sign-up.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import {
  Auth,
  authState,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendEmailVerification,
  signInWithPhoneNumber,
  ApplicationVerifier,
  getAuth,
  RecaptchaVerifier
} from '@angular/fire/auth';
import { VerifyOtpComponent } from '../verify-otp/verify-otp.component';


@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements AfterViewInit {
  //Reference to all the modals
  modalRefSignUp: MdbModalRef<SignUpComponent> | null = null;
  modalRefForgotPassword: MdbModalRef<ForgotPasswordComponent> | null = null;
  modalRefVerifyOTP: MdbModalRef<VerifyOtpComponent> | null = null;
  windowRef: any;
  submitted: boolean = false;
  loading: boolean = false;
  phone: any;
  errorMessage: any;
  mode: any = "invalid";
  showPassword: boolean = false;
  appVerifier: any;
  userDetails: any;

  // @Output() isRegister = new EventEmitter();
  // @Output() isLogin = new EventEmitter();
  // @Output() isRecoverPassword = new EventEmitter();
  // @Output() isVerifyOTP = new EventEmitter();
  // @Output() userName = new EventEmitter();
  // @Output() password = new EventEmitter();
  // @Output() phoneNumber = new EventEmitter();
  // @Output() avatarURL = new EventEmitter();
  loginBtnText: string = "Send OTP"

  constructor(public ngZone: NgZone,
    private modalService: MdbModalService,
    public modalRefSignIn: MdbModalRef<SignInComponent>,
    private win: WindowService,
    public authService: AuthService,
    // private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    // private router: Router,
    // public afs: AngularFirestore,
    // public afAuth: AngularFireAuth,
    private spinnerService: SpinnerService,
    public orolService: OrolService) {
    this.authService.errorMessage.subscribe((data:any) => {
      this.errorMessage = data;
    });
  }

  ngOnInit() {
//     const auth = getAuth();
// this.winRef.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
// "size": "invisible",
// }, auth);

//     this.windowRef = this.win.windowRef;
//     this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
//     // this.windowRef.recaptchaVerifier = this.afAuth.recaptchaVerifier('recaptcha-container');

//     this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//       "recaptcha-container",
//       {
//         size: "invisible",
//         callback: function(response:any) {
//         }
//       }
//     );
    
//     this.windowRef.recaptchaVerifier.render();
  }

  ngAfterViewInit(): void {
  }

  signIn(userName: string, password?: string, phoneNumber?: string) {
    if (this.mode == "phone" && userName != "") {
      this.spinnerService.setSpinner(true);
      this.submitted = true;
      this.signInWithPhoneNumber(userName);
    }else{
      this.errorMessage = "Please enter a valid mobile number or email"
    }
    if (this.mode == "email" && userName != "") {
      if(password != ""){
        this.spinnerService.setSpinner(true);
        this.submitted = true;
        this.signInWithEmailAndPassword(userName, password);
      }else{
      this.errorMessage = "Please enter a valid password"
      }
    }else{
      this.errorMessage = "Please enter a valid mobile number or email"
    }
  }

  signInWithPhoneNumber(phone:any) {
    this.spinnerService.setSpinner(true);
    this.windowRef = this.win.windowRef;
    const auth = getAuth();
    this.windowRef.recaptchaVerifier = new RecaptchaVerifier("recaptcha-container", {
     "size": "invisible",
    }, auth);

    const appVerifier = this.windowRef.recaptchaVerifier;

    this.authService.signInWithPhoneNumber(phone, appVerifier)
      .then((result:any) => {
        this.windowRef.confirmationResult = result;
        this.orolService.signInPhone(phone).subscribe((res) => {
        this.userDetails=JSON.parse(JSON.stringify(res))
        if(this.userDetails.firstName != undefined && this.userDetails.email != undefined){
          this.modalRefVerifyOTP = this.modalService.open(VerifyOtpComponent,{
                  data: { phoneNumber: phone },
          })
          this.modalRefSignIn.close();
          this.getAccessTokenAndSetUser(this.userDetails.phoneNumber, "phone");
          this.spinnerService.setSpinner(false);
        }else{
          this.errorMessage = "Mobile number not registered."
          this.spinnerService.setSpinner(false);
        }
        });
        
       


      
        // this.userName.emit(phone);
        // this.isVerifyOTP.emit(true);
        // this.isLogin.emit(false);
      })
      .catch((error:any) => {
        this.spinnerService.setSpinner(false);
        console.log("signInWithPhoneNumber " + error)
        this.errorMessage = error.message;
      });
  }

  signInWithEmailAndPassword(userName: string, password?: string) {
    this.authService.signInWithEmailAndPassword(userName, password!).then((data:any) => {
      if (data.user.emailVerified == true) {
        this.getAccessTokenAndSetUser(userName, "email");
        this.spinnerService.setSpinner(false);
        this.modalRefSignIn.close();
        // this.ngZone.run(() => {
        //   this.router.navigate(['home']);
        // });
      }
      else {
        this.spinnerService.setSpinner(false);
        this.errorMessage = "Please check your email inbox for a verification email.";
      }

    }).catch((error) => {
      this.spinnerService.setSpinner(false);
      this.errorMessage = error.message;
    });
  }

  //Getting the access token from auth and setting the user in local storage
  getAccessTokenAndSetUser(userName: string, mode: string) {
    this.spinnerService.setSpinner(true);
    this.orolService.getAccessToken(userName, mode).subscribe((data:any) => {
      const User: any = {
        'id': data['user'].id,
        'accessToken': data['accessToken'],
        'firstName': data['user'].firstName,
        'lastName': data['user'].lastName,
        'phoneNumber': data['user'].phoneNumber,
        'email': data['user'].email,
        'avatarURL': data['user'].avatarURL[0],
      }
      console.log(userName)
      localStorage.setItem('User', JSON.stringify(User));
      this.orolService.userDetailsSubject.next(User);
      this.spinnerService.setSpinner(false);
    });
  }

  forgotPassword() {
    this.modalRefSignIn.close();
    this.modalRefForgotPassword = this.modalService.open(ForgotPasswordComponent)
  }
  register() {
    this.modalRefSignIn.close();
    this.modalRefSignUp = this.modalService.open(SignUpComponent)
  }
  
  validate(event: any) {
    if (this.validateUsername(event) == true) {
      if (this.mode == 'phone') {
        this.loginBtnText = "Next";
        this.showPassword = false;
        this.errorMessage = "";
      }
      else if (this.mode == 'email') {
        this.loginBtnText = "Login";
        this.showPassword = true;
        this.errorMessage = "";
      }
    }
    else {
      if (this.mode == 'invalid') {
        this.loginBtnText = "Next";
        this.showPassword = false;
        this.errorMessage = "Please enter a valid mobile number or email"
      }
    }
  }
  validateUsername(event: { target: { value: string; }; }) {
    var phonePattern = /^\d{10}$/;
    if (event.target.value.match(phonePattern)) {
      this.errorMessage = "";
      this.mode = "phone";
      return true;
    }
    else {
      this.mode = "email";
      var emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (event.target.value.match(emailPattern)) {
        this.errorMessage = "";
        this.mode = "email";
        return true;
      }
      else {
        this.mode = "invalid";
        return false;
      }
    }
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
  }
  // toggleClientSecret(){
  //   // this.isEyeHidden = !this.isEyeHidden;
  // }
}
